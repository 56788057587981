import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/common/components/_base-component/dialog/dialog.service';
import { RoundService } from 'src/app/core/services/round.service';
import { Editor, Toolbar } from "ngx-editor";
import { filter, switchMap, takeUntil, tap } from "rxjs/operators";
import { catchError, Subject, throwError } from "rxjs";
import { RoundCreationInstructionsService } from "../../../../core/services/round-creation-instructions.service";
import { FormControl, FormGroup } from "@angular/forms";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { HelpPopupButtonsEnum } from "../../../Enums/HelpPopupButtonsEnum";

@Component({
  selector: 'help-popup',
  templateUrl: './help-popup.component.html',
  styleUrls: ['./help-popup.component.scss'],
})
export class HelpPopupComponent implements OnInit, OnDestroy {
  saveButtonText = HelpPopupButtonsEnum.Save;
  editor: Editor;
  form = new FormGroup({
    editorContent: new FormControl()
  });
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  protected readonly HelpPopupButtonsEnum = HelpPopupButtonsEnum;
  private unsubscribe$ = new Subject<void>();


  constructor(
    public dialogRef: MatDialogRef<HelpPopupComponent>,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private roundCreationInstructionsService: RoundCreationInstructionsService,
    public roundService: RoundService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.editor = new Editor();
    this.roundCreationInstructionsService.getRoundCreationInstructions()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(data => {
          if (Object.keys(data.text).length) {
            this.saveButtonText = HelpPopupButtonsEnum.Edit;
            this.form.patchValue({ editorContent: data.text });
            this.form.disable();
            this.form.markAsPristine();
          }
        }),
        switchMap(() => this.form.valueChanges),
        tap((data) => this.hasNonNullTextContent(data.editorContent['content']) && this.saveButtonText === 'Save'  ? this.form.markAsDirty() : this.form.markAsPristine())
      )
      .subscribe();
  }


  ngOnDestroy(): void {
    this.editor.destroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  hasNonNullTextContent(arr) {
    for (const item of arr) {
      if (typeof item === 'object' && item !== null) {
        if ('text' in item && item.text !== null) {
          return true;
        }
        if ('content' in item && Array.isArray(item.content) && this.hasNonNullTextContent(item.content)) {
          return true;
        }
      }
    }
    return false;
  }

  close() {
    const editorContent = !this.form.getRawValue().editorContent && this.saveButtonText === HelpPopupButtonsEnum.Save ? [] : this.form.getRawValue().editorContent['content'];
    const closeFlag = this.hasNonNullTextContent(editorContent) ? !this.form.dirty : (editorContent.length ? !this.form.touched : this.form.touched);
    if (closeFlag) {
      this.dialog.closeAll();
    } else {
      this.dialogService.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter(response => !!response),
        tap((response) => {
          if (response) {
            this.dialog.closeAll();
          }
        })
      ).subscribe()
    }
  }

  saveDoc() {
    if (this.saveButtonText === HelpPopupButtonsEnum.Save) {
      const formValue = this.form.getRawValue();
      const body = { text: formValue.editorContent };
      this.roundCreationInstructionsService.addRoundCreationInstructions(body)
        .pipe(
          takeUntil(this.unsubscribe$),
          tap(() => {
            this.saveButtonText = HelpPopupButtonsEnum.Edit;
            this.form.disable();
            this.form.markAsUntouched();
            this.snackBarService.showSnackBar('Round creation instructions were updated successfully!')
          }),
          catchError((error) => {
            this.snackBarService.showSnackBar('Round creation instructions were not updated')
            return throwError(error);
          })
        )
        .subscribe()
    } else  {
      this.saveButtonText = HelpPopupButtonsEnum.Save;
      this.form.enable();
      this.form.markAsPristine();
    }

  }
}
