import {HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {UriConfig} from "../../app.config";
import {PlayerItemDetailsModel} from "../../common/models/PlayerItemDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class PlayersService {
  isNeedUpdate$ = new BehaviorSubject<boolean>(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }
  getPlayersList(body) {
    let params = new HttpParams()
    Object.keys(body).forEach(param => {
      if (body[param]) {
        params = params.set(param, body[param]);
      }
    });
    return this.http.get<{ records: PlayerItemDetailsModel[], total: number }>(this.uriConfig.vendors + '/b2c', {params});
  }

  getPlayerDetailsById(id) {
    return this.http.get(this.uriConfig.vendors + '/b2c/' + id);
  }

  needUpdatePlayersTable() {
    this.isNeedUpdate$.next(true);
  }

  getPlayersTableStatus() {
    return this.isNeedUpdate$;
  }

  changePlayerStatus(body, userId) {
    return this.http.patch(this.uriConfig.vendors + '/b2c/' + userId, body);
  }

  downloadHistoryReport(userId: number) {
    return this.http.get(this.uriConfig.analytics + '/players-report/' + userId);
  }

  updatePlayerDetails(body, id) {
    return this.http.patch(this.uriConfig.vendors + '/b2c/' + id, body);
  }


}
