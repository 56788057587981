import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  CurrentUserStreakGame,
  ExpandedB2CUserItemDetailsModel
} from "../../../models/ExpandedB2CUserItemDetailsModel";
import { BehaviorSubject, catchError, finalize, firstValueFrom, Subject, takeUntil, tap, throwError } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { PlayersService } from "../../../../core/services/players.service";
import { TextService } from "../../../../core/services/text.service";
import { map } from "rxjs/operators";

@Component({
	selector: 'update-user-difficulty-level',
	templateUrl: './update-user-difficulty-level.component.html',
	styleUrls: ['./update-user-difficulty-level.component.scss']
})
export class UpdateUserDifficultyLevelComponent implements OnInit, OnDestroy {

	isLoaded$ = new BehaviorSubject<boolean>(false);

	currentGameDetails$ = new BehaviorSubject<CurrentUserStreakGame>(null);

	isDifficultyLevelExist$ = new BehaviorSubject<boolean>(true);

	userDataFromGroup: FormGroup;

	private unsubscribe$: Subject<void> = new Subject();

	constructor(
		public dialogRef: MatDialogRef<UpdateUserDifficultyLevelComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBarService: SnackBarService,
		private playersService: PlayersService,
		public textService: TextService,
    private fb: FormBuilder,
  ) {
	}

	ngOnInit(): void {
		this.playersService.getPlayerDetailsById(this.data.id)
			.pipe(
				takeUntil(this.unsubscribe$),
				tap((userData: ExpandedB2CUserItemDetailsModel) => {

					if (userData.levelQuestionDifficulty) {
						this.buildForm(userData);
					}

					this.isDifficultyLevelExist$.next(!!userData.levelQuestionDifficulty);

					this.currentGameDetails$.next(userData.currentStreakGame);
				}),
				catchError((error) => {
					this.snackBarService.showSnackBar(error.error.message, true);
					return throwError(error);
				}),
				finalize(() => this.isLoaded$.next(true))
			).subscribe();
	}

	buildForm(userData) {
    this.userDataFromGroup = new FormGroup({
      levelPoints: this.fb.group({
        points: this.fb.array(this.createFormItem(userData)),
      })
    });
	}

  createFormItem(userData): FormGroup[] {
    const {currentStreakGame: {streakLevel}, levelQuestionDifficulty} = userData;
    return  Object.keys(levelQuestionDifficulty).map(level => {
      const disableControls = +level <= +streakLevel + 1;
			const formGroup = this.fb.group({
        questionDifficultyLevel: new FormControl(({value: levelQuestionDifficulty[level], disabled: disableControls}), [Validators.required]),
        correctAnswer: new FormControl({value: level, disabled: true}, [Validators.required]),
      });
			return formGroup;
    })
  }

  get levelPoints(): FormArray {
    return this.userDataFromGroup?.get('levelPoints').get('points') as FormArray;
  }

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async onSaveDifficultyLeve() {
		this.isLoaded$.next(false);
		const levelQuestionDifficulty = await firstValueFrom(this.getDifficultyBody());
		this.playersService.updatePlayerDetails({levelQuestionDifficulty}, this.data.id)
			.pipe(
				takeUntil(this.unsubscribe$),
				tap(() => {
					this.dialogRef.close();
				}),
				catchError((error) => {
					this.snackBarService.showSnackBar(error.error.message, true);
					return throwError(error)
				}),
				finalize(() => {
					this.isLoaded$.next(true);
					this.playersService.needUpdatePlayersTable();
				})
			).subscribe()
	}

	onCloseModal() {
		this.dialogRef.close();
	}

	getDifficultyBody() {
		return this.currentGameDetails$.pipe(
			map(currentUser => {
				const {levelPoints : {points}} = this.userDataFromGroup.getRawValue();
				const levelQuestionDifficulty = {};

				points.forEach(level => {
					if (!currentUser.streakLevel || +level.correctAnswer > currentUser.streakLevel + 1) {
						levelQuestionDifficulty[level.correctAnswer] = level.questionDifficultyLevel;
					}
				})
				return levelQuestionDifficulty;
			}),
		);
	}
}

