<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
        <h2 class="popup-header">Select a custom leaderboard type</h2>
      </div>
      <div class="control-header">
        <button
          mat-raised-button
          color="primary"
          [disabled]="leaderboardTypeForm.invalid"
          (click)="selectLeaderboardType()">
          Next
        </button>
      </div>
    </div>
  </div>
  <div class="builder-body">
    <div class="builder-content">
      <div class="class-details">
        <form [formGroup]="leaderboardTypeForm">
          <div class="form-field-with-label">
            <custom-select
              [selectOptions]="leaderboardTypes"
              formControlName="type">
              Type*
            </custom-select>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
