export const APP_ROUTES = {
  AUTH: 'auth',
  DASHBOARD: 'dashboard',
  GAMES: 'games',
  ALL_GAMES: 'all-games',
  CREATE_GAME: 'create-game',
  EDIT_GAME: 'edit-game',
  SPORT_EVENTS: 'sport-events',
  CUSTOM_LEADERBOARDS: 'custom-leaderboards',
  CRM: 'crm',
  CMS: 'cms',
  PLAYERS: 'players',
  LANGUAGES: 'languages',
  TRANSLATIONS: 'translations',
  INFO_POPUP: 'info-popup',
  SETTINGS: 'settings',
  PORTAL: 'v',
  PORTAL_ADMIN: 'a',
  RESTORE_PASSWORD:'restorePassword',
  ADMIN: 'admin',
  VENDORS: 'vendors',
  STREAK_SETUP: 'streak-setup',
  QUESTIONS: 'questions',
  STREAK_DASHBOARD: 'streak-dashboard',
  STREAK_GAMES: 'streak-games',
  ALL_STREAK_GAMES: 'all-streak-games',
  STREAK_CUSTOM_LEADERBOARDS: 'streak-custom-leaderboards',
  UPSELL: 'upsell'
};
