<div class="builder-container">
  <div class="builder-header">
    <div class="header-panel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onCloseModal()">close</mat-icon>
        <h2 class="popup-header">Download Difference Report</h2>
      </div>
    </div>
  </div>
  <div class="builder-body">
    <div class="builder-content">
      <div class="class-details">
        <h4>Upload two CSV files to create a difference report.</h4>
        <div class="class-details_file">
          <p>Upload report with old data</p>
          <div class="buttons-container">
            <div class="file-input-container">
              <button mat-stroked-button color="primary" (click)="triggerFileInput(fileInputOld)">{{ fileNamePrev ?? 'Upload a CSV file' }}</button>
              <input  #fileInputOld type="file" class="file-input" accept="text/csv" (change)="onFileOldChange($event)"/>
            </div>
          </div>
        </div>

        <div class="class-details_file">
          <p>Upload report with new data</p>
          <div class="buttons-container">
            <div class="file-input-container">
              <button mat-stroked-button color="primary" (click)="triggerFileInput(fileInputNew)">{{ fileNameNew ?? 'Upload a CSV file' }}</button>
              <input  #fileInputNew type="file" class="file-input" accept="text/csv" (change)="onFileNewChange($event)"/>
            </div>
          </div>
        </div>
        <mat-error
          class="errors"
          *ngIf="errorMessages">
          <div class="text" *ngFor="let error of errorMessages">
            <i class="fas fa-exclamation-circle"></i>
            {{error}}
          </div>
        </mat-error>
      </div>
      <div class="buttons-container">
        <button mat-raised-button color="primary" [disabled]="!(selectedFiles.length === 2)" (click)="onConfirmClick()">Download a report</button>
        <button mat-raised-button (click)="onCloseModal()">Close</button>
      </div>
    </div>
  </div>
</div>
