<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="closeDialog()">close</mat-icon>
        <h2 class="popup-header">{{data ? 'Edit Question' : 'Create Question'}}</h2>
      </div>
      <div class="buttons-container">
        <button
          mat-raised-button
          color="primary"
          [disabled]="questionTypeForm.invalid || questionForm.invalid || localesForm.invalid"
          (click)="addQuestion()"
        >
          {{data ? 'Edit' : 'Add'}}
        </button>
        <i
          class="fa-solid fa-circle-info tooltip"
          [matTooltipPosition]="TooltipPositionEnum.right"
          #tooltip="matTooltip"
          [matTooltip]="'All fields should be filled and valid to ' + (data ? 'edit' : 'add') + ' question'"
          [matTooltipClass]="TooltipPositionEnum.right"></i>
      </div>
    </div>
  </div>
  <div class="builder-body">
    <div class="builder-content">
      <div class="question-details">
        <div class="header body-big-bold">
          <div class="header-title">
            QUESTION
          </div>
        </div>
        <form class="question-form" [formGroup]="questionForm">
          <!--Question difficulty Field-->
          <div class="question-form-field">
            <mat-label class="body-small-medium">Question difficulty</mat-label>
            <div class="radio-form-field">
              <mat-radio-group color="primary" formControlName="difficultyLevel">
                <mat-radio-button *ngFor="let level of getDifficultyLevelList()" [value]="level">
                  {{textService.createNameWithSpaceAndCapitalizeFirstLetter(level)}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!--Image Field-->
          <div class="question-form-field">
            <div class="image-upload-form-field">
              <div *ngIf="questionForm.get('imageUrl')">
                <drag-and-drop-file-form
                  formControlName="imageUrl"
                  [tooltipText]="tooltipTextIcon"
                  [url]="questionForm?.get('imageUrl')?.value">
                  Question Icon
                </drag-and-drop-file-form>
              </div>
            </div>
          </div>
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
            <mat-tab label="Default">
              <div class="question-header">
                <!--Question Text Field-->
                <div *ngIf="questionForm.get('text')">
                  <custom-input
                    [formControl]="questionForm.get('text')"
                    placeholder="Enter question text">
                    Text*
                  </custom-input>
                </div>
                <!--Question Type Field-->
                <div>
                  <custom-select
                    [ngClass]="{'no-text-question': !questionForm.get('text')}"
                    formControlName="type"
                    [selectOptions]="questionTypeService.questionTypeSelector$ | async">
                    Type*
                  </custom-select>
                </div>
              </div>
              <!--Question Types Forms-->
              <form [ngSwitch]="questionForm.get('type').value"
                    [formGroup]="questionTypeForm">
               <!-- Score Plus Form-->
                <div *ngSwitchCase="QuestionTypesEnum.SCORE_PLUS">
                  <custom-input
                    type="number"
                    formControlName="maxScoreValue"
                    placeholder="Enter max allowed score value">
                    Max Score Value*
                  </custom-input>
                </div>
                <!-- Range Form-->
                <div *ngSwitchCase="QuestionTypesEnum.RANGE">
                  <div class="number-flex">
                    <div>
                      <custom-input
                        type="number"
                        formControlName="minValue"
                        placeholder="Enter min value">
                        Min Value*
                      </custom-input>
                    </div>
                    <div>
                      <custom-input
                        type="number"
                        formControlName="maxValue"
                        placeholder="Enter max value">
                        Max Value*
                      </custom-input>
                    </div>
                  </div>
                  <div class="number-flex">
                    <div>
                      <custom-input
                        formControlName="displayValue"
                        placeholder="Enter display value">
                        Display Value*
                      </custom-input>
                    </div>
                    <div>
                      <custom-input
                        type="number"
                        formControlName="incrementValue"
                        placeholder="Enter increment value">
                        Increment Value*
                      </custom-input>
                    </div>
                  </div>
                </div>
                <!-- Options Form-->
                <div *ngSwitchCase="QuestionTypesEnum.OPTIONS">
                  <select-create-group
                    formControlName="groupId"
                    [answerGroupList]="answerGroupList$ | async"
                    [questionType]="QuestionTypesEnum.OPTIONS"
                    (onEditGroup)="editGroup($event)"
                    (onDeleteGroup)="deleteGroup($event)"
                    (onCreateGroup)="createGroup()">
                  </select-create-group>

                  <ng-template
                    *ngIf="!questionTypeForm.get('groupId').value
                     && questionTypeForm.get('additional').valid"
                    [ngTemplateOutlet]="createAnswersGroup">
                  </ng-template>

                  <ng-template
                    *ngIf="questionTypeForm.get('groupId').value
                    && questionTypeForm.get('additional').valid
                    && questionTypeForm.get('additional').dirty
                    && (isAnswersDirty$ | async)
                    "
                    [ngTemplateOutlet]="createOrEditAnswersGroup">
                  </ng-template>

                  <answers-question
                    [form]="questionTypeForm.controls['additional']"
                    [_isExternalEvent]="false"
                    [_questionType]="QuestionTypesEnum.OPTIONS"
                    [maxAnswerLength]="2"
                    [hideImage]="hideImage"
                    [answerGroupId]="questionTypeForm.get('groupId').value"
                    (isAnswersDirty)="isAnswersDirtyEvent($event)">
                  </answers-question>
                </div>
                <!-- List Form-->
                <div *ngSwitchCase="QuestionTypesEnum.LIST">
                  <select-create-group
                    formControlName="groupId"
                    [answerGroupList]="answerGroupList$ | async"
                    [questionType]="QuestionTypesEnum.LIST"
                    (onEditGroup)="editGroup($event)"
                    (onDeleteGroup)="deleteGroup($event)"
                    (onCreateGroup)="createGroup()">
                  </select-create-group>

                  <ng-template
                    *ngIf="!questionTypeForm.get('groupId').value && questionTypeForm.get('additional').valid"
                    [ngTemplateOutlet]="createAnswersGroup">
                  </ng-template>

                  <ng-template
                    *ngIf="questionTypeForm.get('groupId').value
                    && questionTypeForm.get('additional').valid
                    && questionTypeForm.get('additional').dirty
                    && (isAnswersDirty$ | async)
                    "
                    [ngTemplateOutlet]="createOrEditAnswersGroup">
                  </ng-template>

                  <answers-question
                    [form]="questionTypeForm.controls['additional']"
                    [_isExternalEvent]="false"
                    [_questionType]="QuestionTypesEnum.LIST"
                    [maxAnswerLength]="15"
                    [hideImage]="hideImage"
                    [answerGroupId]="questionTypeForm.get('groupId').value"
                    (isAnswersDirty)="isAnswersDirtyEvent($event)">
                  </answers-question>
                </div>
                <!-- Grid Form-->
                <div *ngSwitchCase="QuestionTypesEnum.GRID">
                  <select-create-group
                    formControlName="groupId"
                    [answerGroupList]="answerGroupList$ | async"
                    [questionType]="QuestionTypesEnum.GRID"
                    (onEditGroup)="editGroup($event)"
                    (onDeleteGroup)="deleteGroup($event)"
                    (onCreateGroup)="createGroup()">
                  </select-create-group>

                  <ng-template
                    *ngIf="!questionTypeForm.get('groupId').value && questionTypeForm.get('additional').valid"
                    [ngTemplateOutlet]="createAnswersGroup">
                  </ng-template>

                  <ng-template
                    *ngIf="questionTypeForm.get('groupId').value
                     && questionTypeForm.get('additional').valid
                     && questionTypeForm.get('additional').dirty
                     && (isAnswersDirty$ | async)
                    "
                    [ngTemplateOutlet]="createOrEditAnswersGroup">
                  </ng-template>

                  <answers-question
                    [form]="questionTypeForm.controls['additional']"
                    [_isExternalEvent]="false"
                    [_questionType]="QuestionTypesEnum.GRID"
                    [maxAnswerLength]="30"
                    [hideImage]="hideImage"
                    [answerGroupId]="questionTypeForm.get('groupId').value"
                    (isAnswersDirty)="isAnswersDirtyEvent($event)">
                  </answers-question>
                </div>
                <!-- Lists Form-->
                <div *ngSwitchCase="QuestionTypesEnum.LISTS">
                  <div class="answer-groups-container">
                    <div>
                      <select-create-group
                        formControlName="answerGroupId"
                        [answerGroupList]="answerGroupList$ | async"
                        [questionType]="QuestionTypesEnum.LISTS"
                        (onEditGroup)="editGroup($event)"
                        (onDeleteGroup)="deleteGroup($event)"
                        (onCreateGroup)="createGroup()">
                      </select-create-group>

                      <ng-template
                        *ngIf="!questionTypeForm.get('answerGroupId').value && additional.get('answers1st').valid"
                        [ngTemplateOutlet]="createAnswersGroup"
                        [ngTemplateOutletContext]="{
                        answersKey: 'answers1st',
                        groupKey: 'answerGroupId'
                      }"
                      >
                      </ng-template>

                      <ng-template
                        *ngIf="questionTypeForm.get('answerGroupId').value
                        && additional.get('answers1st').valid
                        && additional.get('answers1st').dirty
                        && (isAnswersDirty$ | async)
                        "
                        [ngTemplateOutlet]="createOrEditAnswersGroup"
                        [ngTemplateOutletContext]="{
                        answersKey: 'answers1st',
                        groupKey: 'answerGroupId'
                      }"
                      >
                      </ng-template>
                    </div>
                    <div>
                      <select-create-group
                        formControlName="answerGroupId2"
                        [answerGroupList]="answerGroupList$ | async"
                        [questionType]="QuestionTypesEnum.LISTS"
                        (onEditGroup)="editGroup($event)"
                        (onDeleteGroup)="deleteGroup($event)"
                        (onCreateGroup)="createGroup()">
                      </select-create-group>

                      <ng-template
                        *ngIf="!questionTypeForm.get('answerGroupId2').value && additional.get('answers2st').valid"
                        [ngTemplateOutlet]="createAnswersGroup"
                        [ngTemplateOutletContext]="{
                        answersKey: 'answers2st',
                        groupKey: 'answerGroupId2'
                      }"
                      >
                      </ng-template>

                      <ng-template
                        *ngIf="questionTypeForm.get('answerGroupId2').value
                        && additional.get('answers2st').valid
                        && additional.get('answers2st').dirty
                        && (isAnswersDirty2st$ | async)
                        "
                        [ngTemplateOutlet]="createOrEditAnswersGroup"
                        [ngTemplateOutletContext]="{
                        answersKey: 'answers2st',
                        groupKey: 'answerGroupId2'
                      }"
                      >
                      </ng-template>
                    </div>
                  </div>

                  <answers-question
                    [form]="questionTypeForm.controls['additional']"
                    [_isExternalEvent]="false"
                    [_questionType]="QuestionTypesEnum.LISTS"
                    [maxAnswerLength]="15"
                    [hideImage]="hideImage"
                    [titleFor2ndList]="'2nd Answers*'"
                    [answerGroupId]="questionTypeForm.get('answerGroupId').value"
                    [answerGroupId2]="questionTypeForm.get('answerGroupId2').value"
                    (isAnswersDirty)="isAnswersDirtyEvent($event)"
                    (isAnswersDirty2st)="isAnswersDirty2stEvent($event)"                  >
                  </answers-question>
                </div>
              </form>
            </mat-tab>
            <!--Localization Tabs-->
            <ng-container *ngIf="(locales$ | async) as locales">
              <mat-tab
                *ngFor="let locale of locales"
                [label]="locale.country"
              >
                <form [formGroup]="localesForm">
                  <ng-container [formGroupName]="locale.country">
                    <div class="question-header">
                      <ng-container *ngIf="localesForm.get(locale.country).get('text')">
                        <custom-input
                          formControlName="text"
                          placeholder="Enter question text translation">
                          Text Translation*
                        </custom-input>
                      </ng-container>
                    </div>
                    <div class="form-field-with-label answers-translation" *ngIf="localesForm.get(locale.country).get('answers')">
                      <mat-label>Answer Translations*</mat-label>
                      <ng-container formArrayName="answers">
                        <ng-container *ngFor="let item of getLocaleAnswers(locale.country).controls; let i = index" [formGroupName]="i">
                          <mat-form-field appearance="fill" class="answers-translation-item">
                            <input
                              [formControl]="item.get('text')"
                              matInput
                              [placeholder]="'Enter translation for answer ' + (i + 1)"
                            >
                          </mat-form-field>
                          <mat-error *ngIf="isShowError(item.get('text'))">
                            <i class="fas fa-exclamation-circle"></i>
                            <span>{{appData.MANDATORY}}</span>
                          </mat-error>
                          <mat-error *ngIf="isShowErrorLength(item.get('text'))">
                            <i class="fas fa-exclamation-circle"></i>
                            <span>{{appData.MAX_LENGHT_255}}</span>
                          </mat-error>
                        </ng-container>
                      </ng-container>
                    </div>
                    <div class="list-translations-container">
                      <div class="form-field-with-label answers-translation" *ngIf="localesForm.get(locale.country).get('answers1st')">
                        <mat-label>1st Answers Translations*</mat-label>
                        <ng-container formArrayName="answers1st">
                          <ng-container  *ngFor="let item of getLocaleAnswers(locale.country, 'answers1st').controls; let i = index" [formGroupName]="i">
                            <mat-form-field appearance="fill" class="answers-translation-item">
                              <input
                                [formControl]="item.get('text')"
                                matInput
                                [placeholder]="'Enter translation for answer ' + (i + 1)"
                              >
                            </mat-form-field>
                            <mat-error *ngIf="isShowError(item.get('text'))">
                              <i class="fas fa-exclamation-circle"></i>
                              <span>{{appData.MANDATORY}}</span>
                            </mat-error>
                            <mat-error *ngIf="isShowErrorLength(item.get('text'))">
                              <i class="fas fa-exclamation-circle"></i>
                              <span>{{appData.MAX_LENGHT_255}}</span>
                            </mat-error>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="form-field-with-label answers-translation" *ngIf="localesForm.get(locale.country).get('answers2st')">
                        <mat-label>2nd Answers Translations*</mat-label>
                        <ng-container formArrayName="answers2st">
                          <ng-container  *ngFor="let item of getLocaleAnswers(locale.country, 'answers2st').controls; let i = index" [formGroupName]="i">
                            <mat-form-field appearance="fill" class="answers-translation-item">
                              <input
                                [formControl]="item.get('text')"
                                matInput
                                [placeholder]="'Enter translation for answer ' + (i + 1)"
                              >
                            </mat-form-field>
                            <mat-error *ngIf="isShowError(item.get('text'))">
                              <i class="fas fa-exclamation-circle"></i>
                              <span>{{appData.MANDATORY}}</span>
                            </mat-error>
                            <mat-error *ngIf="isShowErrorLength(item.get('text'))">
                              <i class="fas fa-exclamation-circle"></i>
                              <span>{{appData.MAX_LENGHT_255}}</span>
                            </mat-error>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </form>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!(isLoaded$ | async)" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>

<!--Answer Group Warnings Templates-->
<ng-template #createAnswersGroup let-answersKey="answersKey" let-groupKey="groupKey">
<div class="group-warning">
  Do you want to create group based on the current answers?
  <button
    (click)="createNewFromAnswers(groupKey, answersKey)"
    mat-raised-button
    color="primary">
    Create New
  </button>
</div>
</ng-template>


<ng-template #createOrEditAnswersGroup let-answersKey="answersKey" let-groupKey="groupKey">
  <div class="group-warning">
    The answers do not match the selected answer group. Do you want to create a new group or edit the current one based on the current answers?
    <div class="answer-action">
      <button
        (click)="createNewFromAnswers(groupKey, answersKey)"
        mat-raised-button
        color="primary">
        Create New
      </button>
      <button
        (click)="editSelectedFromAnswers(groupKey, answersKey)"
        mat-raised-button
        color="primary">
        Edit Selected
      </button>
    </div>
  </div>
</ng-template>


