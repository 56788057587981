<div class="builder-tabs-container">
  <div class="builder-wrapper">
    <div class="header-pannel">
      <div class="header-container">
        <div class="general-header">
          <mat-icon class="material-icons close" (click)="closeDialog()">close</mat-icon>
          <h2 class="popup-header">{{header}}</h2>
        </div>
        <div class="buttons-container">
          <button
            mat-raised-button
            color="primary"
            [disabled]="saveButtonState$ | async"
            (click)="saveLeaderboard()">
            Save
          </button>
          <i
            class="fa-solid fa-circle-info tooltip"
            [matTooltipPosition]="TooltipPositionEnum.right"
            #tooltip="matTooltip"
            [matTooltip]="saveButtonTooltip$ | async"
            [matTooltipClass]="TooltipPositionEnum.right"></i>
        </div>
      </div>
    </div>
    <div class="tabs-details">
      <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabIndex = $event.index" dynamicHeight>
        <mat-tab
          *ngFor="let tab of (tabNames$ | async)"
          [label]="tab">

          <ng-template *ngIf="!tabIndex" [ngTemplateOutlet]="leaderboardDetailsTab">
          </ng-template>

          <ng-template *ngIf="tabIndex" [ngTemplateOutlet]="leaderboardStructureTab">
          </ng-template>

        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>


<ng-template  #leaderboardStructureTab>
  <div class="header body-big-bold">
    <div class="header-title">
      LEADERBOARD STRUCTURE
    </div>
  </div>
  <form [formGroup]="leaderboardStructureForm">
    <div class="tiers-form-field form-field-with-label" formArrayName="tiers">
      <mat-label>
        Tiers*
        <i
          class="fa-solid fa-circle-info tooltip"
          [matTooltipPosition]="TooltipPositionEnum.right"
          #tooltip="matTooltip"
          [matTooltip]="tiersTooltip"
          [matTooltipClass]="TooltipPositionEnum.right"></i>
      </mat-label>
      <div *ngFor="let item of tiers.controls; let i = index" [formGroupName]="i" class="tiers-color-wrapper">
        <div class="color-name-block">
          <ngx-colors
            [style.pointer-events]="'none'"
            *ngIf="item.get('color')"
            ngx-colors-trigger
            [formControl]="item.get('color')"
          ></ngx-colors>
          <div *ngIf="item.get('name')">
            {{ item.get('name').value }}
          </div>
        </div>
        <div>
          <mat-icon class="material-icons edit" (click)="openEditTierPopup(item.value, i)">edit</mat-icon>
          <mat-icon class="material-icons close" (click)="removeTier(i)">close</mat-icon>
        </div>
      </div>
      <div class="tiers-color-wrapper dashed-border">
        <div class="color-name-block" (click)="openCreateTierPopup()" [style.cursor]="'pointer'">
          <mat-icon class="material-icons add">add</mat-icon>
          <div>
            Add new tier
          </div>
        </div>
      </div>
    </div>
    <div class="tiers-specification-form-field form-field-with-label">
      <mat-label>
        Tier Points Specification*
      </mat-label>
    </div>
    <mat-radio-group
      color="primary"
      (change)="changeValidation($event)"
      [value]="radioIndex"
    >
      <mat-radio-button [value]="0">Point Range</mat-radio-button>
      <mat-radio-button [value]="1">Exact Points</mat-radio-button>
      <mat-radio-button [value]="2">User Position</mat-radio-button>
    </mat-radio-group>
  </form>
  <ng-template *ngIf="!radioIndex" [ngTemplateOutlet]="rangeSection">
  </ng-template>

  <ng-template *ngIf="radioIndex === 1" [ngTemplateOutlet]="exactPointsSection">
  </ng-template>

  <ng-template *ngIf="radioIndex === 2" [ngTemplateOutlet]="userPositionSection">
  </ng-template>
</ng-template>

<ng-template #userPositionSection>
  <form [formGroup]="leaderboardStructureForm">
    <div class="tiers-form-field form-field-with-label" formArrayName="tiers">
      <div *ngFor="let item of tiers.controls; let i = index" [formGroupName]="i" class="tiers-min-max-wrapper">
        <div class="tiers-min-max-block">
          <div *ngIf="item.get('name')" class="tiers-min-max-block-name">
            {{ item.get('name').value }}
          </div>
          <div class="min-max-wrapper">
            <custom-input
              [placeholder]="'Min Position'"
              [type]="'number'"
              [formControl]="item.get('minPosition')">
            </custom-input>
            <div class="dash-block">
              —
            </div>
            <custom-input
              [placeholder]="'Max Position'"
              [type]="'number'"
              [formControl]="item.get('maxPosition')">
            </custom-input>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="leaderboardStructureForm">
    <div class="tiers-prize-pool-field form-field-with-label" formArrayName="tiers">
      <mat-label *ngIf="tiers.controls.length">
        Prize Pool Setup*
      </mat-label>
      <div *ngFor="let item of tiers.controls; let i = index" [formGroupName]="i" class="prize-pool-wrapper">
        <div class="prize-pool-block">
          <div *ngIf="item.get('name')" class="prize-pool-block-name">
            {{ item.get('name').value }}
          </div>
          <div>
            <custom-input
              [placeholder]="'Enter Prize Amount'"
              [type]="'number'"
              [formControl]="item.get('positionPrize')">
            </custom-input>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>


<ng-template #rangeSection>
  <form [formGroup]="leaderboardStructureForm">
    <div class="tiers-form-field form-field-with-label" formArrayName="tiers">
      <div *ngFor="let item of tiers.controls; let i = index" [formGroupName]="i" class="tiers-min-max-wrapper">
        <div class="tiers-min-max-block">
          <div *ngIf="item.get('name')" class="tiers-min-max-block-name">
            {{ item.get('name').value }}
          </div>
          <div class="min-max-wrapper">
            <custom-input
              [placeholder]="'Min Points'"
              [type]="'number'"
              [formControl]="item.get('minPoints')">
            </custom-input>
            <div class="dash-block">
              —
            </div>
            <custom-input
              [placeholder]="'Max Points'"
              [type]="'number'"
              [formControl]="item.get('maxPoints')">
            </custom-input>
            <div class="points-block">
              pts
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="leaderboardStructureForm">
    <div class="tiers-prize-pool-field form-field-with-label" formArrayName="tiers">
      <mat-label *ngIf="tiers.controls.length">
        Prize Pool Setup*
      </mat-label>
      <div *ngFor="let item of tiers.controls; let i = index" [formGroupName]="i" class="prize-pool-wrapper">
        <div class="prize-pool-block">
          <div *ngIf="item.get('name')" class="prize-pool-block-name">
            {{ item.get('name').value }}
          </div>
          <div>
            <custom-input
              [placeholder]="'Enter Prize Amount'"
              [type]="'number'"
              [formControl]="item.get('prize')">
            </custom-input>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #exactPointsSection>
  <form [formGroup]="leaderboardStructureForm">
    <div class="tiers-exact-points-field form-field-with-label" formArrayName="tiers">
      <div *ngFor="let item of tiers.controls; let i = index" [formGroupName]="i" class="exact-points-wrapper">
        <div class="exact-points-block">
          <div *ngIf="item.get('name')" class="exact-points-block-name">
            {{ item.get('name').value }}
          </div>
          <div class="exact-points-fields-wrapper">
            <custom-input
              [type]="'number'"
              [placeholder]="'Enter Points'"
              [formControl]="item.get('exactPoints')">
            </custom-input>
            <div class="points-block">
              pts
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="leaderboardStructureForm">
    <div class="tiers-prize-pool-field form-field-with-label" formArrayName="tiers">
      <mat-label *ngIf="tiers.controls.length">
        Prize Pool Setup*
      </mat-label>
      <div *ngFor="let item of tiers.controls; let i = index" [formGroupName]="i" class="prize-pool-wrapper">
        <div class="prize-pool-block">
          <div *ngIf="item.get('name')" class="prize-pool-block-name">
            {{ item.get('name').value }}
          </div>
          <div>
            <custom-input
              [placeholder]="'Enter Prize Amount'"
              [type]="'number'"
              [formControl]="item.get('exactPrize')">
            </custom-input>
          </div>
        </div>
      </div>
    </div>
  </form>

</ng-template>

<ng-template #leaderboardDetailsTab>
  <form [formGroup]="leaderboardDetailsForm">
    <custom-input
      [formControl]="leaderboardDetailsForm.get('name')"
      placeholder="Enter leaderboard name">
      Leaderboard Name*
    </custom-input>
    <div class="dates-controllers">
      <custom-date-input
        [formControl]="leaderboardDetailsForm.get('startDate')">
        Start Date*
      </custom-date-input>
      <custom-date-input
        [formControl]="leaderboardDetailsForm.get('endDate')">
        End Date*
      </custom-date-input>
    </div>
  </form>
  <div>
    <button
      mat-raised-button
      color="primary"
      [disabled]="isConfirmDisabled()"
      (click)="getRoundsData()">
      Confirm
    </button>
    <i
      class="fa-solid fa-circle-info tooltip"
      [matTooltipPosition]="TooltipPositionEnum.right"
      #tooltip="matTooltip"
      [matTooltip]="confirmButtonTooltip"
      [matTooltipClass]="TooltipPositionEnum.right"></i>
  </div>
  <form [formGroup]="leaderboardRoundsForm"
        *ngIf="(roundsForLeaderBoardData$ | async).length"
        class="rounds-block"
  >
    <custom-select
      [selectOptions]="roundsForLeaderBoardData$ | async"
      [multiple]="true"
      [multipleDisableEnabled]="true"
      [placeholder]="'Select rounds'"
      formControlName="selectedRounds">
      Selected Rounds*
    </custom-select>
  </form>
  <div *ngIf="!(roundsForLeaderBoardData$ | async).length && this.data" class="rounds-block">
    No rounds found for selected time range
  </div>
</ng-template>

