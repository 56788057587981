<div class="builder-tabs-container">
  <div class="builder-wrapper">
    <div class="header-pannel">
      <div class="header-container">
        <div class="general-header">
          <mat-icon class="material-icons close" (click)="closeDialog()">close</mat-icon>
          <h2 class="popup-header">{{header}}</h2>
        </div>
        <div class="buttons-container">
          <button
            *ngIf="!(isRoundFinished$ | async); else submitAnswers"
            (click)="saveRound()"
            mat-raised-button
            color="primary">
            Save
          </button>
          <ng-template #submitAnswers>
            <button
              [disabled]="isDisable() | async"
              (click)="saveCorrectAnswers()"
              mat-raised-button
              color="primary">
              Submit results
            </button>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="tabs-details" *ngIf="isLoaded$ | async">
      <mat-tab-group animationDuration="0ms" [selectedIndex]="tabIndex" (selectedTabChange)="selectTab($event)" dynamicHeight>
        <mat-tab
          *ngFor="let label of labelArray"
          [label]="label">
          <ng-template *ngIf="!tabIndex" [ngTemplateOutlet]="detailsFormTemplate">

          </ng-template>
          <ng-template *ngIf="tabIndex" [ngTemplateOutlet]="correctAnswersFormTemplate">

          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<ng-template #detailsFormTemplate>
  <div class="tab-name body-big-bold">STREAK DAY DETAILS</div>
  <form [formGroup]="detailsForm">
    <custom-input
      [formControl]="detailsForm.get('name')"
      placeholder="Enter game name">
      Round name*
    </custom-input>
    <div class="dates-controllers">
      <custom-date-input
        [formControl]="detailsForm.get('openDate')">
        Open Date & Time*
      </custom-date-input>
      <custom-date-input
        [formControl]="detailsForm.get('closeDate')">
        Close Date & Time*
      </custom-date-input>
    </div>
    <select-create-event
      *ngIf="detailsForm.get('sportEvent')"
      formControlName="sportEvent">
    </select-create-event>
  </form>
</ng-template>
<ng-template #correctAnswersFormTemplate>
  <div class="tab-name body-big-bold">QUESTIONS</div>
  <ng-container *ngIf="questions$ | async as questions">
    <ng-container [formGroup]="correctAnswersForm" *ngIf="questions && questions.length; else noQuestions">
      <ng-container *ngFor="let questionItem of correctAnswersForm?.get('questionsForm')?.controls; let i = index">
        <ng-container [ngSwitch]="questions[i].type">
          <ng-container [formGroup]="questionItem">
            <div class="question-answer-card">
              <right-score-answer
                *ngSwitchCase="QuestionTypesEnum.SCORE"
                card-content
                [_question]="questions[i]"
                [voidOptions]="correctAnswerHelperService.voidTypeOptions"
                [event]="questions[i].sportEvent || data.round.sportEvent">
              </right-score-answer>

              <right-score-answer
                *ngSwitchCase="QuestionTypesEnum.SCORE_PLUS"
                card-content
                [_question]="questions[i]"
                [voidOptions]="correctAnswerHelperService.voidTypeOptions"
                [event]="questions[i].sportEvent || data.round.sportEvent">
              </right-score-answer>

              <right-range-answer
                *ngSwitchCase="QuestionTypesEnum.RANGE"
                [voidOptions]="correctAnswerHelperService.voidTypeOptions"
                card-content
                [_question]="questions[i]"
              >
              </right-range-answer>

              <right-list-answer
                *ngSwitchDefault
                card-content
                [voidOptions]="correctAnswerHelperService.voidTypeOptions"
                [_question]="questions[i]"
              >
              </right-list-answer>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noQuestions>
    No questions for this round
  </ng-template>
</ng-template>
<div *ngIf="!(isLoaded$ | async)" class="disabled-overlay">
  <mat-spinner [diameter]="30" color="primary"></mat-spinner>
</div>
