import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UriConfig} from "../../app.config";
import {VendorStatusEnum} from "../../common/Enums/VendorStatusEnum";
import {VendorSettingDetailsModel} from "../../common/models/VendorSettingDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class AdminsService {

  isNeedUpdate$ = new BehaviorSubject<boolean>(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {
  }

  getVendorsList(body) {
    let params = new HttpParams()
    Object.keys(body).forEach(param => {
      if (body[param]) {
        params = params.set(param, body[param]);
      }
    });
    return this.http.get<{ records: VendorStatusEnum[], total: number }>(this.uriConfig.admins + '/vendors', {params});
  }

  getVendorDetailsById(id): Observable<VendorSettingDetailsModel> {
    return this.http.get<VendorSettingDetailsModel>(this.uriConfig.admins + '/vendor/' + id);
  }

  updateVendorProfile(body, id) {
    return this.http.patch(this.uriConfig.admins + '/vendor/' + id, body);
  }

  createVendor(body): Observable<void> {
    const formData = new FormData();

    Object.keys(body).forEach(value => {
      if (body[value]) {
        formData.append(value, body[value]);
      }
    })

    return this.http.post<void>(this.uriConfig.auth + '/vendor', formData)
  }

  changeVendorStatus(body, userId) {
    return this.http.patch(this.uriConfig.admins + '/state/' + userId, body);
  }

  clearCache() {
    return this.http.delete(this.uriConfig.admins + '/clear-cache')
  }

  needUpdateVendorsTable() {
    this.isNeedUpdate$.next(true);
  }

  getWinnersTableStatus() {
    return this.isNeedUpdate$;
  }
}
