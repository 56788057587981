<div class="locale-builder-container">
  <div class="locale-builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="dialogRef.close();">close</mat-icon>
        <h2 class="popup-header">Add Language</h2>
      </div>
      <div class="control-header">
        <button
          [disabled]="isDisableAddButton()"
          mat-raised-button
          color="primary"
          (click)="addLocale()">
          Add
        </button>
      </div>
    </div>
  </div>
  <div class="locale-builder-body">
    <div class="locale-builder-content">
      <div class="locale-details">
        <form [formGroup]="localeFormGroup">

          <div class="form-field-with-label">
            <mat-form-field appearance="outline">
              <input
                class="locale-prefix"
                matInput
                #localeInput
                placeholder="Search"
                [matAutocomplete]="eventAuto"
                [formControlName]="'locales'">
              <mat-icon
                class="locale-suffix"
                *ngIf="isStringValue(localeFormGroup.get('locales').value)"
                matPrefix>
                search
              </mat-icon>
              <div matPrefix *ngIf="localeFormGroup.get('locales').value?.flag">
                <img class="prefix-image" [src]="localeFormGroup.get('locales').value?.flag">
              </div>
              <mat-autocomplete
                class="locale-autocomplete-style"
                [displayWith]="displayFn"
                #eventAuto="matAutocomplete">
                <mat-option
                  class="event-option"
                  *ngFor="let event of filteredLocales | async"
                  [value]="event"
                >
                  <div class="mat-option-item event">
                    <div class="text-item-part">
                      <ng-container *ngIf="event.flag; else empty">
                        <img [src]="event.flag">
                      </ng-container>
                      <ng-template #empty>
                        <div class="empty-image"></div>
                      </ng-template>
                      <div class="name">{{event.country}}</div>
                    </div>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-error *ngIf="!(filteredLocales | async).length || isShowError()">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{APP_DATA.MANDATORY_SELECT}}</span>
            </mat-error>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

