import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UriConfig } from "../../app.config";
import { RoundCreationInstructionsModel } from "../../common/models/RoundCreationInstructionsModel";

@Injectable({
  providedIn: 'root'
})
export class RoundCreationInstructionsService {

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {
  }

  getRoundCreationInstructions(): Observable<RoundCreationInstructionsModel> {
    return this.http.get<RoundCreationInstructionsModel>(this.uriConfig.roundCreationInstructions);
  }


  addRoundCreationInstructions(instructions: RoundCreationInstructionsModel): Observable<any> {
    return this.http.post<RoundCreationInstructionsModel>(this.uriConfig.roundCreationInstructions, instructions);
  }

}
