<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="close()">close</mat-icon>
        <h2 class="popup-header">{{data.label['Name']}} <span *ngIf="data.locale">({{data.locale.country}})</span></h2>
      </div>
    </div>
  </div>
  <div class="builder-body" [ngClass]="{'editor': data.label.isHTMLMarkup }">
    <div class="builder-content">
      <div class="default-content body-big-regular" *ngIf="!data.label.isHTMLMarkup && data.locale">
        Default text: {{data.label['Text value']}}
      </div>
      <form [formGroup]="form" *ngIf="!data.label.isJson; else jsonEditor">
        <ng-container *ngIf="data.label.isHTMLMarkup; else regularInput">
          <html-editor-translations [editorFormControl]="form.get('editorContent')"></html-editor-translations>
        </ng-container>
        <ng-template #regularInput>
          <mat-form-field appearance="outline" [floatLabel]="'always'">
            <textarea matInput
                      [formControlName]="'editorContent'"
                      placeholder="label text"></textarea>
          </mat-form-field>
          <mat-error
            class="select-error"
            *ngIf="variableInLabel">
            <i class="fas fa-exclamation-circle"></i>
            <span class="text"> {{variableInLabel}}</span>
          </mat-error>
        </ng-template>
      </form>
      <ng-template #jsonEditor>
        <form [formGroup]="jsonForm">
          <div formArrayName="items">
            <div class="item-container" *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
              <mat-form-field appearance="outline" [floatLabel]="'always'">
                <input trim
                      matInput
                      [formControl]="item.get('title')"
                      placeholder="label text">
              </mat-form-field>
              <html-editor-translations [editorFormControl]="item.get('content')"></html-editor-translations>
              <div class="form-control-button-container">
                <button
                  class="regular-button secondary-button delete"
                  mat-flat-button
                  color="primary"
                  (click)="removeItem(i)"
                  >Delete</button>
              </div>
              <div class="divider"></div>
            </div>
          </div>
        </form>
        <div class="form-control-button-container">
          <button
            class="regular-button secondary-button"
            mat-flat-button
            color="primary"
            (click)="addItem({title: '', content: null})"
          >Add Question</button>
        </div>
      </ng-template>
    </div>
    <div class="buttons-container">
      <button mat-raised-button color="primary" [disabled]="isButtonDisabled()"
              (click)="saveDoc()">Save
      </button>
      <button mat-raised-button (click)="close()">Cancel</button>
    </div>
  </div>
</div>
