import {TeamDetailsModel} from "./TeamDetailsModel";

export class EventDetailsModel {
  id: number
  name: string
  startDate: string
  closeDate: string
  homeTeamSportEvents: TeamDetailsModel
  awayTeamSportEvents: TeamDetailsModel
  score: string
  externalId: string
  externalData: ExternalSportEventData
}


export class ExternalSportEventData {
  eventId: number
}
