<div class="result-container">
  <div class="general-header">
    <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
    <div class="header body-medium-medium">INTEGRITY CHECK RESULTS</div>
  </div>
  <div class="result-body" *ngIf="healthCheckResults$ | async as healthCheckResults">
    <div *ngFor="let item of healthCheckResults" class="result-item-container">
      <div class="result-item">
        <div class="rule">
          {{item.rule}}
        </div>
        <div class="status" [style.color]="getColor(item.result)">
          {{item.result}}
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
<div *ngIf="!(isLoaded$ | async)" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
