import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FilesService} from "../../../../core/services/files.service";
import {BehaviorSubject, filter, Subject, takeUntil} from "rxjs";
import {SnackBarService} from "../../../../core/services/snack-bar.service";
import { TooltipPositionEnum } from '../../../Enums/TooltipPositionEnum';
import {APP_DATA} from "../../../../general.app.config";

@Component({
  selector: 'drag-and-drop-file',
  templateUrl: './drag-and-drop-file.component.html',
  styleUrls: ['./drag-and-drop-file.component.scss']
})
export class DragAndDropFileComponent implements OnDestroy {

  constructor(
    private filesService: FilesService,
    private snackBarService: SnackBarService
  ) {
  }

  isMandatoryError = false;

  @Input() title: string;

  @Input() isRequired: boolean;

  @Input() isDisabled: boolean;

  @Input() isDisabledDelete: boolean;

  @Input() tooltipText: string;

  @Input() set _isMandatoryError( flag) {
    this.isMandatoryError = flag;
  }

  /**
   * If current component opening with existing image assing this image to show it
   */
  @Input() set image (image) {
    if (image.url) {
      this.getImgContentType(image.url)

      this.uploadedFiles = image;
    }
  }

  /**
   * Send event with uploaded image data (url and id)
   */
  @Output() fileUpload = new EventEmitter<any>();

  /**
   * Send file loaded on drag and drop
   */
  @Output() file = new EventEmitter<any>();

  TooltipPositionEnum = TooltipPositionEnum;

  /**
   * Use for show processing styles
   */
  processing: boolean;
  /**
   * Indicator to show loader
   */
  isLoading = false;

  /**
   * Assigned uploaded file to show image
   */
  uploadedFiles: any;

  /**
   * Store size of image
   */
  imageSize: number;

  /**
   * Image size unit
   */
  imageSizeSymbol: string;

  appData = APP_DATA;

  /**
   * Flag to show to big size (4mb) error
   */
  isImageTooBig = false;

  private unsubscribe$: Subject<void> = new Subject();

  /**
   * If drag event canceled
   */
  onDragOver(event) {
    event.preventDefault();
  }

  /**
   * If drag event finished
   */
  onDropSuccess(event) {
    event.preventDefault();
    if (this.isDisabled) return;

    this.onFileChange(event);
  }

  /**
   * If input event field vas changed in any way(drag and drop, click)
   */
  onChange(event) {
    this.uploadImage(event);
  }

  private onFileChange(files) {
    this.uploadImage(files)
  }


  uploadImage(event) {
    this.isLoading = true;
    this.isMandatoryError = false;
    const files = event.dataTransfer || event.target;
    this.uploadFile(files.files[0]);
  }

  /**
   * Create body for saving image, get image size
   */
  uploadFile(file) {
    this.isImageTooBig = file.size > ((1024*1024) * 4);
    if (this.isImageTooBig) {
      this.isLoading = false;
      return;
    }
    this.file.emit(file);
    const formdata = new FormData();
    formdata.append("file", file, file.name);
    this.filesService.uploadImage(formdata)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(uploadedFile => {
          this.isLoading = false;
          this.uploadedFiles = uploadedFile;
          this.getImgContentType(uploadedFile.url)
          this.fileUpload.emit(uploadedFile);
        },
        err => {
          this.isLoading = false;
          this.snackBarService.showSnackBar(err.error.message, true);
        });
  }

  /**
   * Delete uploaded file
   */
  deleteImage() {
    this.uploadedFiles = null;
    this.fileUpload.emit(null);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Get image size
   */
  getImgContentType(img) {
    const xml = new XMLHttpRequest();

    xml.open('GET', img, true);
    xml.send();
    const imageSize$ = new BehaviorSubject<string>(null);

    xml.onreadystatechange = function() {
      if (this.readyState === this.HEADERS_RECEIVED) {
        imageSize$.next(xml.getResponseHeader('Content-Length'));
      }
    };
    imageSize$
      .pipe(
        filter(value => !!value),
        takeUntil(this.unsubscribe$)
        )
      .subscribe(val => {
        this.processImageSize(val)
    })
  }

  /**
   * Convert image size to unit
   */
  processImageSize(size) {
    if (size < 1024) {
      this.imageSize = size;
      this.imageSizeSymbol = 'b'
      return
    }
    if (size > (1024*1024)) {
      this.imageSize = Math.round((size / (1024*1024)) * 10) / 10;
      this.imageSizeSymbol = 'Mb';
      return;
    }
    this.imageSize = Math.floor(size / 1024);
    this.imageSizeSymbol = 'Kb';
  }
}
