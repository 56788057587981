<div class="game-builder-container">
  <div class="game-builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
        <h2 class="popup-header">{{title}}</h2>
        <status-chip [status]="data.state" *ngIf="data" [chipType]="LabelChipTypeEnum.VENDOR_STATUS">
          {{textService.capitalizeFirstLetter(data.state)}}
        </status-chip>
      </div>
      <div class="control-header">
        <button
          [disabled]="(!this.vendorDataFormGroup?.valid || !isLoaded) && isButtonWasClicked"
          mat-raised-button
          color="primary"
          (click)="saveVendor()">
          Save
        </button>
      </div>
    </div>
  </div>
  <div class="game-builder-body">
    <div class="game-builder-content">
      <div class="prize-details">
        <form [formGroup]="vendorDataFormGroup">
          <!--          Contacts group-->

          <div class="form-field-group">
            <div class="form-field-with-label">
              <mat-label>Phone</mat-label>
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  trim
                  trimDoubleSpace
                  onkeypress="return /[0-9+]/i.test(event.key)"
                  matInput
                  placeholder="Enter phone"
                  [formControlName]="'phone'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('phone', 'required') && isShowError('phone','maxlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MAX_LENGHT_32}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('phone', 'required') && isShowError('phone','invalidPhone')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.PHONE_ERROR}}</span>
              </mat-error>
            </div>
            <div class="form-field-with-label">
              <mat-label>Email*</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  trim
                  trimDoubleSpace
                  placeholder="Enter email"
                  [formControlName]="'email'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="isShowError('email', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MANDATORY}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('email', 'required') && isShowError('email','maxlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MAX_LENGHT_70}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('email', 'required') && isShowError('email','email')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.EMAIL_ERROR}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="isShowError('email','server')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{serverErrorMassage}}</span>
              </mat-error>
            </div>
          </div>

          <!--          Name group-->
          <div class="form-field-group">
            <div class="form-field-with-label">
              <mat-label>First Name*</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  trim
                  trimDoubleSpace
                  placeholder="Enter first name"
                  [formControlName]="'firstName'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="isShowError('firstName', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MANDATORY}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('firstName', 'required') && isShowError('firstName','maxlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MAX_LENGHT_15}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('firstName', 'required') && isShowError('firstName','minlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MIN_LENGHT_3}}</span>
              </mat-error>
            </div>
            <div class="form-field-with-label">
              <mat-label>Last Name*</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  trim
                  trimDoubleSpace
                  placeholder="Enter last name"
                  [formControlName]="'lastName'">
              </mat-form-field>
              <mat-error
                class="select-error"
                *ngIf="isShowError('lastName', 'required')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MANDATORY}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('lastName', 'required') && isShowError('lastName','maxlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MAX_LENGHT_15}}</span>
              </mat-error>
              <mat-error
                class="select-error"
                *ngIf="!isShowError('lastName', 'required') && isShowError('lastName','minlength')">
                <i class="fas fa-exclamation-circle"></i>
                <span class="text">{{appData.MIN_LENGHT_3}}</span>
              </mat-error>
            </div>
          </div>
          <div class="form-field-with-label">
            <mat-label>Locales</mat-label>
            <mat-form-field appearance="outline" class="example-full-width">
              <input
                matInput
                placeholder="Select locales"
                [matAutocomplete]="auto"
                [formControlName]="'locales'">
              <mat-icon
                (click)="closeAutocomplete($event)"
                class="drop-arrow"
                matSuffix>arrow_drop_down</mat-icon>
            </mat-form-field>

            <mat-autocomplete
              class="custom-autocomplete-style"
              #auto="matAutocomplete"
              [displayWith]="displayFn">
              <mat-option
                [ngClass]="{'not-selected': !locale.selected}"
                *ngFor="let locale of filteredLocales | async"
                [value]="selectedLocales">
                <span class="custom-wrapper" (click)="optionClicked($event, locale)">
                  <mat-checkbox
                    color="primary"
                    class="body-medium-regular"
                    [checked]="locale.selected"
                    (change)="toggleSelection(locale)"
                    (click)="$event.stopPropagation()">
                    {{ locale.country }}
                  </mat-checkbox>
                </span>
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="form-field-group">
            <div class="form-field-with-label">
              <mat-label>Is streak vendor</mat-label>
              <div class="toggle-container">
                <mat-checkbox
                  color="primary"
                  [formControlName]="'isStreakVendor'">
                  Is streak vendor
                </mat-checkbox>
              </div>
            </div>
            <div class="form-field-with-label">
              <mat-label>Is test vendor</mat-label>
              <div class="toggle-container">
                <mat-checkbox
                  color="primary"
                  [formControlName]="'isTestTenant'">
                  Is test vendor
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="form-field-with-label">
            <mat-label>Unauthorized users</mat-label>
            <div class="toggle-container">
              <mat-slide-toggle
                color="primary"
                [formControlName]="'allowUnauthorizedUsers'">
                Allow unauthorized users
              </mat-slide-toggle>
            </div>
          </div>


          <div class="form-field-with-label" [ngClass]="{'slack-form-field': vendorDataFormGroup.getRawValue()['slackUpdatesEnabled']}">
            <mat-slide-toggle
              color="primary"
              formControlName="slackUpdatesEnabled">
              Slack updates enabled
            </mat-slide-toggle>
            <mat-label class="slack-label">This option can be changed only in case if the Slack notifications enabled flag is activated</mat-label>
          </div>

          <div class="form-field-with-label">
            <mat-label>Slack updates channel ID</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Enter slack updates channel ID"
                formControlName="slackChannelId"
              >
            </mat-form-field>
            <mat-label class="slack-input-label">Please make sure that the correct Slack channel ID is entered into the field. Otherwise, the notifications won’t be displayed in Slack</mat-label>
          </div>

          <div class="form-field-with-label">
            <mat-label>Brand Name</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                trimDoubleSpace
                placeholder="Enter brand name"
                [formControlName]="'name'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('name','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="isShowError('name','minlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MIN_LENGHT_2}}</span>
            </mat-error>
          </div>
          <div class="form-field-with-label">
            <mat-label>Website Prefix*</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                trimDoubleSpace
                placeholder="Enter website prefix"
                [formControlName]="'websitePrefix'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('websitePrefix', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MANDATORY}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('websitePrefix', 'required') && isShowError('websitePrefix','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT_30}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('websitePrefix', 'required') && isShowError('websitePrefix','minlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MIN_LENGHT_2}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('websitePrefix', 'required') && isShowError('websitePrefix','pattern')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.WEBSITE_PREFIX_VALIDATOR}}</span>
            </mat-error>
          </div>


            <drag-and-drop-file
              class="brand-image-popup"
              [isRequired]="false"
              [tooltipText]="'We recommend using a 270px x 200px image resolution (minimum). Max image size is 4MB'"
              [isDisabledDelete]="false"
              [image]="{url: image?.url}"
              [title]="'Brand Image'"
              (file)="onCreateFile($event, 'brandImage')"
              (fileUpload)="uploadFile($event, 'image')"
            ></drag-and-drop-file>

          <div class="form-field-with-label chips">
            <mat-label>Send submission report additionally to:</mat-label>
            <mat-form-field
              appearance="outline"
              [ngClass]="{
              'content': reportReceiversEmail?.length,
              'invalid': vendorDataFormGroup.get('submissionReportReceivers').invalid}">
              <mat-chip-grid #chipGrid aria-label="Fruit selection" >
                <mat-chip-row *ngFor="let email of reportReceiversEmail" (removed)="removeReceiversEmail(email)">
                  {{email}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip-row>
                <input
                  [formControlName]="'submissionReportReceivers'"
                  matInput
                  trim
                  (focusout)="onReportReceiversFocusOut($event)"
                  [placeholder]="reportReceiversEmail?.length ? null : 'Enter email addresses separated by comma'"
                  [matChipInputFor]="chipGrid"
                  (matChipInputTokenEnd)="addReceiverEmail($event)"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
              </mat-chip-grid>
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="vendorDataFormGroup.get('submissionReportReceivers').hasError('pattern')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.EMAIL_ERROR}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="vendorDataFormGroup.get('submissionReportReceivers').hasError('duplicateEmail')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.DUPLICATE_EMAIL}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="vendorDataFormGroup.get('submissionReportReceivers').hasError('duplicateEmails')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.DUPLICATE_EMAIL}}</span>
            </mat-error>
          </div>
          <div class="form-field-with-label">
            <mat-label>Brand Upsell Source Url</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                trimDoubleSpace
                placeholder="Enter brand upsell source url"
                [formControlName]="'upsellSourceUrl'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('upsellSourceUrl','url')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.URL}}</span>
            </mat-error>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
<div *ngIf="!isLoaded" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
