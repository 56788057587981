<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onCloseModal()">close</mat-icon>
        <h2 class="popup-header">Create game from CSV file</h2>
      </div>
    </div>
  </div>
  <div class="builder-body">
    <div class="builder-content">
      <div class="class-details">
        <h4>Select a game type to download the CSV file template</h4>
        <form [formGroup]="createGameFromSCVForm" (submit)="getTemplateFile()">
          <div class="form-field-with-label">
            <mat-form-field appearance="outline">
              <ng-container>
                <mat-select
                  [formControlName]="'gameType'"
                >
                  <mat-option *ngFor="let type of gameType" [value]="type.value">
                    {{type.displayValue}}
                  </mat-option>
                </mat-select>
              </ng-container>
            </mat-form-field>
          </div>
          <button mat-raised-button type="submit" color="primary" class="template-button">Download a template</button>
        </form>
        <div>
          <p>Upload your CSV file to create a game. The game will appear in the Draft status after confirming the changes. Please make sure all details are correct for the game before publishing</p>
          <div class="buttons-container">
            <div class="file-input-container">
              <button mat-stroked-button color="primary" (click)="triggerFileInput()">{{ fileName ?? 'Upload a CSV file' }}</button>
              <input  #fileInput type="file" class="file-input" accept="text/csv" (change)="onFileChange($event)"/>
            </div>
            <button mat-raised-button color="primary" [disabled]="!selectedFile" (click)="onConfirmClick()">Confirm</button>
          </div>
        </div>
        <mat-error
          class="select-error"
          *ngIf="errorMessages">
          <div class="text" *ngFor="let error of errorMessages">
            <i class="fas fa-exclamation-circle"></i>
            {{error}}
          </div>
        </mat-error>
      </div>
    </div>
  </div>
</div>
