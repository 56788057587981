<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="closeDialog()">close</mat-icon>
        <h2 class="popup-header">{{header}}</h2>
      </div>
    </div>
  </div>
  <div class="builder-body">
    <div class="builder-content">
      <div class="class-details">
        <form [formGroup]="leaderboardDetailsForm">
          <div class="form-field-with-label">
            <mat-label>
              Leaderboard Name*
            </mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trimDoubleSpace
                placeholder="Leaderboard Name"
                [formControlName]="'name'">
            </mat-form-field>
            <mat-error *ngIf="leaderboardDetailsForm.get('name').touched && leaderboardDetailsForm.get('name').hasError('required')">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{appData.MANDATORY}}</span>
            </mat-error>
            <mat-error
              *ngIf="!leaderboardDetailsForm.get('name').hasError('required') && leaderboardDetailsForm.get('name').hasError('maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{appData.MAX_LENGHT}}</span>
            </mat-error>
            <mat-error
              *ngIf="!leaderboardDetailsForm.get('name').hasError('required') && leaderboardDetailsForm.get('name').hasError('minlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{appData.MIN_LENGHT_2}}</span>
            </mat-error>
          </div>

          <div class="dates-controllers">
            <custom-date-input
              formControlName="startDate"
              [pickerType]="'both'">
              Start Date*
            </custom-date-input>
            <custom-date-input
              formControlName="endDate"
              [pickerType]="'both'">
              End Date*
            </custom-date-input>
          </div>

          <div class="control-header">
            <button
              mat-raised-button
              color="primary"
              [disabled]="!leaderboardDetailsForm.valid || !leaderboardDetailsForm.dirty"
              (click)="saveLeaderboard()">
              Save
            </button>
          </div>
        </form>
        <form [formGroup]="leaderboardRoundsForm"
              *ngIf="(roundsForLeaderBoardData$ | async).length">
          <custom-select
            [selectOptions]="roundsForLeaderBoardData$ | async"
            [multiple]="true"
            [multipleDisableEnabled]="true"
            formControlName="selectedRounds">
            Selected Rounds*
          </custom-select>
          <button

            mat-raised-button
            color="primary"
            [disabled]="!leaderboardRoundsForm.valid || !leaderboardRoundsForm.dirty"
            (click)="saveSelectedRounds()">
            Save Selected Rounds
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
