import { Component, Input } from '@angular/core';

@Component({
  selector: 'cell-with-label',
  templateUrl: './cell-with-label.component.html',
  styleUrls: ['./cell-with-label.component.scss']
})
export class CellWithLabelComponent {

  @Input() labelName = 'LANDING';

  @Input() labelColor = '#D5EE92';

  @Input() revertHorizontalDisplay = false;

}
