import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StreaksService } from "../../../../../core/services/streaks.service";

@Component({
  selector: 'level-points-group',
  templateUrl: './level-points-group.component.html',
  styleUrls: ['./level-points-group.component.scss']
})
export class LevelPointsGroupComponent {

  difficultyArray = this.streaksService.getListOfDifficultyLevels();

  @Input() levelPoints: FormGroup[];

  @Input() hidePoints: boolean;

  constructor(
    private streaksService: StreaksService,
  ) {
  }

}
