import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  finalize,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of, pairwise,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
  throwError
} from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LocaleItemModel } from "../../../models/LocaleItemModel";
import { LocalizationService } from "../../../../core/services/localization.service";
import { languageCodesArray } from "../../_common-modal-components/create-vendor-modal/locale-list";
import {
  QuestionTypeService
} from "../../../../new-round-wizzard/common-wizzard-components/prediction-question/question-type.service";
import { filter, takeUntil } from "rxjs/operators";
import { QuestionTypesEnum } from "../../../Enums/QuestionTypesEnum";
import {
  getValidateRangeValue,
  PredictionQuestionFormSectionService,
  requiredListGripAnswersLength,
  requiredListsAnswersLength,
  requiredOptionAnswersLength,
} from "../../../../new-round-wizzard/prediction-question-section-form.service";
import { AnswerGroupsService } from "../../../../new-round-wizzard/answer-groups.service";
import {
  CreateAnswerGroupModalService
} from "../../../../new-round-wizzard/create-answer-group-modal/create-answer-group-modal.service";
import { DialogService } from "../../_base-component/dialog/dialog.service";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { RounWizzardAnswerService } from "../../../../new-round-wizzard/round-wizard-answer.service";
import { MoveDirectionEnum } from "../../../Enums/MoveDirectionEnum";
import { APP_DATA } from "../../../../general.app.config";
import { QuestionStreakService } from "../../../../core/services/question-streak.service";
import { StreakQuestionModel } from "../../../models/StreakQuestionModel";
import { StreakQuestionDifficultyEnum } from "../../../Enums/StreakQuestionDifficultyEnum";
import { TextService } from "../../../../core/services/text.service";
import { TooltipPositionEnum } from "../../../Enums/TooltipPositionEnum";
import { StreakAnswerModel } from "../../../models/StreakAnswerModel";


@Component({
  selector: 'create-streak-question',
  templateUrl: './create-streak-question.component.html',
  styleUrls: ['./create-streak-question.component.scss'],
})
export class CreateStreakQuestionComponent implements OnInit, OnDestroy {

  questionForm: FormGroup;

  questionTypeForm: FormGroup;

  localesForm: FormGroup;

  locales$: Observable<LocaleItemModel[]>;

  isLoaded$ = new BehaviorSubject(true);

  locales: LocaleItemModel[];

  answerGroupList$: Observable<any>;

  answerGroupList: any;

  hideImage = true;

  currentQuestion: StreakQuestionModel;

  currentQuestionId = null;

  mockAnswer : StreakAnswerModel = {externalOutcomeId: null, groupName: null, id: 0, imageUrl: null, text: null};

  externalAnswerOptions = ['AWAY_TEAM', 'HOME_TEAM', 'DRAW'];

  tooltipTextIcon = 'We recommend using a 24px x 24px image resolution. Max image size is 4MB';

  fullLocaleList = languageCodesArray;

  isAnswersDirty$ = new BehaviorSubject<boolean>(false);

  isAnswersDirty2st$ = new BehaviorSubject<boolean>(false);

  protected readonly MoveDirectionEnum = MoveDirectionEnum;

  protected readonly appData = APP_DATA;

  protected readonly QuestionTypesEnum = QuestionTypesEnum;

  private unsubscribe$: Subject<void> = new Subject<void>();


  constructor(
    public dialogRef: MatDialogRef<CreateStreakQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localizationService: LocalizationService,
    private answerGroupsService: AnswerGroupsService,
    private createAnswerGroupModalService: CreateAnswerGroupModalService,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private roundWizardAnswerService: RounWizzardAnswerService,
    private questionSectionFormService: PredictionQuestionFormSectionService,
    private answerGroupService: AnswerGroupsService,
    private fb: FormBuilder,
    private questionStreakService: QuestionStreakService,
    private cdr: ChangeDetectorRef,
    public textService: TextService,
    public questionTypeService: QuestionTypeService,
  ) {
    this.questionTypeForm = new FormGroup({});
    this.localesForm = new FormGroup({});
    this.locales$ = this.localizationService.vendorLocales$.pipe(
      map(vendorLocale => this.prepareLocaleList(vendorLocale)),
      tap((locales) => this.locales = locales)
    );
  }

  ngOnInit(): void {

    this.initData();

    this.buildForm();
    this.initLocalesForm();

    if (this.data) {
      this.isLoaded$.next(false);
      this.fillFormWithData();
    }

    this.handleTypeChange();

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleLocalesFormChange() {
    this.localesForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(700),
      )
      .subscribe(() => {
       this.validateLocalizationTabs();
      })
  }

  handleTypeChange() {
    this.questionForm.get('type').valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.processQuestionTypeChange(data)
      })
  }

  fillFormWithData() {
    this.questionStreakService.getStreakQuestionById(this.data)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((question) => {
          if (question) {
            this.currentQuestion = question;
            this.currentQuestionId = question.id;
            this.fillQuestionFormWithData();
            this.processQuestionTypeChange(this.currentQuestion.type as QuestionTypesEnum);
          }
        }),
        finalize(() => {
          this.isLoaded$.next(true);
          this.cdr.detectChanges();
        }),
        catchError((error) => {
          if (error.error) {
            this.snackBarService.showSnackBar(error.error.message, true);
          }
          return throwError(error);
        })
      )
      .subscribe()
  }


  fillLocalesForm() {
    this.locales$
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap((locales) => {
          locales.forEach((locale) => {
            this.updateLocalizedTitle(locale);
            this.updateLocalizedAnswers(locale);
            this.updateGroupedLocalizedAnswers(locale);
          });
        })
      )
      .subscribe();
  }


  private updateLocalizedTitle(locale): void {
    const localizationTitles = this.currentQuestion.localizations;
    const localizedTitle = localizationTitles.find(title => title.locale.i18n === locale.i18n);
    const targetTitle = localizedTitle ? localizedTitle.text : '';
    this.localesForm.get(locale.country).get('text').patchValue(targetTitle);
  }

  private updateLocalizedAnswers(locale): void {
    if (!this.localesForm.get(locale.country).get('answers')) return;

    const answers = this.currentQuestion.answers;
    const translatedAnswers = answers.map(answer => {
      const translatedAnswer = answer.localizations.find(localizedAnswer => localizedAnswer.locale.i18n === locale.i18n);
      return { text: translatedAnswer ? translatedAnswer.text : '' };
    });

    this.localesForm.get(locale.country).get('answers').patchValue(translatedAnswers);
  }

  private updateGroupedLocalizedAnswers(locale): void {
    if (
      !this.localesForm.get(locale.country).get('answers1st') ||
      !this.localesForm.get(locale.country).get('answers2st')
    ) {
      return;
    }

    const answers = this.currentQuestion.answers;
    const groupedAnswers = this.getGroupedItems(answers);

    this.updateSpecificGroupedAnswers(locale, groupedAnswers[0], 'answers1st');
    this.updateSpecificGroupedAnswers(locale, groupedAnswers[1], 'answers2st');
  }

  private updateSpecificGroupedAnswers(locale, answersGroup, formControlName: string): void {
    if (!Array.isArray(answersGroup)) return;

    const translatedAnswers = answersGroup.map(answer => {
      const translatedAnswer = answer.localizations.find(localizedAnswer => localizedAnswer.locale.i18n === locale.i18n);
      return { text: translatedAnswer ? translatedAnswer.text : '' };
    });

    this.localesForm.get(locale.country).get(formControlName).patchValue(translatedAnswers);
  }


  /*Applying red color to tabs and controls that are not valid*/
  validateLocalizationTabs() {
    this.locales$.pipe(
      takeUntil(this.unsubscribe$),
      take(1),
      tap((locales) => {
        const tabs = document.querySelectorAll('.mat-mdc-tab');
        locales.forEach((locale, index) => {
          const element = tabs[index + 1];
          if (element) {
            const target = element.querySelector('.mdc-tab-indicator__content--underline');
            const targetLabel = element.querySelector('.mdc-tab__text-label');
            if (this.localesForm.get(locale.country).invalid && target && targetLabel) {
              this.localesForm.get(locale.country).markAllAsTouched();
              this.localesForm.get(locale.country).get('text').patchValue(this.localesForm.get(locale.country).get('text').value);
              (target as HTMLElement).style.opacity = '1';
              (target as HTMLElement).style.borderColor = 'red';
              (targetLabel as HTMLElement).style.color = 'red';
            } else {
              (target as HTMLElement).style.opacity = '';
              (target as HTMLElement).style.borderColor = '';
              (targetLabel as HTMLElement).style.color = '';
            }
          }
        })
      })
    ).subscribe();
  }

  addQuestion() {
    const targetRequest$ = this.currentQuestionId ? this.questionStreakService.updateStreakQuestion(
      this.questionForm.value,
      this.questionTypeForm.value,
      this.localesForm.value,
      this.locales,
      this.answerGroupList,
      this.currentQuestionId
    ) : this.questionStreakService.createStreakQuestion(
      this.questionForm.value,
      this.questionTypeForm.value,
      this.localesForm.value,
      this.locales,
      this.answerGroupList
    );
    targetRequest$.pipe(
      takeUntil(this.unsubscribe$),
      tap((data) => {
        this.snackBarService.showSnackBar(`The question with ${this.currentQuestionId ?? data.id} was ${this.currentQuestionId ? 'updated' : 'created'} successfully`);
        this.questionStreakService.needUpdateStreakQuestions$.next(true);
        this.dialogRef.close();
      }),
      catchError((error) => {
        if (error.error) {
          this.snackBarService.showSnackBar(error.error.message, true);
        }
        return throwError(error);
      })
    ).subscribe()
  }

  initLocalesForm() {
    this.locales$.pipe(
      takeUntil(this.unsubscribe$),
      take(1),
      tap((locales) => {
        locales.forEach((locale) => {
          this.localesForm.addControl(locale.country, this.initLocaleControl());
        })
      })
    ).subscribe()
  }

  initLocaleControl(): FormGroup {
    return this.fb.group({
      text: ['', [Validators.required, Validators.maxLength(255)]]
    });
  }

  addControlToLocaleAnswers(answers: StreakAnswerModel[], indexForDelete?: number) {
    this.locales$
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap((locales) => {
          locales.forEach(locale => {
            let value =  (this.localesForm.get(locale.country) as FormGroup).value.answers;
            if (typeof indexForDelete === "number") {
              value = value.filter((item, index) => index !== indexForDelete);
            }
            const formArray = this.createFormArrayWithMultipleControls(answers);
            (this.localesForm.get(locale.country) as FormGroup).setControl('answers', formArray);
            (this.localesForm.get(locale.country) as FormGroup).get('answers').patchValue(value);
          })
        })
      )
      .subscribe()
  }


  addControlsToLocaleAnswers1st(answers: StreakAnswerModel[], indexForDelete?: number) {
    this.locales$
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap((locales) => {
          locales.forEach(locale => {
            let value1st = (this.localesForm.get(locale.country) as FormGroup).value.answers1st;
            if (typeof indexForDelete === "number") {
              value1st = value1st.filter((item, index) => index !== indexForDelete);
            }
            const formArray = this.createFormArrayWithMultipleControls(answers);
            (this.localesForm.get(locale.country) as FormGroup).setControl('answers1st', formArray);
            (this.localesForm.get(locale.country) as FormGroup).get('answers1st').patchValue(value1st);
          })
        })
      )
      .subscribe()
  }


  addControlsToLocaleAnswers2st(answers: StreakAnswerModel[], indexForDelete?: number) {
    this.locales$
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap((locales) => {
          locales.forEach(locale => {
            let value2st = (this.localesForm.get(locale.country) as FormGroup).value.answers2st;
            if (typeof indexForDelete === "number") {
              value2st = value2st.filter((item, index) => index !== indexForDelete);
            }
            const formArray = this.createFormArrayWithMultipleControls(answers);
            (this.localesForm.get(locale.country) as FormGroup).setControl('answers2st', formArray);
            (this.localesForm.get(locale.country) as FormGroup).get('answers2st').patchValue(value2st);
          })
        })
      )
      .subscribe()
  }



  async initData() {
    await firstValueFrom(this.answerGroupsService.getAllAnswerGroups());
    this.roundWizardAnswerService.getAnswersList().subscribe()
  }

  removeControls() {
    this.locales$
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap((locales) => {
          locales.forEach(locale => {
            (this.localesForm.get(locale.country) as FormGroup).removeControl('answers');
            (this.localesForm.get(locale.country) as FormGroup).removeControl('answers1st');
            (this.localesForm.get(locale.country) as FormGroup).removeControl('answers2st');
          })
        })
      )
      .subscribe()
  }

  processQuestionTypeChange(data: QuestionTypesEnum) {
    this.questionTypeForm = new FormGroup({});
    this.removeControls();
    switch (data) {
      case QuestionTypesEnum.SCORE_PLUS:
        this.buildFormForScorePlus();
        break;
      case QuestionTypesEnum.RANGE:
        this.buildFormForRangePlus();
        break;
      case QuestionTypesEnum.OPTIONS:
        this.buildFormForOptionsListGrid(QuestionTypesEnum.OPTIONS);
        break;
      case QuestionTypesEnum.GRID:
        this.buildFormForOptionsListGrid(QuestionTypesEnum.GRID);
        break;
      case QuestionTypesEnum.LIST:
        this.buildFormForOptionsListGrid(QuestionTypesEnum.LIST);
        break;
      case QuestionTypesEnum.LISTS:
        this.buildFormForLists();
        break;
    }

    if (this.currentQuestion
      && (data === QuestionTypesEnum.SCORE_PLUS || data === QuestionTypesEnum.SCORE || data === QuestionTypesEnum.RANGE)) {
      this.fillLocalesForm();
    }

  }

  buildFormForScorePlus() {
    this.questionTypeForm = new FormGroup({
      maxScoreValue: new FormControl('', [Validators.max(999), Validators.min(1), Validators.required])
    });

    if (this.currentQuestion) {
      const maxScoreValue = this.currentQuestion.maxScoreValue ? parseInt(this.currentQuestion.maxScoreValue.slice(0, -1)) : null;
      this.questionTypeForm.get('maxScoreValue').patchValue(maxScoreValue);
    }
  }

  getLocaleAnswers(locale: string, answerKey?: string): FormArray {
    return this.localesForm.get(locale).get(answerKey ?? 'answers') as FormArray;
  }

  isShowError(control: FormControl) {
    if (!control.touched) return false;
    return control.hasError('required');
  }

  isShowErrorLength(control: FormControl) {
    if (!control.touched) return false;
    return control.hasError('maxlength');
  }

  getGroupedItems(answers) {
    return Object.values(answers.reduce((acc, item) => {
      if (!acc[item.groupName]) {
        acc[item.groupName] = [];
      }
      acc[item.groupName].push(item);
      return acc;
    }, {}));
  }


  buildFormForLists() {
    this.answerGroupList$ = this.answerGroupsService.answerGroupList$.pipe(
      map((answerGroupList) => {
        if (answerGroupList) {
          return answerGroupList.filter(answerGroup => answerGroup.answers.length >= requiredListsAnswersLength);
        } else {
          return [];
        }
      }
      ),
      tap(data => this.answerGroupList = data)
    );

    this.questionTypeForm = new FormGroup({
      answerGroupId: new FormControl(''),
      answerGroupId2: new FormControl(''),
      additional: this.questionSectionFormService.buildAdditionnalFormByType(QuestionTypesEnum.LISTS)
    });

    this.addControlsToLocaleAnswers1st([this.mockAnswer]);
    this.addControlsToLocaleAnswers2st([this.mockAnswer]);


    this.questionTypeForm.get('answerGroupId').valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(() => this.answerGroupList$),
      tap((answers) => {
        const targetGroup = answers.find(answer => answer.id === this.questionTypeForm.get('answerGroupId').value);
        const targetAnswers = targetGroup ? targetGroup.answers : [];
        if (targetAnswers.length) {
          this.additional = new FormGroup({
            answers1st: this.questionSectionFormService.fillAnswersForLists(targetAnswers),
            answers2st: this.additional.get('answers2st')
          })
          this.addControlsToLocaleAnswers1st(targetAnswers);
        }
      }),
    ).subscribe()

    this.questionTypeForm.get('answerGroupId2').valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(() => this.answerGroupList$),
      tap((answers) => {
        const targetGroup = answers.find(answer => answer.id === this.questionTypeForm.get('answerGroupId2').value);
        const targetAnswers = targetGroup ? targetGroup.answers : [];

        if (targetAnswers.length) {
          this.additional = new FormGroup({
            answers1st: this.additional.get('answers1st'),
            answers2st: this.questionSectionFormService.fillAnswersForLists(targetAnswers)
          })
          this.addControlsToLocaleAnswers2st(targetAnswers);
        }
      }),
    ).subscribe();



    this.answerGroupList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (this.currentQuestion) {
          const groupedAnswers = this.getGroupedItems(this.currentQuestion.answers);
          const answerGroupName = groupedAnswers[0][0].groupName === 'group_0' ? null : groupedAnswers[0][0].groupName;
          const answerGroupName2 = groupedAnswers[1][0].groupName === 'group_1' ? null : groupedAnswers[1][0].groupName;

          if (answerGroupName) {
            const answerGroupId = data.find(item => item.name === answerGroupName).id;
            this.questionTypeForm.get('answerGroupId').patchValue(answerGroupId)
          } else {
            this.additional = new FormGroup({
              answers1st: this.questionSectionFormService.fillAnswersForLists(groupedAnswers[0]),
              answers2st: this.additional.get('answers2st')
            })
            if (Array.isArray(groupedAnswers[0])) {
              this.addControlsToLocaleAnswers1st(groupedAnswers[0]);
            }
          }

          if (answerGroupName2) {
            const answerGroupId2 = data.find(item => item.name === answerGroupName2).id;
            this.questionTypeForm.get('answerGroupId2').patchValue(answerGroupId2)
          } else {
            this.additional = new FormGroup({
              answers1st: this.additional.get('answers1st'),
              answers2st: this.questionSectionFormService.fillAnswersForLists(groupedAnswers[1])
            })
            if (Array.isArray(groupedAnswers[1])) {
              this.addControlsToLocaleAnswers2st(groupedAnswers[1]);
            }
          }
          this.fillLocalesForm();
        }
      })

    this.questionTypeForm.get('additional').get('answers1st').valueChanges
      .pipe(
        startWith(this.questionTypeForm.get('additional').get('answers1st').value),
        takeUntil(this.unsubscribe$),
        pairwise(),
        tap(([previousAnswers, currentAnswers]) => {
          const currentLength = currentAnswers.length;
          const previousLength = previousAnswers.length;
          if (currentLength !== previousLength && currentLength < previousLength) {
            const changedIndex = this.findMissingIndex(currentAnswers, previousAnswers);
            this.addControlsToLocaleAnswers1st(currentAnswers, changedIndex);
          } else {
            this.addControlsToLocaleAnswers1st(currentAnswers);
          }
        })
      ).subscribe();


    this.questionTypeForm.get('additional').get('answers2st').valueChanges
      .pipe(
        startWith(this.questionTypeForm.get('additional').get('answers2st').value),
        takeUntil(this.unsubscribe$),
        pairwise(),
        tap(([previousAnswers, currentAnswers]) => {
          const currentLength = currentAnswers.length;
          const previousLength = previousAnswers.length;
          if (currentLength !== previousLength && currentLength < previousLength) {
            const changedIndex = this.findMissingIndex(currentAnswers, previousAnswers);
            this.addControlsToLocaleAnswers2st(currentAnswers, changedIndex);
          } else {
            this.addControlsToLocaleAnswers2st(currentAnswers);
          }
        })
      ).subscribe();



  }

  set additional(formGroup: FormGroup) {
    this.questionTypeForm.setControl('additional', formGroup);
  }

  get additional() {
    return this.questionTypeForm.get('additional') as FormGroup;
  }

  createFormArrayWithMultipleControls(answers: StreakAnswerModel[]): FormArray {
    const formGroups = [];

    answers.forEach(answer => {
      let control = new FormControl();
      if (this.externalAnswerOptions.includes(answer.text)) {
        control = new FormControl('', [Validators.maxLength(255)]);
      } else {
        control = new FormControl('', [Validators.required, Validators.maxLength(255)]);
      }
      const formGroup = new FormGroup({
        text: control
      });

      formGroups.push(formGroup);
    })

    return new FormArray(formGroups);
  }

  buildFormForOptionsListGrid(type: QuestionTypesEnum) {
    this.answerGroupList$ = this.answerGroupsService.answerGroupList$.pipe(
      map((answerGroupList) => {
        if (answerGroupList) {
          switch(type) {
            case QuestionTypesEnum.GRID:
            case QuestionTypesEnum.LIST:
              return answerGroupList.filter(answerGroup => answerGroup.answers.length >= requiredListGripAnswersLength);
            case QuestionTypesEnum.OPTIONS:
              return answerGroupList.filter(answerGroup => answerGroup.answers.length === requiredOptionAnswersLength);
            default:
              return answerGroupList;
          }
        } else {
          return [];
        }
      }),
      tap(data => {
        this.answerGroupList = data;
      })
    );
    this.questionTypeForm = new FormGroup({
      groupId: new FormControl(''),
      additional: this.questionSectionFormService.buildAdditionnalFormByType(type)
    });


    this.addControlToLocaleAnswers([this.mockAnswer]);

    this.questionTypeForm.get('groupId').valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(() => this.answerGroupList$),
      tap((answers) => {
        const targetGroup = answers.find(answer => answer.id === this.questionTypeForm.get('groupId').value);
        const targetAnswers = targetGroup ? targetGroup.answers : [];

        if (targetAnswers.length) {
          this.questionTypeForm.removeControl('additional');
          this.questionTypeForm.addControl('additional', this.questionSectionFormService.fillAnswers(type, targetAnswers));
          this.addControlToLocaleAnswers(targetAnswers);
        }
      }),
      switchMap(() => this.questionTypeForm.get('additional').get('answers').valueChanges),
      tap((answers) => {
        this.addControlToLocaleAnswers(answers);
      })
    ).subscribe();



    this.answerGroupList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (this.currentQuestion) {
          if (this.currentQuestion.answers[0].groupName && data.length) {
            const group = data.find(item => item.name === this.currentQuestion.answers[0].groupName);
            const groupId = group ? group.id : null;
            this.questionTypeForm.get('groupId').patchValue(groupId);
          } else {
            const targetAnswers = this.currentQuestion.answers.map(answer =>({id: answer.id, text: answer.text, imageUrl: answer.imageUrl}))
            this.questionTypeForm.removeControl('additional');
            this.questionTypeForm.addControl('additional', this.questionSectionFormService.fillAnswers(type, targetAnswers));
            this.addControlToLocaleAnswers(targetAnswers as StreakAnswerModel[]);
          }
          this.fillLocalesForm();
        }
    })

    this.questionTypeForm.get('additional').get('answers').valueChanges
      .pipe(
        startWith(this.questionTypeForm.get('additional').get('answers').value),
        takeUntil(this.unsubscribe$),
        pairwise(),
        tap(([previousAnswers, currentAnswers]) => {
          const currentLength = currentAnswers.length;
          const previousLength = previousAnswers.length;
          if (currentLength !== previousLength && currentLength < previousLength) {
            const changedIndex = this.findMissingIndex(currentAnswers, previousAnswers);
            this.addControlToLocaleAnswers(currentAnswers, changedIndex);
          } else {
            this.addControlToLocaleAnswers(currentAnswers);
          }
        })
      ).subscribe();



  }

  findMissingIndex(arr1, arr2) {
    const idsInArr = new Set(arr1.map(item => item.id));
    return arr2.findIndex(item => !idsInArr.has(item.id));
  }


  async createNewFromAnswers(groupKey: string, answersKey: string) {
    const answers = this.questionTypeForm?.get('additional').get(answersKey ?? 'answers').value || [];
    const data = await this.createAnswerGroupModalService.open({
      questionType: this.questionForm.get('type').value,
      answers,
    });
    if (data?.groupId) {
      this.questionTypeForm.get(groupKey ?? 'groupId').patchValue(data.groupId);
      this.questionTypeForm.markAsDirty();
    }
  }

  async editSelectedFromAnswers(groupKey: string, answersKey: string) {
    const answers = this.questionTypeForm?.get('additional')?.get(answersKey ?? 'answers')?.value || [];
    const groupId = this.questionTypeForm.get(groupKey ?? 'groupId').value;
    const data = await this.createAnswerGroupModalService.open({
      questionType: this.questionForm.get('type').value,
      answers,
      group: this.answerGroupService.answerGroupList.find(group => group.id === groupId),
    });
    if (data?.groupId) {
      this.questionTypeForm.get(groupKey ?? 'groupId').patchValue(data.groupId);
    }
  }



  isAnswersDirtyEvent(value: boolean) {
    this.isAnswersDirty$.next(value);
  }

  isAnswersDirty2stEvent(value: boolean) {
    this.isAnswersDirty2st$.next(value);
  }

  buildFormForRangePlus() {
    this.questionTypeForm = new FormGroup({
      minValue: new FormControl(null, [Validators.max(9999), Validators.min(0), Validators.required]),
      maxValue: new FormControl(null, [Validators.max(9999), Validators.min(0), Validators.required]),
      displayValue: new FormControl(null, [Validators.maxLength(20),Validators.required]),
      incrementValue: new FormControl(null, [Validators.max(9999), Validators.min(0), Validators.required]),
    }, getValidateRangeValue);

    if (this.currentQuestion) {
      this.questionTypeForm.get('minValue').patchValue(this.currentQuestion.attributes.range.minValue);
      this.questionTypeForm.get('maxValue').patchValue(this.currentQuestion.attributes.range.maxValue);
      this.questionTypeForm.get('displayValue').patchValue(this.currentQuestion.attributes.range.displayValue);
      this.questionTypeForm.get('incrementValue').patchValue(this.currentQuestion.attributes.range.incrementValue);
    }
  }

  async editGroup(group: any) {
    await this.createAnswerGroupModalService.open({
      questionType: this.questionForm.get('type').value,
      group,
      //TODO remove 'disableExternalEvent" when the external events functionality will be added
      disableExternalEvent: true
    });
  }

  async deleteGroup(group: any) {
    await lastValueFrom(
      this.dialogService.openDeleteConfirmationPopup(`${group.name} answers group`)
        .pipe(
          switchMap((response) => {
            if (response) {
              return this.answerGroupsService.deleteAnswerGroup(group.id)
                .pipe(
                  switchMap(() => this.answerGroupsService.getAllAnswerGroups()),
                  tap(() => {
                    if (this.questionTypeForm.get('groupId').value == group.id) {
                      this.questionTypeForm.get('groupId').patchValue(null, {onlySelf: true});
                      this.questionTypeForm.markAsDirty();
                    }
                  })
                )
            } else {
              return of(null);
            }
          }),
          catchError((error) => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          })
        )
    );
  }

  async createGroup() {
    const data = await this.createAnswerGroupModalService.open({
      questionType: this.questionForm.get('type').value,
      //TODO remove 'disableExternalEvent" when the external events functionality will be added
      disableExternalEvent: true
    });
    if (data?.groupId) {
      this.questionTypeForm.get('groupId').patchValue(data.groupId, {onlySelf: true});
      this.questionForm.markAsDirty();
    }
  }


  buildForm() {
    this.questionForm =  new FormGroup({
      difficultyLevel: new FormControl('', [Validators.required]),
      imageUrl: new FormControl(''),
      text: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      type: new FormControl(QuestionTypesEnum.SCORE, [Validators.required]),
    })
  }

  fillQuestionFormWithData() {
    this.questionForm.get('difficultyLevel').patchValue(this.currentQuestion.difficultyLevel);
    this.questionForm.get('imageUrl').patchValue(this.currentQuestion.imageUrl);
    this.questionForm.get('text').patchValue(this.currentQuestion.text);
    this.questionForm.get('type').patchValue(this.currentQuestion.type);
  }

  closeDialog() {
    if (!this.questionForm.dirty && !this.questionTypeForm.dirty && !this.localesForm.dirty) {
      this.dialogRef.close();
    } else {
      this.dialogService.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter(response => !!response),
        tap((response) => {
          if (response) {
            this.dialogRef.close();
          }
        })
      ).subscribe();
    }
  }

  prepareLocaleList(vendorLocales) {
    const localeArray =  vendorLocales.map(locale => {
      const {i18n, id} = locale;
      return {
        country: this.fullLocaleList.find(savedLocale => savedLocale.localeCode === i18n).country,
        i18n,
        id
      }
    });
    return localeArray;
  }

  getDifficultyLevelList () {
    return Object.keys(StreakQuestionDifficultyEnum);
  }

  protected readonly TooltipPositionEnum = TooltipPositionEnum;
}
