import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { LocaleModel } from "../../../models/LocaleModel";
import { catchError, combineLatest, lastValueFrom, map, Observable, startWith, tap, throwError } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { LocalizationService } from "../../../../core/services/localization.service";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { APP_DATA } from "../../../../general.app.config";

@Component({
  selector: 'add-new-locale',
  templateUrl: './add-new-locale.component.html',
  styleUrls: ['./add-new-locale.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewLocaleComponent implements OnInit {

  @ViewChild('localeInput') localeInput: ElementRef | undefined;

  localeFormGroup: FormGroup;

  locales: LocaleModel[];

  filteredLocales: Observable<LocaleModel[]>;

  lastFilter = '';

  APP_DATA = APP_DATA;

  constructor(
    public dialogRef: MatDialogRef<AddNewLocaleComponent>,
    private localizationService: LocalizationService,
    private snackBarService: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.getLocaledata();
  }

  async getLocaledata() {
    await lastValueFrom(
      combineLatest([
        this.localizationService.fullLocaleData$,
        this.localizationService.vendorLocales$
      ]).pipe(
        tap(([fullLocaleData,vendorLocales]) => {
          this.locales = fullLocaleData
          .filter(localeItem => {
            return !vendorLocales.find(vendorLocale => vendorLocale.i18n === localeItem.localeName);
          })
        }),
        tap(() => {
          this.filteredLocales = this.localeFormGroup.get('locales').valueChanges.pipe(
            startWith<string>(''),
            map(value =>  value ),
            map(filter =>  this.filter(filter))
          );
        })
      ))
  }

  displayFn(option) {
    return option?.country;
  }

  filter(filter: string): LocaleModel[] {
    this.lastFilter = filter;
    if (filter && typeof filter === 'string') {
      return this.locales.filter(option => {
        return option.country.toLowerCase().indexOf(filter?.toLowerCase()) >= 0;
      })
    } else {
      return this.locales.slice();
    }
  }

  buildForm() {
    this.localeFormGroup = new FormGroup({
      locales: new FormControl(null),
    });
  }

 async addLocale() {
    const i18n = this.localeFormGroup.get('locales').value.localeName;
    const body = {i18n, automaticTranslate: false}
    await lastValueFrom(
      this.localizationService.createVendorLocale(body)
        .pipe(
          tap(() => {
            this.dialogRef.close();
            this.localizationService.needUpdateLocaleList$.next(true)
          }),
          catchError((error) => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          })
        )
    )
  }

  isStringValue(value) {
    if (!value) return true;
    return typeof value === "string";
  }

  isDisableAddButton() {
    const formValue = this.localeFormGroup.get('locales').value;
    return !formValue || (formValue && typeof formValue === 'string');
  }

  isShowError() {
    if (this.localeInput?.nativeElement === document.activeElement) return false;
    return typeof this.localeFormGroup.get('locales').value === 'string';
  }

}
