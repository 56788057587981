import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { UriConfig } from "../../app.config";
import { SlackSettingsModel } from "../../common/models/SlackSettingsModel";

@Injectable({
  providedIn: 'root'
})
export class SlackUpdatesService {

  slackSettings$ = new BehaviorSubject<SlackSettingsModel>({
    isSlackEnabled: false,
    isSlackIncidentsEnabled: false,
    slackIncidentsChannelId: ''
  });

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {}

  getSlackSettings(): Observable<SlackSettingsModel> {
    return this.http.get<SlackSettingsModel>(this.uriConfig.slackSettings).pipe(
      tap((data) => this.slackSettings$.next(data))
    );
  }

  updateSlackSettings(settings: SlackSettingsModel): Observable<SlackSettingsModel> {
    return this.http.patch<SlackSettingsModel>(this.uriConfig.slackSettings, settings).pipe(
      tap((data) => this.slackSettings$.next(data))
    );
  }

}
