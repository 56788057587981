import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UriConfig } from "@app/app.config";
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, tap } from "rxjs";
import {
  InfoPopupBreakdownData,
  InfoPopupTabsCreateModel,
  InfoPopupTabsEditResponseModel,
  InfoPopupTabsModel,
  InfoTabLocalized
} from "@models/InfoPopupTabsModel";
import { ProfileService } from "@services/profile.service";
import { CmsService } from "@services/cms.service";
import { SaasSettingsService } from "@services/saas-settings.service";
import { RoundPrizeTypeEnum } from "@enums/RoundPrizeTypeEnum";
import { RoundPrizeAssigmentEnum } from "@enums/RoundPrizeAssigmentEnum";
import { RoundDetailsModel } from "@models/RoundDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class InfoPopupCmsService {
  needUpdateContent$ = new BehaviorSubject(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private  profileService: ProfileService,
    private cmsService: CmsService,
    private sassSettings: SaasSettingsService,
  ) {}

  getInfoPopupTabs(): Observable<InfoPopupTabsModel[]> {
    return this.http.get<InfoPopupTabsModel[]>(this.uriConfig.infoPopupTabs);
  }

  addInfoPopupTab(tab: InfoPopupTabsCreateModel): Observable<any> {
    return this.http.post<InfoPopupTabsCreateModel>(this.uriConfig.infoPopupTabs, tab);
  }

  localizeTabs(tabs: InfoTabLocalized[]): Observable<any> {
    const body = {
      items: [...tabs]
    }
    return this.http.patch<InfoPopupTabsCreateModel>(this.uriConfig.localizations, body);
  }


  updateInfoPopupTab(tab: InfoPopupTabsCreateModel, id: number): Observable<InfoPopupTabsEditResponseModel[]> {
    return this.http.patch<InfoPopupTabsEditResponseModel[]>(this.uriConfig.infoPopupTabs + '/' + id, tab);
  }

  deleteInfoPopupTab(id: number): Observable<InfoPopupTabsEditResponseModel[]> {
    return this.http.delete<InfoPopupTabsEditResponseModel[]>(this.uriConfig.infoPopupTabs + '/' + id);
  }

  displayPrizeModalOnLanding(id: number): Observable<any> {
    return  this.http.patch(this.uriConfig.rounds + `/${id}/winnings-displaying`, {})
  }

  getPrizeModalB2CData(): Observable<InfoPopupBreakdownData> {
    const {domain} = this.profileService.currentUser$.value;
    let query = 3;
    if(domain.includes('napoleon')) {
      query = 2
    } else if(domain.includes('romania')) {
      query = 4
    }
    return this.http.get<InfoPopupBreakdownData>(this.uriConfig.infoPopupTabs + `/b2c/breakdown-winnings?minValue=${query}`)
  }

  getRoundsBreakdownList(): Observable<InfoPopupBreakdownData[]> {
    const {domain} = this.profileService.currentUser$.value;
    let query =  3;
    if(domain.includes('napoleon')) {
      query = 2
    } else if(domain.includes('romania')) {
      query = 4
    }
    return this.http.get<InfoPopupBreakdownData[]>(this.uriConfig.infoPopupTabs + `/breakdown-winnings?minValue=${query}`)
  }

  showOnLandingFeatureInfoModal() {
    return this.http.patch<InfoPopupBreakdownData[]>(this.uriConfig.infoPopupTabs + `/mark-unseen`, {})
  }

  getRoundPrizePopup(id: number): Observable<any> {
    return this.http.get<InfoPopupTabsModel>(this.uriConfig.infoPopupTabs + `/prize-popups/${id.toString()}`)
  }

  getDefaultRoundModal(locales: { country: string, i18n: string }[], round: RoundDetailsModel, prizes: any): Observable<any> {
    const currencyAfterPrice = this.sassSettings.getCurrencyAfterPriceSettings()?.value;
    let isCashPoints = true;
    if(prizes) {
       isCashPoints = Object.values(prizes).every((res: { type: string }) => res.type === RoundPrizeTypeEnum.CASH_POINTS);
    }

    return combineLatest([
      this.cmsService.getTextLabels({ name: 'iframe-v3-prize-modal-header' }).pipe(map(res => res.records[0])),
      this.cmsService.getTextLabels({ name: 'iframe-v3-prize-modal-description' }).pipe(map(res => res.records[0])),
      this.cmsService.getTextLabels({ name: 'iframe-v3-amount-landing' }).pipe(map(res => res.records[0])),
      this.cmsService.getTextLabels({ name: 'iframe-v3-prize-modal-amount-description' }).pipe(map(res => res.records[0])),
      this.getPrizeDetails(prizes, locales, currencyAfterPrice),
    ]).pipe(
      map(([title, content, amountValue, amountDescription, prizeDetails]) => {
        return locales.reduce((acc, locale) => {
          let finalContent = '';
          const localePrizes: any = prizeDetails?.find(p => p.locale === locale.country);

          if (isCashPoints) {
            const prizeAmount = round.prizeTotal || (!locale.i18n ? amountValue['Text value'] : this.cmsService.getLocalizedLable(amountValue, locale));
            let amount = ''
            if(localePrizes){
            amount = currencyAfterPrice
              ? `${prizeAmount}${localePrizes.localizedCurrency}`
              : `${localePrizes.localizedCurrency}${prizeAmount}`;
          }

            finalContent += `<p>${!locale.i18n
              ? content['Text value'].replace('{maxPrize}', `<strong>${amount}</strong>`)
              : this.cmsService.getLocalizedLable(content, locale)?.replace('{maxPrize}', `<strong>${amount}</strong>`)}</p>`;
          } else {
            finalContent += `<p>${localePrizes.description}</p>`;
          }

          finalContent += `<p>${!locale.i18n
            ? amountDescription['Text value']
            : this.cmsService.getLocalizedLable(amountDescription, locale)}</p>`;

          if(localePrizes) {
            finalContent += `<li>${localePrizes[locale.country].text}</li>`;
          }

          acc[locale.country] = {
            content: finalContent,
            title: !locale.i18n ? title['Text value'] : this.cmsService.getLocalizedLable(title, locale),
          };
          return acc;
        }, {});
      })
    );
  }


  getPrizeDetails(prizes: any, locales: any[], currencyAfterPrice: boolean): Observable<any> {
    return combineLatest([
      this.cmsService.getTextLabels({ name: 'iframe-v3-prize-modal-prize-description' }).pipe(map(res => res.records[0])),
      this.cmsService.getTextLabels({ name: 'iframe-v3-prize-modal-shared-prize-description' }).pipe(map(res => res.records[0])),
      this.cmsService.getTextLabels({ name: 'iframe-v3-currency' }).pipe(map(res => res.records[0])),
    ]).pipe(
      map(([descriptionLabel, splitPrizeText, currency]) => {
        if(prizes) {
          const prizeList = Object.keys(prizes).map(correctCount => {
          const currentPrize = prizes[correctCount];
          const assignment = currentPrize.assignment;
          const prizeAmount =
            typeof currentPrize === 'string'
              ? currentPrize
              : this.formatThousands(currentPrize.reward, '.').toString();
          return { correctCount, assignment, prize: prizeAmount };
        });

        return locales.reduce((acc, locale) => {
          const localizedCurrency = !locale.i18n
            ? currency['Text value']
            : this.cmsService.getLocalizedLable(currency, locale);

          const prizeDetails = prizeList.map(prize => {
            let prizeText = `<strong>${currencyAfterPrice ? `${prize.prize}${localizedCurrency}` : `${localizedCurrency}${prize.prize}`}</strong>`;
            if (prize.assignment === RoundPrizeAssigmentEnum.SPLIT && splitPrizeText) {
              prizeText = `<li style="list-style: none">${splitPrizeText}</li>`;
            }
            return {
              correctCount: prize.correctCount,
              text: `${prizeText} – ${prize.correctCount}/${Object.keys(prizes).length}`,
            };
          });

          const prizeTemplate = prizeDetails
            .map(prize => `<li>${prize.text}</li>`)
            .join('');

          acc[locale.country]['content'] = `<ul>${prizeTemplate}</ul>`;
          acc[locale.country]['description'] = !locale.i18n ? descriptionLabel['Text value'] : this.cmsService.getLocalizedLable(descriptionLabel, locale)
          acc[locale.country]['localizedCurrency'] = !locale.i18n ? localizedCurrency['Text value'] : this.cmsService.getLocalizedLable(localizedCurrency, locale)
          return acc;
        }, {});
        }
      })
    );
  }

  formatThousands(number, amountSeparator) {
    if (!number) return null;

    const numStr = number.toString();
    if (numStr.length <= 3) {
      return numStr;
    } else {
      const formattedSubstr = this.formatThousands(numStr.slice(0, -3), amountSeparator);
      return formattedSubstr + amountSeparator + numStr.slice(-3);
    }
  }
}

