import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RoundService } from 'src/app/core/services/round.service';
import { LeaderboardTypeEnum } from "@enums/LeaderboardTypeEnum";
import { SelectOption } from "../../../_base-component/select/select.component";
import { CreateLeaderboardComponent } from "../create-leaderboard.component";
import { CustomLeaderboardService } from "@services/custom-leaderboard.service";
import { CreateSpecialLeaderboardComponent } from "../create-special-leaderboard/create-special-leaderboard.component";

@Component({
  selector: 'select-leaderboard-type-modal',
  templateUrl: './select-leaderboard-type-modal.component.html',
  styleUrls: ['./select-leaderboard-type-modal.component.scss'],
})
export class SelectLeaderboardTypeModalComponent {

  leaderboardTypeForm: FormGroup;

  leaderboardTypes: SelectOption[] = [
    {
      label: 'Regular',
      value: LeaderboardTypeEnum.REGULAR
    },
    {
      label: 'Special',
      value: LeaderboardTypeEnum.SPECIAL
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<SelectLeaderboardTypeModalComponent>,
    private dialog: MatDialog,
    public roundService: RoundService,
    @Inject(MAT_DIALOG_DATA) public data: { isNew: boolean },
    private customLeaderboardService: CustomLeaderboardService
  ) {
    this.buildForm();
  }

  buildForm() {
    this.leaderboardTypeForm = new FormGroup({
      type: new FormControl(LeaderboardTypeEnum.REGULAR, [Validators.required]),
    });
  }
  onClosePrizeWizard() {
    this.dialogRef.close();
  }

  selectLeaderboardType() {
    this.customLeaderboardService.customLeaderboardType$.next(this.leaderboardTypeForm.value.type);
    this.dialogRef.close();
    this.addLeaderboard();
  }

  addLeaderboard() {
    const isRegular = this.customLeaderboardService.customLeaderboardType$.value === LeaderboardTypeEnum.REGULAR;
    if (isRegular) {
      this.dialog.open(CreateLeaderboardComponent,
        {
          disableClose: true,
          autoFocus: false,
          width: '660px',
          panelClass:['prize-builder'],
        })
    } else {
      this.dialog.open(CreateSpecialLeaderboardComponent,
        {
          disableClose: true,
          autoFocus: false,
          width: '660px',
          height: '700px',
          panelClass:['prize-builder'],
        })
    }
  }
}
