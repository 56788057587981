/* eslint @typescript-eslint/no-var-requires: "off" */
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidators } from 'ng2-validation';
import { parsePhoneNumber } from 'awesome-phonenumber'
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, startWith, Subject, takeUntil, tap } from 'rxjs';
import { APP_DATA } from "@app/general.app.config";
import { SnackBarService } from "@services/snack-bar.service";
import { AdminsService } from "@services/admins.service";
import { VendorSettingDetailsModel } from "@models/VendorSettingDetailsModel";
import { TextService } from "@services/text.service";
import { B2CAvatar } from "@models/ExpandedB2CUserItemDetailsModel";
import { FormService } from "@services/form.service";
import { LocaleModel } from "@models/LocaleModel";
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { languageCodesArray } from './locale-list';
import { SlackUpdatesService } from "@services/slack-updates.service";
import { LabelChipTypeEnum } from "@enums/LabelChipTypeEnum";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";


@Component({
  selector: 'create-vendor-modal',
  templateUrl: './create-vendor-modal.component.html',
  styleUrls: ['./create-vendor-modal.component.scss']
})
export class CreateVendorModalComponent implements OnInit, OnDestroy {

  testSuffix = '-test';

  @ViewChild(MatAutocompleteTrigger)
  private autocomplete: MatAutocompleteTrigger;

  isLoaded = true;

  title = 'New Vendor';

  vendorDataFormGroup: FormGroup;

  appData = APP_DATA;

  image: B2CAvatar;

  isButtonWasClicked = false;

  serverErrorMassage: string;

  formErrors$ = new BehaviorSubject<{ massage, field }>(null);

  isServerError = false;

  fileImages: {
    brandImage?: any
  }

  locales: LocaleModel[];

  selectedLocales: LocaleModel[] = new Array<LocaleModel>();

  filteredLocales: Observable<LocaleModel[]>;

  lastFilter = '';

  reportReceiversEmail: string[] = [];

  isTestTenant$

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private unsubscribe$: Subject<void> = new Subject();

  protected readonly LabelChipTypeEnum = LabelChipTypeEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateVendorModalComponent>,
    public textService: TextService,
    private snackBarService: SnackBarService,
    private adminsService: AdminsService,
    private formsService: FormService,
    private slackUpdatesService: SlackUpdatesService,
  ) {
  }

  ngOnInit(): void {
    this.locales = this.createLocaleObjectData(languageCodesArray);
    this.isLoaded = false;
    this.buildForm();
    this.filterLocales();
    this.processServerError();
    this.processWebsitePrefixAutofill();
    this.processSlackUpdate();
  }

  filterLocales() {
    this.filteredLocales = this.vendorDataFormGroup.get('locales').valueChanges.pipe(
      tap(val =>
        !val?.length
        && this.vendorDataFormGroup.get('locales').patchValue(null, {eventEmit: false})),
      startWith<string | LocaleModel[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filter(filter))
    );
  }

  processServerError() {
    this.formErrors$
      .subscribe((errorObject) => {
        if (!errorObject) return;

        if (errorObject.massage) {
          const {massage, field} = errorObject;
          this.isServerError = true;
          this.formsService.bindErrors(massage, this.vendorDataFormGroup, field);
          this.serverErrorMassage = massage;
        } else {
          const {field} = errorObject;
          this.isServerError = false;
          this.formsService.bindErrors(null, this.vendorDataFormGroup, field);
          this.serverErrorMassage = null;
        }
      });
    this.vendorDataFormGroup.get('email').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        if (this.isServerError) {
          this.formErrors$.next(null);
        }
      });
    this.vendorDataFormGroup.get('isStreakVendor').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        if (this.isServerError) {
          this.formErrors$.next(null);
        }
      });
  }

  processSlackUpdate() {
    this.vendorDataFormGroup.get('slackUpdatesEnabled').valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      tap((value) => {
        if (value) {
          this.vendorDataFormGroup.get('slackChannelId').addValidators(Validators.required);
        } else {
          this.vendorDataFormGroup.get('slackChannelId').removeValidators(Validators.required);
        }
        this.vendorDataFormGroup.get('slackChannelId').updateValueAndValidity();
      })
    ).subscribe();

    if (this.slackUpdatesService.slackSettings$.value.isSlackEnabled) {
      this.handleSlackUpdatesEnabledChange();
    }
  }

  processWebsitePrefixAutofill() {
    const isTestTenant$ = this.vendorDataFormGroup.get('isTestTenant').valueChanges.pipe(
      startWith(this.vendorDataFormGroup.get('isTestTenant').value),
      distinctUntilChanged()
    );

    const name$ = this.vendorDataFormGroup.get('name').valueChanges.pipe(
      startWith(this.vendorDataFormGroup.get('name').value),
      distinctUntilChanged()
    );

    combineLatest([isTestTenant$, name$])
      .subscribe(([isTestTenantValue, nameValue]) => {
        const brandName = nameValue?.toLowerCase().replace(' ', '-');
        const websitePrefix = this.vendorDataFormGroup.get('websitePrefix').value;
        if (!brandName && !websitePrefix) return;

        const isTestInclude = websitePrefix?.includes(this.testSuffix);
        if (!isTestInclude && isTestTenantValue) {
          const newWebsitePrefix = brandName + this.testSuffix;
          this.vendorDataFormGroup.get('websitePrefix').patchValue(newWebsitePrefix);
        }
        if (isTestInclude && !isTestTenantValue) {
          const newWebsitePrefix = brandName.replace(this.testSuffix, '');
          this.vendorDataFormGroup.get('websitePrefix').patchValue(newWebsitePrefix);
        }
        if (!isTestInclude && !isTestTenantValue) {
          this.vendorDataFormGroup.get('websitePrefix').patchValue(brandName);
        }
      });
  }

  handleSlackUpdatesEnabledChange() {
    this.vendorDataFormGroup.get('slackUpdatesEnabled').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((slackUpdatesEnabled) => {
        if (!slackUpdatesEnabled) {
          this.vendorDataFormGroup.get('slackChannelId').disable();
          this.vendorDataFormGroup.get('slackChannelId').patchValue('');
        } else {
          this.vendorDataFormGroup.get('slackChannelId').enable();
        }
      });
  }

  filter(filter: string): LocaleModel[] {
    this.lastFilter = filter;
    if (filter) {
      return this.locales.filter(option => {
        return option.country.toLowerCase().indexOf(filter.toLowerCase()) >= 0
          || option.country.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      })
    } else {
      return this.locales.slice();
    }
  }

  displayFn(value: LocaleModel[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((locale, index) => {
        if (index === 0) {
          displayValue = locale.country;
        } else {
          displayValue += ', ' + locale.country;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, locale: LocaleModel) {
    event.stopImmediatePropagation()
    this.toggleSelection(locale);
  }

  toggleSelection(locale: LocaleModel) {
    locale.selected = !locale.selected;
    if (locale.selected) {
      this.selectedLocales.push(locale);
    } else {
      const i = this.selectedLocales.findIndex(value => value.country === locale.country);
      this.selectedLocales.splice(i, 1);
    }

    this.vendorDataFormGroup.get('locales').setValue(this.selectedLocales);
  }

  buildForm() {
    this.vendorDataFormGroup = new FormGroup({
      email: new FormControl({value: null, disabled: !!this.data}, [Validators.required, Validators.email, Validators.maxLength(70)]),
      phone: new FormControl(null, [
        (control) => {
          if (!control.value) return ;
          const valueE164 = "+" + control.value?.replace(/\D/g, "");

          const phoneNumber = parsePhoneNumber(valueE164);
          if (phoneNumber.valid) {
            return null;
          } else {
            return { invalidPhone: { value: control.value } };
          }
        },
        Validators.maxLength(32)]),
      firstName: new FormControl(null, [Validators.required, Validators.maxLength(15), Validators.minLength(3)]),
      lastName: new FormControl(null, [Validators.required, Validators.maxLength(15), Validators.minLength(3)]),
      locales: new FormControl({value: null, disabled: !!this.data}),
      allowUnauthorizedUsers: new FormControl(false),
      slackUpdatesEnabled: new FormControl({value: false, disabled: !this.slackUpdatesService.slackSettings$.value.isSlackEnabled}),
      slackChannelId: new FormControl({value: '', disabled: !this.slackUpdatesService.slackSettings$.value.isSlackEnabled || !this.data}),
      upsellSourceUrl: new FormControl('', [CustomValidators.url]),
      name: new FormControl('', [Validators.maxLength(20), Validators.minLength(2)]),
      imageUrl: new FormControl('',),
      submissionReportReceivers: new FormControl(null, [Validators.pattern(this.appData.EMAIL_REGEX), this.duplicateValidator.bind(this)]),
      //eslint-disable-next-line
      websitePrefix: new FormControl({value: null, disabled: !!this.data}, [Validators.pattern(/^[0-9a-z\-]+$/), Validators.minLength(2), Validators.maxLength(30), Validators.required]),
      isStreakVendor: new FormControl({value: null, disabled: !!this.data}),
      isTestTenant: new FormControl({value: null, disabled: !!this.data}),
    });

    if (this.data) {
      this.title = 'Edit Vendor';
      this.adminsService.getVendorDetailsById(this.data.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((userData: VendorSettingDetailsModel) => {
            this.fillForm(userData);
            this.isLoaded = true;
          },
          error => {
            this.snackBarService.showSnackBar(error.error.message, true);
            this.isLoaded = true;

          })
    } else {
      this.isLoaded = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClosePrizeWizard() {
    this.dialogRef.close();
  }

  fillForm(userData) {
    const brand = userData.brand;
    this.vendorDataFormGroup.get('email').patchValue(userData.email);
    this.vendorDataFormGroup.get('phone').patchValue(userData.phone);
    this.vendorDataFormGroup.get('firstName').patchValue(userData.firstName);
    this.vendorDataFormGroup.get('lastName').patchValue(userData.lastName);
    this.vendorDataFormGroup.get('locales').patchValue(this.getLocalesValue(userData.locales));
    this.vendorDataFormGroup.get('allowUnauthorizedUsers').patchValue(userData.allowUnauthorizedUsers);
    this.vendorDataFormGroup.get('slackUpdatesEnabled').patchValue(userData.slackUpdatesEnabled);
    this.vendorDataFormGroup.get('slackChannelId').patchValue(userData.slackChannelId);
    this.vendorDataFormGroup.get('isStreakVendor').patchValue(userData.isStreakVendor);
    this.vendorDataFormGroup.get('isTestTenant').patchValue(userData.isTestTenant);
    //eslint-disable-next-line
    const prefix = userData.domain.match(/^([^\.]+)/);
    this.vendorDataFormGroup.get('websitePrefix').patchValue(prefix[1]);
    const submissionReportReceivers = userData.submissionReportReceivers;
    if (submissionReportReceivers && submissionReportReceivers.length) {
      this.reportReceiversEmail = userData.submissionReportReceivers;
    }

    if (!brand) return;
    this.vendorDataFormGroup.get('upsellSourceUrl').patchValue(brand.upsellSourceUrl);
    this.vendorDataFormGroup.get('name').patchValue(brand.name);
    this.vendorDataFormGroup.get('imageUrl').patchValue(brand.imageUrl);
    this.image = {url: brand.imageUrl};
  }

  createLocaleObjectData(localesList) {
    return localesList.map(({localeCode, country}) => new LocaleModel(localeCode, country));
  }

  getLocalesValue(localesList) {
    return localesList.map((localeCode) => {
      const country = this.locales.find(localeSet => localeSet.localeName === localeCode).country;
      return new LocaleModel(localeCode, country);
    });
  }

  saveVendor() {
    this.isButtonWasClicked = true;
    if (!this.vendorDataFormGroup.valid) {
      this.vendorDataFormGroup.markAllAsTouched();
      return;
    }
    this.isLoaded = false;
    let request$;
    const {
      email,
      phone,
      firstName,
      lastName,
      name,
      imageUrl,
      locales,
      allowUnauthorizedUsers,
      slackUpdatesEnabled,
      isStreakVendor,
      isTestTenant
    } = this.vendorDataFormGroup.value;

    const upsellSourceUrl = this.vendorDataFormGroup.value?.upsellSourceUrl || null;

    const slackChannelId = slackUpdatesEnabled ? this.vendorDataFormGroup.get('slackChannelId').value : null;

    if (this.data) { // Edit
      const brand = {
        name: name || null,
        upsellSourceUrl: upsellSourceUrl || null,
        imageUrl,
      };
      const profile = {email, phone, firstName, lastName, allowUnauthorizedUsers, brand, submissionReportReceivers: this.reportReceiversEmail, slackUpdatesEnabled, slackChannelId, isStreakVendor};
      request$ = this.adminsService.updateVendorProfile(profile, this.data.id)
    } else { // Add
      const createVendorBody = {
        brandName: name || null,
        ...this.vendorDataFormGroup.value,
        ...this.fileImages,
        upsellSourceUrl: this.vendorDataFormGroup.value?.upsellSourceUrl || null,
        slackUpdatesEnabled,
        slackChannelId,
        isStreakVendor,
        isTestTenant
      }

      if (this.reportReceiversEmail && this.reportReceiversEmail.length) {
        createVendorBody.submissionReportReceivers = this.reportReceiversEmail.length;
      } else {
        delete createVendorBody.submissionReportReceivers
      }

      if (locales && locales.length) {
        const localeList = locales.map(localeItem => localeItem.localeName);
        createVendorBody.locales = localeList;
      }
      delete createVendorBody.name;
      request$ = this.adminsService.createVendor(createVendorBody);
    }

    request$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.isLoaded = true;
          this.adminsService.needUpdateVendorsTable();
          const massage = this.data ? 'Vendor profile updated successfully' : 'Vendor profile created successfully';
          this.snackBarService.showSnackBar(massage);
          this.dialogRef.close();
      },
        error => {
          this.isLoaded = true;

          if (error.status === 409) {
            const massage = error.error.message;
            this.formErrors$.next({massage, field: 'email'});
          } else {
            this.snackBarService.showSnackBar(error.error.message, true);
          }
        })
  }

  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.vendorDataFormGroup.get(fieldName).touched) return false;
    return this.vendorDataFormGroup.get(fieldName).hasError(errorName);
  }

  uploadFile(event, name) {
    if (event) {
      const {url} = event;
      this[name] = {url}
    } else {
      this[name] = null;
    }
    this.vendorDataFormGroup.get(name + 'Url').patchValue(event ? event.url : null);
  }

  /**
   * As this popup using for creating vendor, image must be send as file, save this file
   * @param file - uploaded file
   * @param name - form field name
   */
  onCreateFile(file, name) {
    if (!this.fileImages) {
      this.fileImages = {};
    }
    this.fileImages[name] = file;
  }

  /**
   * Call on enter or on coma press and add email to the emails list
   * If control has error dont do enuthing
   * @param event - input event
   */
  addReceiverEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!(value || '').trim()) return;

    if (event.value.includes(' ')) {
      const emails = event.value.split(/\s+/).filter(value => value);
      const isAnyEmailInvalid = emails.some(email => !this.appData.EMAIL_REGEX.test(email));
      const isAnyDuplicatedNewEmail = this.findDuplicatesInArray(emails).length;
      const isAnyDuplacatedAddedEmail = emails.some(email => this.reportReceiversEmail.includes(email))
      if (isAnyEmailInvalid) {
        return;
      }
      if (isAnyDuplicatedNewEmail || isAnyDuplacatedAddedEmail) {
        this.vendorDataFormGroup.get('submissionReportReceivers').setErrors({ duplicateEmails: true });
        return;
      }
      this.reportReceiversEmail = [...this.reportReceiversEmail, ...emails];
    } else {
      if (!this.vendorDataFormGroup.get('submissionReportReceivers').valid) {
        return
      }
      this.reportReceiversEmail.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
    event.value = '';
    this.vendorDataFormGroup.get('submissionReportReceivers').patchValue(null);
    this.vendorDataFormGroup.get('submissionReportReceivers').updateValueAndValidity();
  }

  findDuplicatesInArray(arr) {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) !== index;
    });
  }


  /**
   * Delete selected email from the list
   * @param email - email to delete
   */
  removeReceiversEmail(email): void {
    const index = this.reportReceiversEmail.indexOf(email);

    if (index >= 0) {
      this.reportReceiversEmail.splice(index, 1);
    }
  }

  /**
   * Validate if entered email is already present in the list
   * @param control - form control to which applied validator
   */
  duplicateValidator(control: AbstractControl) {
    if (control.value && this.reportReceiversEmail.includes(control.value)) {
      return { duplicateEmail: true };
    }
  }

  onReportReceiversFocusOut(event) {
    this.addReceiverEmail(event.target);
    if (!this.vendorDataFormGroup.get('submissionReportReceivers').valid) {
      this.vendorDataFormGroup.get('submissionReportReceivers').patchValue(event.target.value);
    }
  }

  closeAutocomplete($event: MouseEvent): void {
    if(this.autocomplete.panelOpen) {
      $event.stopPropagation();
      this.autocomplete.closePanel();
    }
  }
}
