import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { UriConfig } from "../../app.config";
import { BehaviorSubject, Observable } from "rxjs";
import { StreakRoundDashboardModel } from "../../common/models/StreakRoundDashboardModel";
import { StreakRoundModel } from "../../common/models/StreakRoundModel";
import { StreakModel } from "../../common/models/StreakModel";
import { StreakQuestionDifficultyEnum } from "../../common/Enums/StreakQuestionDifficultyEnum";
import { TextService } from "./text.service";

@Injectable({
  providedIn: 'root'
})
export class StreaksService {

  needUpdateStreakRounds$ = new BehaviorSubject(true);

  needUpdateStreaks$ = new BehaviorSubject<boolean>(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private textService: TextService
  ) {}

  fetchStreakRoundsList(requestBody) {
    let params = new HttpParams()
    Object.keys(requestBody).forEach(param => {
      if (param === "status") {
        requestBody[param]?.forEach(level => {
          params = params.append(param, level);
        })
      } else {
        params = params.set(param, requestBody[param]);
      }
    })
    return this.http.get<{records: StreakRoundDashboardModel[], total: number }>(this.uriConfig.rounds + '/streak-rounds', { params });
  }

  deleteStreakById(roundId: string | number) {
    return this.http.delete<any>(this.uriConfig.rounds + '/' + roundId);
  }

  createStreakRound(round: StreakRoundModel):Observable<{ id: number }> {
    return this.http.post<{ id: number }>(this.uriConfig.streakRounds, round);
  }

  editStreakRound(body, roundId: StreakRoundModel) {
    return this.http.patch(this.uriConfig.rounds + '/' + roundId +'/streak', body);
  }

  publishStreakRound(body, roundId) {
    return this.http.patch(this.uriConfig.rounds + '/' + roundId +'/streak', body);
  }

  toggleLandingPageDisplay(id: number, isDefault) {
    return this.http.patch(this.uriConfig.rounds + '/' + id +'/streak', {isDefault});
  }

  fetchStreaksList(requestBody: { pageNumber: number, pageSize: number, name?: string }) {
    let params = new HttpParams()
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param]);
    })
    return this.http.get<{records: StreakModel[], total: number }>(this.uriConfig.rounds + '/streaks', { params });
  }

  editStreakRoundDay(body, id) {
    return this.http.patch(this.uriConfig.rounds + '/' + id + '/streak-round', body);
  }

  getListOfDifficultyLevels() {
    return Object.keys(StreakQuestionDifficultyEnum)
      .filter(status => status.toLowerCase() !== 'verified')
      .map(status => ({label: this.textService.createNameWithSpaceAndCapitalizeFirstLetter(status), value: status}));
  }

  downloadStreakRoundSubmissionReport(roundId: number) {
    return this.http.get(this.uriConfig.analytics + '/streak/' + roundId + '/daily');
  }

  downloadStreakSubmissionReport(roundId: number) {
    return this.http.get(this.uriConfig.analytics + '/streak/' + roundId );
  }

  sendStreakReportToS3(roundId: number) {
    return this.http.get(this.uriConfig.analytics + '/streak/' + roundId  + '/send');
  }

  sendDailyStreakReportToS3(roundId: number) {
    return this.http.get(this.uriConfig.analytics + '/streak/' + roundId  + '/daily/send');
  }
}
