import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  lastValueFrom,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  throwError
} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {DialogService} from "../../_base-component/dialog/dialog.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {openDateValidator} from "../../../modules/validators/open-date-validator";
import {closeDateValidator} from "../../../modules/validators/close-date-validator";
import {IsCloseDateAfterOpen} from "../../../../new-round-wizzard/wizzard-sections/details-section/date-validator";
import {SnackBarService} from "../../../../core/services/snack-bar.service";
import {StreaksService} from "../../../../core/services/streaks.service";
import {LevelPositionsSetModel} from "../../../models/StreakRoundModel";
import {cloneDeep} from "lodash";
import moment from "moment";
import {StreakHelperService} from "../../../../core/services/streak/streak-helper.service";
import {RoundStatusEnum} from "../../../Enums/RoundStatusEnum";
import {TooltipPositionEnum} from "../../../Enums/TooltipPositionEnum";
import {LocalizationService} from "../../../../core/services/localization.service";

@Component({
	selector: 'create-streak',
	templateUrl: './create-streak.component.html',
	styleUrls: ['./create-streak.component.scss']
})
export class CreateStreakComponent implements OnInit, OnDestroy {

	displayDraftButton$ = new BehaviorSubject(true);

	pointsFormBuilt$ = new BehaviorSubject<boolean>(false);

  isDraft$ = new BehaviorSubject(false);

	detailsForm: FormGroup;

	streakDetailsForm: FormGroup;

	tabIndex = 0;

	header = 'Create Streak';

	isInitChanges = true;

	isTryToSave = false;

  private unsubscribe$: Subject<void> = new Subject();
  private isSubmitting = false;

	protected readonly TooltipPositionEnum = TooltipPositionEnum;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<CreateStreakComponent>,
		private dialogService: DialogService,
		private isCloseDateAfterOpen: IsCloseDateAfterOpen,
		private streaksService: StreaksService,
		private snackBarService: SnackBarService,
		private fb: FormBuilder,
		private streakHelperService: StreakHelperService,
    private localizationService: LocalizationService,
	) {
	}

	ngOnInit(): void {
		this.buildForms();
		if (this.data?.id) {
			this.header = 'Edit Streak';
		}

    this.checkIsDraft();

		this.detailsForm.valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(() => this.isCloseDateAfterOpen.roundDateErrorChecker(this.detailsForm));

		this.detailsForm.get('openDate').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(openDate => {
				const openTime = this.detailsForm.get('streakOpenTime').value;
				if (openTime) {
					const time = this.getFormattedDateTime(openTime, openDate);
					this.detailsForm.get('streakOpenTime').patchValue(time);
				} else {
					this.detailsForm.get('streakOpenTime').patchValue(openDate);
				}
			});

		this.detailsForm.get('closeDate').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(() => {
				const openDate = this.detailsForm.get('openDate').value;
				if (openDate) {
					this.detailsForm.get('openDate').patchValue(openDate);
					this.detailsForm.get('openDate').updateValueAndValidity();
				}
			});

		this.streakDetailsForm.get('correctAnswersNeeded').valueChanges
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				takeUntil(this.unsubscribe$))
			.subscribe(correctAnswersNeeded => {
				this.pointsFormBuilt$.next(false);
				const savedValue = this.isInitChanges ? cloneDeep(this.data?.levelPoints) : null;
				this.isInitChanges = false;
				delete savedValue?.['1'];
				this.rebuildLevelPointsForm(+correctAnswersNeeded, savedValue);
				if (correctAnswersNeeded) {
					this.pointsFormBuilt$.next(true);
				}
			})

    combineLatest([
      this.detailsForm.get('closeDate').valueChanges.pipe(startWith(this.detailsForm.get('closeDate').value)),
      this.detailsForm.get('openDate').valueChanges.pipe(startWith(this.detailsForm.get('openDate').value)),
      this.detailsForm.get('streakOpenTime').valueChanges.pipe(startWith(this.detailsForm.get('streakOpenTime').value))
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged()
      )
      .subscribe(([closeDate, openDate, openTime]) => {
        if (openDate && closeDate && openTime) {
          const maxValue = this.calculateDaysBetweenDates(openDate, closeDate, openTime);
          this.streakDetailsForm.get('correctAnswersNeeded').setValidators([Validators.required, Validators.max(maxValue), this.zeroValueValidator()]);
          if (this.streakDetailsForm.get('correctAnswersNeeded').value) {
            this.streakDetailsForm.get('correctAnswersNeeded').markAsTouched();
            this.streakDetailsForm.get('correctAnswersNeeded').updateValueAndValidity();
          }
        }
      })


		if (this.data?.id) {
			this.streakDetailsForm.get('correctAnswersNeeded').patchValue(this.data.correctAnswersNeeded);
		}
	}

  combineDateAndTime(dateObj: string, timeObj: string): Date {
    const combinedDate = new Date(dateObj);
    const time = new Date(timeObj);

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    const milliseconds = time.getMilliseconds();

    combinedDate.setHours(hours, minutes, seconds, milliseconds);

    return combinedDate;
  }


  calculateDaysBetweenDates(startDate: string, endDate: string, openTime: string): number {
    const date1 = this.combineDateAndTime(startDate, openTime);
    const date2 = new Date(endDate);

    const timeDifference = Math.abs(date2.getTime() - date1.getTime());

    let resultDifference =  Math.ceil(timeDifference / (1000 * 3600 * 24));

    resultDifference = ((this.isAfter3AM(date1) && this.compareTime(date1, date2) && !this.isTimeSame(date1, date2))
      || (!this.isAfter3AM(date1) && !this.isAfter3AM(date2) && this.compareTime(date1, date2) && !this.isTimeSame(date1, date2))
      || (this.isAfter3AM(date1) && !this.isAfter3AM(date2) && !this.isTimeSame(date1, date2))) ? resultDifference - 1 : resultDifference;

    return resultDifference;
  }

  isTimeSame(date1: Date, date2: Date): boolean {
    return (
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes() &&
      date1.getSeconds() === date2.getSeconds() &&
      date1.getMilliseconds() === date2.getMilliseconds()
    );
  }

  compareTime (date1: Date, date2: Date): boolean {
    const time1 = date1.getHours() * 3600000 + date1.getMinutes() * 60000 + date1.getSeconds() * 1000 + date1.getMilliseconds();
    const time2 = date2.getHours() * 3600000 + date2.getMinutes() * 60000 + date2.getSeconds() * 1000 + date2.getMilliseconds();

    return time1 < time2;
  }


  isAfter3AM(openTime: Date): boolean {
    const hours = openTime.getHours();

    return hours >= 3;
  }

  private checkIsDraft() {
    this.displayDraftButton$.next( !this.data.status || this.data.status === RoundStatusEnum.DRAFT)
    this.isDraft$.next( !this.data.status || this.data.status === RoundStatusEnum.DRAFT)
  }

	buildForms() {
		const {
			name,
			openDate,
			closeDate,
			prize,
			skippedQuestionsForResetting,
			levelPoints,
			status,
			id,
			visibleForUsers,
			ticketRequired
		} = this.data;
		this.detailsForm = new FormGroup({
				name: new FormControl(name, [Validators.required, Validators.minLength(1), Validators.maxLength(52)]),
				openDate: new FormControl(openDate, Validators.compose([Validators.required, openDateValidator(true)])),
				closeDate: new FormControl(closeDate, [Validators.required, closeDateValidator()]),
				streakOpenTime: new FormControl(id ? openDate : null, [Validators.required, openDateValidator(false)]),
				visibleForUsers: new FormControl(id ? visibleForUsers : true),
				ticketRequired: new FormControl(id ? ticketRequired : null),
			},
			{
				validators: [this.isCloseDateAfterOpen.validateDates()],
			});
		if (status && this.streakHelperService.isRoundStarted(status)) {
			this.detailsForm.get('openDate').disable();
			this.detailsForm.get('streakOpenTime').disable();
			this.detailsForm.get('visibleForUsers').disable();
			this.detailsForm.get('ticketRequired').disable();
		}
		if (status === RoundStatusEnum.COMPLETED) {
			this.detailsForm.get('closeDate').disable();
		}
    if (status && this.streakHelperService.isRoundFinished(status)) {
      this.detailsForm.get('closeDate').disable();
    }

		this.streakDetailsForm = new FormGroup({
			prize: new FormControl(prize, [Validators.required]),
			correctAnswersNeeded: new FormControl(null, [Validators.required]),
			skippedQuestionsForResetting: new FormControl(skippedQuestionsForResetting, [Validators.required, Validators.min(1)]),
			levelPoints: this.fb.group({
				points: this.fb.array([this.createFormItem(1, levelPoints?.['1'])]),
			})
		});


    if (status && this.streakHelperService.isRoundStarted(status)) {
      this.streakDetailsForm.get('correctAnswersNeeded').disable();
      this.streakDetailsForm.get('skippedQuestionsForResetting').disable();
    }
	}

  zeroValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value && +value === 0 ? { zeroValue: true } : null;
    };
  }

	rebuildLevelPointsForm(correctAnswersNeeded, savedValue) {
		if (correctAnswersNeeded == null) return;
		const levelPointsCount = this.levelPoints.length;
		const diff = correctAnswersNeeded - levelPointsCount;

		if (!diff) return;

		if (diff > 0) {
			this.addFormControls(diff, levelPointsCount, savedValue);
		} else {
			const absolutDiff = Math.abs(diff);
			this.removeFormControls(absolutDiff, levelPointsCount - absolutDiff);
		}
	}

	addFormControls(numberToAdd, levelPointsCount, savedValue) {
		const arr = this.generateArray(numberToAdd);
		arr.forEach(index => {
			const currentValue = savedValue ? savedValue[index + 1] : null;
			this.levelPoints.push(this.createFormItem(levelPointsCount + index, currentValue));
		})
		if (this.isTryToSave) {
			this.validatePointArray();
		}
	}

	removeFormControls(numberToRemove, start) {
		const arr = this.generateArray(numberToRemove, start);
		arr.reverse().forEach(index => {
			this.levelPoints.removeAt(index);
		})
	}

	generateArray(length, start = 1) {
		return Array.from({length}, (_, i) => start + i).slice(0, length);
	}

	get levelPoints(): FormArray {
		return this.streakDetailsForm?.get('levelPoints').get('points') as FormArray;
	}

	createFormItem(value, savedValue): FormGroup {
    const disableControls = this.data.status ? this.streakHelperService.isRoundStarted(this.data.status) : false;
		const formGroup = this.fb.group({
			points: new FormControl({value: savedValue?.points || value, disabled: disableControls}, [Validators.required]),
			questionDifficultyLevel: new FormControl(({value: savedValue?.questionDifficultyLevel, disabled: disableControls}), [Validators.required]),
			correctAnswer: new FormControl({value, disabled: true}, [Validators.required]),
		});
		return formGroup;
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async closeDialog() {
		if (this.detailsForm.dirty || this.streakDetailsForm.dirty) {
			await lastValueFrom(
				this.dialogService.open(
					{
						dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
						labelOk: 'Yes',
						labelNo: 'No'
					}
				).pipe(
					filter(response => !!response),
					tap(() => this.dialogRef.close(true))
				)
			)
		} else {
			this.dialogRef.close(true);
		}
	}

	saveRound(isDraft) {
    if ( this.isSubmitting ) return;

    this.isSubmitting = true;
		this.isTryToSave = true;
		if (this.detailsForm.get('name').invalid) {
			return;
		}

		if (!isDraft && !this.validateRound()) return;

		const roundBody = this.getRoundBody(isDraft);

		const request$ = this.data.id
      ? this.streaksService.editStreakRound(roundBody, this.data.id)
      : this.streaksService.createStreakRound(roundBody);

		request$.pipe(
			switchMap((response: {id: number, isRoundNonLocalized?: boolean, status?: string, areQuestionsAvailable?: boolean}) => {
        this.data.id = response.id;
        if (!isDraft && response && response.areQuestionsAvailable) {
          return this.streaksService.publishStreakRound({publish: true}, response.id)
				}

        if (!response.areQuestionsAvailable) return this.showNotAvailableQuestionsErr();

				return of(response);
			}),
      switchMap((data: {id: number, isRoundNonLocalized?: boolean, status?: string}) => {
        return this.localizationService.vendorLocales$.pipe(
          switchMap(locales => {
            const isLocalizedPopupDisplayed = this.data.id ? (data?.isRoundNonLocalized && this.isDraft$.value && locales.length) : locales.length;
            if (isLocalizedPopupDisplayed) {
              this.dialogService.openLocalizedErrorMessage({id: this.data.id ?? data.id }, this.dialogRef).then(() => {
                this.streaksService.needUpdateStreaks$.next(true);
              });
            } else {
              this.streaksService.needUpdateStreaks$.next(true);
              this.dialogRef.close();
            }
            return of(null)
          })
        )
      }),
      catchError((error) => {
        if (error.error) {
          this.snackBarService.showSnackBar(error.error.message, true);
        }
        this.isSubmitting = false;
        return throwError(error);
      }),
      tap(() => this.isSubmitting = false),
      takeUntil(this.unsubscribe$)
		).subscribe()
	}

  private showNotAvailableQuestionsErr(): Observable<null> {
    return this.dialogService.open(
      {
        dialogContent: 'The round has been saved as a DRAFT as you don\'t have enough questions. Would you like to finish editing?',
        labelOk: 'Close',
        labelNo: 'Continue editing'
      }, 'custom-dialog-box-streak'
    ).pipe(
      tap(() => this.streaksService.needUpdateStreaks$.next(true)),
      tap((res) => res && this.dialogRef.close()),
      switchMap(() => throwError('No questions available, stopping observable'))
    )
  }


	validateRound() {
		if (this.detailsForm.invalid || this.streakDetailsForm.invalid) {
			Object.keys(this.detailsForm.controls).forEach(controlName => {
				this.detailsForm.get(controlName).markAsTouched()
				this.detailsForm.get(controlName).updateValueAndValidity()
			})
			Object.keys(this.streakDetailsForm.controls).forEach(name => {
				if (name === 'levelPoints') {
					this.validatePointArray()
				} else {
					this.streakDetailsForm.get(name).markAsTouched();
					this.streakDetailsForm.get(name).updateValueAndValidity();
				}
			})
			return false;
		}
		return true;
	}

	validatePointArray() {
		for (let i = 0; i < this.levelPoints.length; i++) {
			const item = this.levelPoints.at(i);
			item.get('questionDifficultyLevel').markAsTouched();
			item.get('questionDifficultyLevel').updateValueAndValidity();
			item.get('points').markAsTouched();
			item.get('points').updateValueAndValidity();
		}
	}

	isDraftInvalid() {
		let isDraftInvalid = this.detailsForm.get('name').invalid;

		if (this.streakDetailsForm.dirty && this.streakDetailsForm.get('correctAnswersNeeded').value) {
			isDraftInvalid = this.levelPoints.invalid || this.streakDetailsForm.get('correctAnswersNeeded').invalid;
		}

		return isDraftInvalid;
	}

	getRoundBody(isDraft) {
		const roundBody = this.detailsForm.value
		const streakBody = this.streakDetailsForm.value;
		if (isDraft) {
			Object.keys(roundBody).forEach(key => {
				if (!roundBody[key]) delete roundBody[key];
			})
			if (!streakBody.correctAnswersNeeded) {
				delete streakBody.levelPoints
			}
			Object.keys(streakBody).forEach(key => {
				if (!streakBody[key]) delete streakBody[key];
			})
		}

		if (this.data?.status && this.streakHelperService.isRoundStarted(this.data.status)) {
			delete roundBody.openDate;
		}
		if (this.data?.status === RoundStatusEnum.COMPLETED) {
			delete roundBody.closeDate;
		}

		const {openDate, closeDate, streakOpenTime} = roundBody;
		const {prize, correctAnswersNeeded, levelPoints, skippedQuestionsForResetting} = streakBody;
		if (prize) {
			roundBody.prize = +prize;
		}

    const arePointsIncluded = this.data.status ? !this.streakHelperService.isRoundStarted(this.data?.status) : true;

    if (arePointsIncluded && levelPoints?.points) {
      const levelPointsBody = this.createLevelPoints(levelPoints.points);
      roundBody.correctAnswersNeeded = +correctAnswersNeeded;
      roundBody.levelPoints = levelPointsBody;
      if (skippedQuestionsForResetting) {
        roundBody.skippedQuestionsForResetting = +skippedQuestionsForResetting;
      }
    }

		if (openDate) {
			roundBody.openDate = this.getFormattedDateTime(streakOpenTime, openDate);
		}
		if (closeDate) {
			roundBody.closeDate = typeof closeDate === 'string' ? closeDate : closeDate.toISOString();
		}
		delete roundBody.streakOpenTime;

		return roundBody;
	}

	getFormattedDateTime(time, date) {
		if (!time || !date) return date;
		const formattedTime = typeof time === 'string' ? moment(time) : time;
		const formattedDate = typeof date === 'string' ? moment(date) : date;
		const hours = formattedTime.hour();
		const minutes = formattedTime.minute();
		const second = formattedTime.second();
		return formattedDate.hour(hours).minute(minutes).second(second).toISOString();
	}

	createLevelPoints(levelPoints) {
		const levelPointsBody: LevelPositionsSetModel = {}
		levelPoints.forEach((levelPoint, index) => {
			levelPointsBody[(index + 1).toString()] = levelPoint;
		})
		return levelPointsBody;
	}
}
