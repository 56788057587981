<div class="user-data-container">
  <div class="user-data-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onCloseModal()">close</mat-icon>
        <h2 class="popup-header">Update difficulty levels</h2>
      </div>
    </div>
  </div>
  <div class="difficulty-builder-body">
    <div class="difficulty-builder-content" *ngIf="currentGameDetails$ | async as currentGameDetails">
      <div class="difficulty-builder-details">
        <div class="game-details body-big-medium">
          Current streak game: {{currentGameDetails.id}} - {{currentGameDetails.name}}
        </div>
        <ng-container *ngIf="isDifficultyLevelExist$ | async else noDifficultyLevel">
          <form [formGroup]="userDataFromGroup">
            <level-points-group
              [hidePoints]="true"
              [levelPoints]="levelPoints.controls"
            >
            </level-points-group>
          </form>
        </ng-container>
        <ng-template #noDifficultyLevel>
          There is no difficulty level for current user
        </ng-template>
      </div>
      <div class="buttons-container" *ngIf="isDifficultyLevelExist$ | async">
        <button mat-raised-button color="primary" [disabled]="!userDataFromGroup.dirty" (click)="onSaveDifficultyLeve()">Save</button>
        <button mat-raised-button (click)="onCloseModal()">Close</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!(isLoaded$ | async)" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
