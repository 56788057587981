<div class="game-builder-container">
  <div class="game-builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
        <h2 class="popup-header">Edit User</h2>
        <status-chip [status]="data.state"  *ngIf="data" [chipType]="LabelChipTypeEnum.VENDOR_STATUS">
          {{textService.capitalizeFirstLetter(data.state)}}
        </status-chip>
      </div>
      <div class="control-header">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!this.userDataFromGroup?.valid || !isLoaded"
          (click)="saveUser()">
          Save
        </button>
      </div>
    </div>
  </div>
  <div class="game-builder-body">
    <div class="game-builder-content">
      <div class="prize-details">
        <form [formGroup]="userDataFromGroup">
          <div class="form-field-with-label">
            <mat-label>First Name*</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                trimDoubleSpace
                placeholder="Enter first name"
                [formControlName]="'firstName'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('firstName', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MANDATORY}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('firstName', 'required') && isShowError('firstName','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT_15}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('firstName', 'required') && isShowError('firstName','minlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MIN_LENGHT_3}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('firstName', 'required') && isShowError('firstName','pattern')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.SPECIAL_CHARACTER_EXCLUDE}}</span>
            </mat-error>
          </div>
          <div class="form-field-with-label">
            <mat-label>Last Name*</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                trimDoubleSpace
                placeholder="Enter last name"
                [formControlName]="'lastName'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('lastName', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MANDATORY}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('lastName', 'required') && isShowError('lastName','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT_15}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('lastName', 'required') && isShowError('lastName','minlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MIN_LENGHT_3}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('lastName', 'required') && isShowError('lastName','pattern')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.SPECIAL_CHARACTER_EXCLUDE}}</span>
            </mat-error>
          </div>
          <div class="form-field-with-label">
            <mat-label>Email*</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                trimDoubleSpace
                placeholder="Enter email"
                [formControlName]="'email'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('email', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MANDATORY}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('email', 'required') && isShowError('email','email')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.EMAIL_ERROR}}</span>
            </mat-error>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('email', 'required') && isShowError('email','maxlength')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MAX_LENGHT_70}}</span>
            </mat-error>
          </div>
          <drag-and-drop-file
            [isRequired]="false"
            [title]="'Profile Image '"
            [image]="{url: userAvatar?.url}"
            (fileUpload)="uploadFile($event, 'avatar')"
          ></drag-and-drop-file>
          <div class="form-field-with-label">
            <mat-label>MetaMask Wallet Address</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                trim
                trimDoubleSpace
                placeholder="Enter MetaMask wallet address"
                [formControlName]="'metaMaskWalletAddress'">
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="!isShowError('metaMaskWalletAddress', 'required') && isShowError('metaMaskWalletAddress','pattern')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.WALLET}}</span>
            </mat-error>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isLoaded" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
