<div class="form-wrapper" [ngClass]="{'two-columns': hidePoints}">
  <div class="form-header-wrapper">
    <div>Level Number</div>
    <div>Difficulty</div>
    <div *ngIf="!hidePoints">Points per Question</div>
  </div>
  <div *ngFor="let levelPointsItem of levelPoints; let i = index" class="form-group-container">
    <custom-input
      class="item-input"
      [isSmall]="!hidePoints"
      [formControl]="levelPointsItem.get('correctAnswer')"
      [type]="'number'">
    </custom-input>

    <custom-select
      class="item-input"
      [isSmall]="!hidePoints"
      [formControl]="levelPointsItem.get('questionDifficultyLevel')"
      [selectOptions]="difficultyArray"
      [placeholder]="'Select difficulty'"
    >
    </custom-select>

    <custom-input
      class="item-input"
      *ngIf="!hidePoints"
      [isSmall]="true"
      [formControl]="levelPointsItem.get('points')"
      [type]="'number'"
      [placeholder]="'Enter points'">
    </custom-input>
  </div>
</div>
