import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UriConfig } from "../../app.config";
import { catchError, tap, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CsvGameCreatorService {

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {
  }

  getFileTemplate(classType: string) {
    return this.http.get(this.uriConfig.fileTemplate + '/' + classType, {responseType: "blob"}).pipe(
      tap((data) => {
        const fileName = `${classType.toLowerCase()}-round-creating-template.csv`
        this.downLoadFile(data, 'text/csv', fileName);
      }),
      catchError(error => {
        return throwError(error)
      })
    );
  }

  downLoadFile(data: any, type: string, fileName: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(this.uriConfig.uploadCSVFile, formData);
  }

}
