import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { RoundClassEnum } from "../../../Enums/RoundClassEnum";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { APP_DATA } from "../../../../general.app.config";
import { CsvGameCreatorService } from "../../../../core/services/csv-game-creator.service";
import { catchError, Subject, takeUntil, tap, throwError } from "rxjs";
@Component({
  selector: 'create-game-from-csv-modal',
  templateUrl: './create-game-from-csv-modal.component.html',
  styleUrls: ['./create-game-from-csv-modal.component.scss'],
})
export class CreateGameFromCsvModalComponent implements OnDestroy {

  @ViewChild('fileInput') fileInput: ElementRef;

  createGameFromSCVForm: FormGroup;

  selectedFile: File;

  fileName: string;

  errorMessages: string[];

  gameType: { displayValue: string, value: RoundClassEnum }[] = [
    {
      displayValue: 'Single',
      value: RoundClassEnum.Single
    },
    {
      displayValue: 'Multi',
      value: RoundClassEnum.Multi
    }
  ];

  appData = APP_DATA;

  private destroy$ = new Subject<void>();


  constructor(
    public dialogRef: MatDialogRef<CreateGameFromCsvModalComponent>,
    private csvGameCreatorService: CsvGameCreatorService
  ) {
    this.createGameFromSCVForm = new FormGroup({
      gameType: new FormControl(RoundClassEnum.Single, [Validators.required]),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  onCloseModal(roundId = null) {
    this.dialogRef.close(roundId);
  }

  getTemplateFile() {
    this.csvGameCreatorService.getFileTemplate(this.createGameFromSCVForm.value.gameType)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  onFileChange(event: any) {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      this.selectedFile = files[0];
      this.fileName = this.selectedFile.name;
    }

    if (this.errorMessages) {
      this.errorMessages = [];
    }
  }

  onConfirmClick() {
    this.csvGameCreatorService.uploadFile(this.selectedFile).pipe(
      takeUntil(this.destroy$),
      tap(({id}) => this.onCloseModal(id)),
      catchError((error) => {
        if (Array.isArray(error.error.message)) {
          this.errorMessages = error.error.message.map((item: string) => item[0].toUpperCase() + item.slice(1));
        } else {
          const transformedError = error.error.message[0].toUpperCase() + error.error.message.slice(1)
          this.errorMessages = [transformedError];
        }
        return throwError(error);
      })
    ).subscribe();
  }

}
