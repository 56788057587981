<div class="builder-tabs-container">
  <div class="builder-wrapper">
    <div class="header-pannel">
      <div class="header-container">
        <div class="general-header">
          <mat-icon class="material-icons close" (click)="closeDialog()">close</mat-icon>
          <h2 class="popup-header">{{header}}</h2>
        </div>
        <div class="buttons-container">
          <button
            *ngIf="displayDraftButton$ | async"
            (click)="saveRound(true)"
            mat-stroked-button
            [disabled]="isDraftInvalid()"
            color="primary">
            Save Draft
          </button>

          <button
            (click)="saveRound(false)"
            mat-raised-button
            [disabled]="detailsForm.invalid || streakDetailsForm.invalid"
            color="primary">
            Publish
          </button>
          <i
            class="fa-solid fa-circle-info tooltip"
            [matTooltipPosition]="TooltipPositionEnum.right"
            #tooltip="matTooltip"
            [matTooltip]="'All fields should be filled and valid to ' + (data?.id ? 'edit' : 'add') + ' a round'"
            [matTooltipClass]="TooltipPositionEnum.right"></i>
        </div>
      </div>
    </div>
    <div class="tabs-details">
      <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabIndex = $event.index" dynamicHeight>
        <mat-tab
          *ngFor="let label of ['Game Details', 'Streak Page']"
          [label]="label">
          <ng-template *ngIf="!tabIndex" [ngTemplateOutlet]="detailsFormTemplate">

          </ng-template>
          <ng-template *ngIf="tabIndex" [ngTemplateOutlet]="streakFormTemplate">

          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<ng-template #detailsFormTemplate>
  <div class="tab-name body-big-bold">GAME DETAILS</div>
  <form [formGroup]="detailsForm">
    <div class="ticket-form-container">
      <div class="form-field-with-label">
        <mat-label>
          Visible for all users
        </mat-label>
        <mat-slide-toggle
          color="primary"
          formControlName="visibleForUsers">
        </mat-slide-toggle>
      </div>
      <div class="form-field-with-label">
        <mat-label>
          Ticket required
        </mat-label>
        <mat-slide-toggle
          color="primary"
          formControlName="ticketRequired">
        </mat-slide-toggle>
      </div>
    </div>
    <custom-input
      [formControl]="detailsForm.get('name')"
      placeholder="Enter game name">
      Game name*
    </custom-input>
    <div class="dates-controllers">
      <custom-date-input
        [pickerType]="'calendar'"
        [formControl]="detailsForm.get('openDate')"
        placeholder="Select open date">
        Open Date*
      </custom-date-input>
      <custom-date-input
        [formControl]="detailsForm.get('closeDate')"
        placeholder="Select close date & time">
        Close Date & Time*
      </custom-date-input>
    </div>
    <custom-date-input
      [pickerType]="'timer'"
      [formControl]="detailsForm.get('streakOpenTime')"
      placeholder="Select open time">
      Round Open Time*
    </custom-date-input>
  </form>
</ng-template>


<ng-template #streakFormTemplate>
  <div class="tab-name body-big-bold">STREAK PAGE</div>
  <form [formGroup]="streakDetailsForm">
    <custom-input
      [formControl]="streakDetailsForm.get('prize')"
      [type]="'number'"
      placeholder="Enter jackpot prize">
      Jackpot Prize*
    </custom-input>
    <custom-input
      [formControl]="streakDetailsForm.get('correctAnswersNeeded')"
      [type]="'number'"
      placeholder="Enter correct answers needed to win jackpot">
      Correct Answers Needed to Win Jackpot*
    </custom-input>
    <custom-input
      [formControl]="streakDetailsForm.get('skippedQuestionsForResetting')"
      [type]="'number'"
      placeholder="Enter number of unanswered questions before losing streak">
      Number of Unanswered Questions Before Losing Streak*
    </custom-input>
    <ng-container *ngIf="(pointsFormBuilt$ | async) ">
        <level-points-group
          [levelPoints]="levelPoints.controls"
        >
        </level-points-group>
    </ng-container>
  </form>
</ng-template>
