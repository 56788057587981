import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { UriConfig } from "../../app.config";
import { StreakQuestionModel } from "../../common/models/StreakQuestionModel";
import { QuestionTypesEnum } from "../../common/Enums/QuestionTypesEnum";
import { BehaviorSubject, Observable } from "rxjs";
import { StreakQuestionListItemModel } from "../../common/models/StreakQuestionListItemModel";

@Injectable({
  providedIn: 'root'
})
export class QuestionStreakService {

  needUpdateStreakQuestions$ = new BehaviorSubject(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {}

  createStreakQuestion(question, questionType, localeizationData, locales, answerGroups) {
    const questionBody = this.createQuestionBody(question, questionType, localeizationData, locales, answerGroups);
    return this.http.post<StreakQuestionModel>(this.uriConfig.questionsStreak, questionBody);
  }

  updateStreakQuestion(question, questionType, localeizationData, locales, answerGroups, id) {
    const questionBody = this.createQuestionBody(question, questionType, localeizationData, locales, answerGroups);
    return this.http.patch<StreakQuestionModel>(this.uriConfig.questions + '/' + id + '/streak', questionBody);
  }

  getStreakQuestionById(id: number): Observable<StreakQuestionModel>{
    return this.http.get<StreakQuestionModel>(this.uriConfig.questions + '/' + id + '/streak');
  }

  getLocalizations(localizationData, locales, answersKey?, index?) {
    return Object.keys(localizationData).map(locale => {
      return {
        localeId: locales.find(item => item.country === locale).id,
        text: answersKey ? localizationData[locale][answersKey][index].text : localizationData[locale].text
      }
    })
  }

  getLocalizedAnswers(groupId, answers, localeizationData, locales, answerGroups) {
    const groupName = answerGroups.find(item => item.id === groupId);
    return answers.map((answer, index) => {
      return {
        answerId: answer.id,
        groupName: groupName ? groupName.name : null,
        localizations: this.getLocalizations(localeizationData, locales, 'answers', index)
      }
    });
  }

  getLocalizedAnswersForLists(groupId1, groupId2, answers1st, answers2st, localeizationData, locales, answerGroups) {
    const groupName1 = answerGroups.find(item => item.id === groupId1);
    const groupName2 = answerGroups.find(item => item.id === groupId2);

    const answers1 = answers1st.map((answer, index) => {
      return {
        answerId: answer.id,
        groupName: groupName1 ? groupName1.name : 'group_0',
        localizations: this.getLocalizations(localeizationData, locales,'answers1st', index)
      }
    });

    const answers2 = answers2st.map((answer, index) => {
      return {
        answerId: answer.id,
        groupName: groupName2 ? groupName2.name : 'group_1',
        localizations: this.getLocalizations(localeizationData, locales, 'answers2st', index)
      }
    });

    return [...answers1, ...answers2]
  }

  createQuestionBody(question, questionType, localeizationData, locales, answerGroups) {
    const type = question.type;

    let questionBody: Partial<StreakQuestionModel> = {
      type: type,
      text: question.text,
      imageUrl: question.imageUrl ? question.imageUrl : null,
      difficultyLevel: question.difficultyLevel,
      localizations: this.getLocalizations(localeizationData, locales)
    }



    switch (type) {
      case QuestionTypesEnum.SCORE_PLUS:
        questionBody = {
          ...questionBody,
          maxScoreValue: questionType.maxScoreValue + '+'
        }
        break;
      case QuestionTypesEnum.RANGE:
        questionBody = {
          ...questionBody,
          attributes: {
            minValue: questionType.minValue,
            maxValue: questionType.maxValue,
            incrementValue: questionType.incrementValue,
            displayValue: questionType.displayValue
          }
        }
        break;
      case QuestionTypesEnum.OPTIONS:
      case QuestionTypesEnum.LIST:
      case QuestionTypesEnum.GRID:
        questionBody = {
          ...questionBody,
          answers: this.getLocalizedAnswers(questionType.groupId, questionType.additional.answers, localeizationData, locales, answerGroups)
        }
        break;
      case QuestionTypesEnum.LISTS:
        questionBody = {
          ...questionBody,
          answers: this.getLocalizedAnswersForLists(
            questionType.answerGroupId,
            questionType.answerGroupId2,
            questionType.additional.answers1st,
            questionType.additional.answers2st,
            localeizationData,
            locales,
            answerGroups
          )
        }
        break;

    }

    return questionBody
  }

  fetchStreakQuestionList(requestBody) {
    let params = new HttpParams()
    Object.keys(requestBody).forEach(param => {
      if (param === "difficultyLevels") {
        requestBody[param]?.forEach(level => {
          params = params.append(param, level);
        })
      } else {
        params = params.set(param, requestBody[param]);
      }
    })
    return this.http.get<{records: {[key:string] : string}[], total: number }>(this.uriConfig.baseApiUrl + 'questions/streak', { params });
  }

  deleteStreakQuestion(questionId) {
    return this.http.delete(this.uriConfig.questions + '/' +questionId);
  }

  fetchQuestionListByRoundId(roundId): Observable<any> {
    return this.http.get<StreakQuestionListItemModel[]>(this.uriConfig.questionsStreak + '/' + roundId)
  }
}
