import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UriConfig } from "../../../../app.config";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class DiffReportService {

 constructor(protected http: HttpClient,
             private uriConfig: UriConfig) {}

 compareCSV(files: File[]): Observable<{link: string}> {
   const formData = new FormData();

   formData.append(`oldFile`, files[0], files[0].name)
   formData.append(`newFile`, files[1], files[1].name)

  return this.http.post<{link: string}>(this.uriConfig.analytics + '/submission-reports/compare',  formData)
 }
}
