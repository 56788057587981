<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="close()">close</mat-icon>
        <h2 class="popup-header">Help</h2>
      </div>
    </div>
  </div>
  <div class="builder-body">
    <div class="builder-content" [ngClass]="{'not-editable': saveButtonText === HelpPopupButtonsEnum.Edit}">
      <form [formGroup]="form">
        <div class="editor">
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" *ngIf="saveButtonText !== HelpPopupButtonsEnum.Edit">
          </ngx-editor-menu>
          <ngx-editor [editor]="editor" formControlName="editorContent">
          </ngx-editor>
        </div>
      </form>
    </div>
    <div class="buttons-container">
      <button mat-raised-button color="primary" [disabled]="saveButtonText === HelpPopupButtonsEnum.Edit ? false : !form.dirty" (click)="saveDoc()">{{ saveButtonText }}</button>
      <button mat-raised-button (click)="close()">Cancel</button>
    </div>
  </div>
</div>
