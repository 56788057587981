import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TwoFaAuthService } from "../../../../core/services/two-fa-auth.service";
import { SafeUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-2fa-popup',
  templateUrl: './two-fa-popup.component.html',
  styleUrls: ['./two-fa-popup.component.scss']
})
export class TwoFAPopupComponent {
  @Input() qrCodeSrc: SafeUrl;
  @Input() secret: string;
  @Input() showPopup = false;
  @Output() closePopupEvent = new EventEmitter<string>();
  code: string;
  showSecret = false;

  constructor(public twoFaAuthService: TwoFaAuthService) {}

  revealSecret(): void {
    this.showSecret = !this.showSecret;
  }

  confirmEnableTwoFA(): void {
    this.closePopupEvent.emit(this.code);
  }

  cancelTwoFa() {
    this.closePopupEvent.emit('');
  }

  isConfirmEnabled(): boolean {
    return !!this.code && this.code.length >= 6;
  }

}
