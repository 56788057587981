import { Injectable } from '@angular/core';
import { RoundStatusEnum } from "@enums/RoundStatusEnum";
import { StreakRoundDashboardModel } from "@models/StreakRoundDashboardModel";
import { StreakModel } from "@models/StreakModel";
import moment from "moment";
import { S3SubmissionStatusEnum } from "@enums/S3SubmissionStatusEnum";
import { CustomLeaderboardDetailsModel } from "@models/CustomLeaderboardDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class StreakHelperService {

  constructor() { }

  isRoundFinished (roundStatus) {
    return roundStatus === RoundStatusEnum.CLOSED || roundStatus === RoundStatusEnum.COMPLETED || roundStatus === RoundStatusEnum.RESULT_PROCESSING;
  }

  isRoundStarted(roundStatus) {
    return roundStatus !== RoundStatusEnum.DRAFT && roundStatus !== RoundStatusEnum.PENDING;
  }

  isDownloadAvailable(streakRound: StreakRoundDashboardModel | StreakModel) {
    return !(
      streakRound.status === RoundStatusEnum.DRAFT ||
      streakRound.status === RoundStatusEnum.PENDING
    );
  }

  getTooltipForS3Status(round: CustomLeaderboardDetailsModel | StreakModel | StreakRoundDashboardModel): string {
    const { outcomeReportStatus, outcomeReportSentAt } = round;
    const statusMessage = outcomeReportStatus === S3SubmissionStatusEnum.SUCCEED ?
      'S3 Upload Successful' : 'S3 Upload Unsuccessful';
    return `${statusMessage}: ${moment.utc(outcomeReportSentAt).local().format('D MMM YYYY, HH:mm')}`;
  }
}
