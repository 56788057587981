<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="close()">close</mat-icon>
        <h2 class="popup-header">{{ this.currentTab ? 'Edit Tab' : 'Create Tab' }}</h2>
      </div>
    </div>
  </div>
  <div class="builder-body info-popup">
    <div class="builder-content">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="changeTabIndex($event)">
        <mat-tab
          *ngFor="let locale of locales"
          [label]="locale">
          <form [formGroup]="formGroup">
            <div [formGroupName]="locale" class="form-container">
              <div class="field-wrapper">
                <h4>Tab name</h4>
                <custom-input
                  formControlName="name"
                  placeholder="Enter tab name">
                </custom-input>
              </div>
              <div class="field-wrapper" *ngIf="locale === 'Default'">
                <h4>Tab description</h4>
                <custom-input
                  formControlName="description"
                  placeholder="Enter tab description">
                </custom-input>
              </div>
              <div class="field-wrapper" *ngIf="(selectedContentTypeValue$ | async) !== 3">
                <h4>Tab title</h4>
                <custom-input
                  formControlName="title"
                  placeholder="Enter tab title">
                </custom-input>
              </div>
              <ng-container *ngIf="(selectedContentTypeValue$ | async) === 1 && locale === 'Default' && formGroup.get('Default').get('autofillGroup')">
                <form [formGroup]="autofillFormGroup">
                    <div class="field-wrapper item-container">
                      <mat-slide-toggle
                        color="primary"
                        formControlName="allowAutofill"
                      >
                        Allow autofill
                      </mat-slide-toggle>
                        <mat-icon
                          class="tooltip"
                          #tooltip="matTooltip"
                          matTooltip='Use autofill for the Results tab.
                                      Add special identifiers in the template
                                      to automatically display dynamic content.'>
                          info
                        </mat-icon>
                    </div>

                    <div class="field-wrapper item-container" *ngIf="autofillFormGroup.get('allowAutofill').value">
                      <mat-form-field>
                        <mat-select placeholder="Select Round" formControlName="roundId">
                          <mat-option *ngFor="let round of rounds" [value]="round.roundId">
                            ({{round.roundId}}) <b>{{ round.roundName }}</b>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="buttons-container">
                        <button mat-stroked-button
                                color="primary"
                                [disabled]="!autofillFormGroup.get('roundId').value"
                                (click)="previewClick()">Preview</button>
                      </div>
                    </div>
                </form>

                <div class="preview-container" [hidden]="isNull(previewDisplayed$ | async)">
                  <div  #preview [hidden]="!(previewDisplayed$ | async)">
                  </div>
                  <ng-container  *ngIf="!(previewDisplayed$ | async)" [ngTemplateOutlet]="error"></ng-container>
                  <div class="buttons-container">
                    <button mat-raised-button color="primary"
                            *ngIf="previewDisplayed$ | async"
                            [disabled]="!autofillFormGroup.get('roundId').value"
                            (click)="displayPrizeModalOnLanding(autofillFormGroup.get('roundId').value)">Display on landing</button>
                    <button mat-stroked-button
                            color="primary"
                            *ngIf="(previewDisplayed$ | async) && locales.length > 1"
                            (click)="fillAllLocalesWithNonLocalizedText()">Fill all locales with non localized content</button>
                  </div>
                </div>

              </ng-container>
              <div>
                <h4>Content</h4>
                <mat-radio-group
                  color="primary"
                  aria-label="Select content type" (change)="onSelectContentType($event)"
                  [value]="selectedContentTypeValue$ | async">
                  <mat-radio-button [value]="1">Default HTML</mat-radio-button>
                  <mat-radio-button [value]="2">Accordion</mat-radio-button>
                  <mat-radio-button [value]="3">Slider</mat-radio-button>
                </mat-radio-group>
                <html-editor-translations
                  *ngIf="(selectedContentTypeValue$ | async) === 1"
                  [editorFormControl]="getContentControl(locale)"></html-editor-translations>
                <ng-container *ngIf="(selectedContentTypeValue$ | async) === 2">
                  <form [formGroup]="jsonForm">
                    <div [formGroupName]="locales[selectedTabIndex]">
                      <ng-container formArrayName="items">
                        <div class="item-container"
                             *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
                          <custom-input
                            [placeholder]="'label text'"
                            [formControl]="item.get('title')">
                          </custom-input>
                          <html-editor-translations [editorFormControl]="item.get('content')"></html-editor-translations>
                          <div class="form-control-button-container">
                            <button
                              class="regular-button secondary-button delete"
                              mat-flat-button
                              color="primary"
                              (click)="removeItem(i, locale)"
                            >Delete</button>
                          </div>
                          <div class="divider"></div>
                        </div>
                      </ng-container>
                    </div>
                  </form>
                  <div class="form-control-button-container">
                    <button
                      class="regular-button secondary-button"
                      mat-flat-button
                      color="primary"
                      (click)="currentTab ? addItemForLocale({title: '', content: null}, locale) : addItem({title: '', content: null})"
                    >Add Question</button>
                  </div>
                </ng-container>

                <ng-container *ngIf="(selectedContentTypeValue$ | async) === 3">
                  <form [formGroup]="slidesForm">
                    <div [formGroupName]="locales[selectedTabIndex]">
                      <ng-container formArrayName="slides">
                        <div class="item-container"
                             *ngFor="let item of slides.controls; let i = index" [formGroupName]="i">
                          <custom-input
                            [placeholder]="'Enter slide title'"
                            [formControl]="item.get('title')">
                          </custom-input>
                          <html-editor-translations
                            [placeholder]="'Enter slide description'"
                            [editorFormControl]="item.get('description')"
                            [maxContentLength]="maxLengthForSliderContent"
                          ></html-editor-translations>
                          <div class="image-upload-form-field">
                            <div *ngIf="item.get('imageUrl')">
                              <drag-and-drop-file-form
                                formControlName="imageUrl"
                                [tooltipText]="tooltipTextIcon"
                                (imageUrlChange)="item?.get('imageUrl').patchValue($event)"
                                [url]="item?.get('imageUrl')?.value">
                                Slide Image
                              </drag-and-drop-file-form>
                            </div>
                          </div>
                            <div class="form-control-button-container">
                              <button
                                class="regular-button secondary-button delete"
                                mat-flat-button
                                color="primary"
                                (click)="removeSlide(i, locale)"
                              >Delete</button>
                           </div>
                          <div class="divider"></div>
                        </div>
                      </ng-container>
                    </div>
                  </form>
                  <div class="form-control-button-container">
                    <button
                      class="regular-button secondary-button"
                      mat-flat-button
                      color="primary"
                      [disabled]="slides.controls.length >= 5"
                      (click)="currentTab ? addSlideForLocale(mockSlide, locale) : addSlide(mockSlide)"
                    >Add Slide</button>
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
        <div class="buttons-container">
            <button mat-raised-button color="primary" [disabled]="this.currentTab ? !isFormDisabledForEdit() : !isFormDisabled()" (click)="onSubmit()">Save</button>
            <button mat-raised-button (click)="close()">Cancel</button>
        </div>
    </div>
  </div>
</div>
<ng-template #error>
  <div class="error-preview autofill-preview">
    <strong>Use identifiers in the content below for autofill. Available identifiers:</strong>
    <ul>
      <li><strong>{{ '{' }}roundName{{ '}' }}</strong>: Name of the round</li>
      <li><strong>{{ '{' }}roundId{{ '}' }}</strong>: Unique ID of the round</li>
      <li><strong>{{ '{' }}totalWinningCash{{ '}' }}</strong>: Total prize amount in euros</li>
      <li><strong>{{ '{' }}totalQuestionAmount{{ '}' }}</strong>: Total number of questions in the round</li>
      <li><strong>{{ '{' }}totalWinningUsers{{ '}' }}</strong>: Total number of winners</li>
      <li><strong>{{ '{' }}answeredCorrectly{{ '}' }}</strong>: Number of correct answers by each group</li>
      <li><strong>{{ '{' }}usersAmount{{ '}' }}</strong>: Number of users in each prize entry</li>
      <li><strong>{{ '{' }}reward{{ '}' }}</strong>: Prize amount for each user or total amount based on type</li>
    </ul>
    <p>Add these placeholders to automatically fill in specific information in the results tab.</p>
  </div>
</ng-template>
