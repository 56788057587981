import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Editor, Toolbar } from "ngx-editor";

@Component({
  selector: 'html-editor-translations',
  templateUrl: './html-editor-translations.component.html',
  styleUrls: ['./html-editor-translations.component.scss'],
})
export class HtmlEditorTranslationsComponent implements OnDestroy {

  @Input() editorFormControl: FormControl;

  @Input() maxContentLength: number;

  @Input() placeholder = 'Type here...'

  editor: Editor;

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
    ['link'],
  ];

  constructor() {
    this.editor = new Editor();
  }

  checkContentLength(content: string) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;
    const strippedContent = tempElement.textContent || tempElement.innerText || '';
    if (strippedContent.length > this.maxContentLength) {
      this.editorFormControl.setErrors({maxContentLength: true})
    } else {
      this.editorFormControl.setErrors(null)
    }
    return strippedContent.length > this.maxContentLength;
  }

  ngOnDestroy(): void {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}
