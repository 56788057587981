<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="close()">close</mat-icon>
        <h2 class="popup-header">Edit prize pop up</h2>
      </div>
    </div>
  </div>
  <div class="builder-body info-popup">
    <div class="builder-content">
      <mat-tab-group animationDuration="0ms" (selectedTabChange)="changeTabIndex($event)">
        <mat-tab
          *ngFor="let locale of locales"
          [label]="locale.country">
          <form [formGroup]="formGroup">
            <div [formGroupName]="locale.country" class="form-container">
              <div class="field-wrapper">
                <h4>Tab title</h4>
                <custom-input
                  formControlName="title"
                  placeholder="Enter tab title">
                </custom-input>
              </div>
              <div>
                <h4>Content</h4>
                <div class="buttons-container">
                  <button mat-stroked-button
                          color="primary"
                          (click)="updatePrizeAutomatically()" [disabled]="!prizes || isEmpty(prizes)">
                    Fill prize automatically
                  </button>
                </div>
                <html-editor-translations
                  [editorFormControl]="getContentControl(locale.country)"></html-editor-translations>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
      <div class="buttons-container">
        <button mat-raised-button color="primary" [disabled]="isFormDisabled()" (click)="onSubmit()">Save</button>
        <button mat-raised-button (click)="close()">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!(isLoaded$ | async)" class="disabled-overlay">
  <mat-spinner [diameter]="60" color="primary"></mat-spinner>
</div>
