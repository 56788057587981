<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="onClosePrizeWizard()">close</mat-icon>
        <h2 class="popup-header">Choose a round class</h2>
      </div>
      <div class="control-header">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!roundClassForm.valid"
          (click)="selectRoundType()">
          Next
        </button>
      </div>
    </div>
  </div>
  <div class="builder-body">
    <div class="builder-content">
      <div class="class-details">
        <form [formGroup]="roundClassForm">
          <!--      Category-->
          <div class="form-field-with-label" *ngIf="(roundClasses$ | async) as roundClasses">
            <mat-label>
              Class*
            </mat-label>

            <mat-form-field appearance="outline">
              <ng-container>
                <mat-select
                  placeholder="Choose the option from drop-down"
                  floatPlaceholder="never"
                  (selectionChange)="onClassChange($event)"
                  [formControlName]="'class'">
                  <mat-option *ngFor="let roundClass of roundClasses" [value]="roundClass.value">
                    {{roundClass.displayValue}}
                  </mat-option>
                </mat-select>
              </ng-container>
            </mat-form-field>
            <mat-error
              class="select-error"
              *ngIf="isShowError('class', 'required')">
              <i class="fas fa-exclamation-circle"></i>
              <span class="text">{{appData.MANDATORY_SELECT}}</span>
            </mat-error>

          </div>
          <div
            class="form-field-with-label"
            *ngIf="!(roundHelperService.isMultiEventRound$ | async) && (roundHelperService.roundClass$ | async) && (sportEvents$ | async) as sportEvents">
            <mat-label>Event*</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                placeholder="Select an event"
                [matAutocomplete]="eventAuto"
                [formControlName]="'sportEventId'">
              <mat-icon
                class="event-suffix"

                *ngIf="!roundClassForm.value['sportEventId']"
                matSuffix>
                manage_search
              </mat-icon>
              <mat-autocomplete
                (optionSelected)="roundHelperService.singleRoundEvent = $event.option.value"
                [displayWith]="getOptionTextEvent"
                #eventAuto="matAutocomplete">
                <mat-option
                  class="event-option"
                  *ngFor="let event of sportEvents|filter: roundClassForm.get('sportEventId').value:'name'"
                  [value]="event"
                  [id]="event.id">
                  <div class="mat-option-item event">
                    <div class="text-item-part">
                      <div class="name">{{event.name}}</div>
                      <status-chip [customColor]="'#D5EE92'" *ngIf="!!event.externalId">
                        {{appData.EXTERNAL}}
                      </status-chip>
                      <div class="date body-small-regular" *ngIf="event.startDate">
                        {{event.startDate | datesFormatPipe: dateFormats.DD_MMMM_YYYY_hh_mmtz}}
                      </div>
                      <ng-container *ngIf="event.closeDate">
                        -
                        <div class="date body-small-regular">
                          {{event.closeDate | datesFormatPipe: dateFormats.DD_MMMM_YYYY_hh_mmtz}}
                        </div>
                      </ng-container>
                    </div>

                    <div class="icons-part">
                      <mat-icon
                        matRipple
                        [matRippleRadius]="10"
                        [matRippleUnbounded]="true"
                        [matRippleCentered]="true"
                        matSuffix class="material-icons material-icons-outlined"
                        (click)="editEvent($event, event)">edit
                      </mat-icon>
                      <mat-icon
                        matRipple
                        [matRippleRadius]="10"
                        [matRippleUnbounded]="true"
                        [matRippleCentered]="true"
                        matSuffix class="material-icons material-icons-outlined"
                        (click)="deleteEvent($event, event)">delete_outline
                      </mat-icon>
                    </div>
                  </div>

                </mat-option>
                <mat-option class="create-new-item" (click)="openEventModel()">
                  <mat-icon class="material-icons">add_circle_outline</mat-icon>
                  Create a new event
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-error *ngIf="isShowError('sportEventId', 'required') || isShowError('sportEventId', 'invalidSportEventId')">
              <i class="fas fa-exclamation-circle"></i>
              <span>{{appData.MANDATORY_SELECT}}</span>
            </mat-error>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
