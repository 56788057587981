import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HealthCheckResultsModel } from "../../../models/HealthCheckResultsModel";
import { HealthResultEnum } from "../../../Enums/HealthResultEnum";
import { AnalyticsService } from "../../../../core/services/analytics.service";
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { SnackBarService } from "../../../../core/services/snack-bar.service";

@Component({
  selector: 'health-check-result-modal',
  templateUrl: './health-check-result-modal.component.html',
  styleUrls: ['./health-check-result-modal.component.scss']
})
export class HealthCheckResultModalComponent {

  healthCheckResults$: Observable<HealthCheckResultsModel[]>;

  isLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<HealthCheckResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private analyticsService: AnalyticsService,
    private snackBarService: SnackBarService,

  ) {
    this.healthCheckResults$ = this.analyticsService.getHealthCheckResult(this.data).pipe(
      map((healthCheckResults) => {
        this.isLoaded$.next(true);
        return healthCheckResults;
      }),
      catchError((error) => {
        this.snackBarService.showSnackBar(error.error.message, true);
        this.isLoaded$.next(true);
        this.dialogRef.close();
        return throwError(error);
      })
    );
  }

  onClosePrizeWizard() {
    this.dialogRef.close();
  }

  getColor (status: HealthResultEnum) {
    switch (status){
      case HealthResultEnum.error:
        return '#E04141';
      case HealthResultEnum.ok:
        return '#00C566';
      default:
        return '#424242';
    }
  }
}
