import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import { QuestionTypesEnum } from 'src/app/common/Enums/QuestionTypesEnum';
import { RoundHelperService } from '../../round-helper.service';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  filter,
  firstValueFrom,
  lastValueFrom,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
  throwError,
  distinctUntilChanged
} from 'rxjs';
import { FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import {
  MAX_LENGTH_ANSWERS_GRID,
  MAX_LENGTH_ANSWERS_NOT_OPTION,
  MAX_LENGTH_ANSWERS_OPTION,
  PredictionQuestionFormSectionService,
  requiredListGripAnswersLength,
  requiredListsAnswersLength,
  requiredOptionAnswersLength
} from '../../prediction-question-section-form.service';
import { QuestionService } from 'src/app/core/services/question.service';
import { QuestionTypeService } from './question-type.service';
import { AnswerGroupsService } from '../../answer-groups.service';
import { CreateAnswerGroupModalService } from '../../create-answer-group-modal/create-answer-group-modal.service';
import { RoundStatusEnum } from 'src/app/common/Enums/RoundStatusEnum';
import { DialogService } from 'src/app/common/components/_base-component/dialog/dialog.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { RoundClassEnum } from "@enums/RoundClassEnum";
import { take } from "rxjs/operators";
import { RoundProcessingService } from "@services/round-processing.service";
import { RounWizzardAnswerService } from "../../round-wizard-answer.service";
import { isEqual } from "lodash";
import { CreateQuestionDetailModel } from "@models/CreateQuestionDetailsModel";

@Component({
  selector: 'prediction-question',
  templateUrl: './prediction-question.component.html',
  styleUrls: ['./prediction-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PredictionQuestionComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  disabled$ = new BehaviorSubject(true);

  answerGroupSubscription: Subscription;

  @Output()
  onUp = new EventEmitter<void>();

  @Output()
  onDown = new EventEmitter<void>();

  @Output()
  onAddQuestionForm = new EventEmitter<void>()

  @Output()
  onRemoveQuestionForm = new EventEmitter<void>()

  @Output()
  isMainChanged = new EventEmitter<number>()

  @Input()
  set isRemoveDisabled(value) {
    this.isRemoveDisabled$.next(value)
  }

  @Input() predictionNumber: number;

  @Input() questionIndex: number;

  questionType$ = new BehaviorSubject<QuestionTypesEnum>(QuestionTypesEnum.SCORE);

  displayAnswers$ = this.questionType$.pipe(
    map(type => this.questionSectionFormService.isQuestionWithAnswerGroup(type))
  );

  displatGroupOne$ = this.displayAnswers$;

  isListsQuestion$ = this.questionType$.pipe(
    map(type => type === QuestionTypesEnum.LISTS)
  )

  maxAnswerLength$ = this.questionType$.pipe(
    map(questionType => {
      return questionType === QuestionTypesEnum.OPTIONS ?
        MAX_LENGTH_ANSWERS_OPTION :
        questionType === QuestionTypesEnum.GRID ? MAX_LENGTH_ANSWERS_GRID : MAX_LENGTH_ANSWERS_NOT_OPTION;
    })
  );

  answerGroupId$ = new BehaviorSubject(undefined);

  answerGroupId2$ = new BehaviorSubject(undefined);

  // this variable need for filtering answers groups by answes length and block using two same group in one question for LISTS
  // answer group list filtered by question type
  answerGroupListFilteredByQuestionType$ = combineLatest([
    this.answerGroupsService.answerGroupList$,
    this.questionType$
  ]).pipe(
    map(([answerGroupList, questionType]) => {
      switch(questionType) {
        case QuestionTypesEnum.GRID:
        case QuestionTypesEnum.LIST:
          return answerGroupList.filter(answerGroup => answerGroup.answers.length >= requiredListGripAnswersLength);
        case QuestionTypesEnum.OPTIONS:
          return answerGroupList.filter(answerGroup => answerGroup.answers.length === requiredOptionAnswersLength);
        case QuestionTypesEnum.LISTS:
          return answerGroupList.filter(answerGroup => answerGroup.answers.length >= requiredListsAnswersLength);
        default:
          return answerGroupList;
      }
    })
  );

  // answer group list for first group with out selected second group if second is existing. Second group is existing for type question LISTS
  answerGroupList$ = combineLatest([
    this.answerGroupListFilteredByQuestionType$,
    this.answerGroupId2$.asObservable()
  ]).pipe(
    map(([answerGroupList, answerGroupId2]) =>
      answerGroupList.filter(answerGroup => answerGroup.id != answerGroupId2))
  );

  // answer group list for second group with out first selected group
  answerGroupList2$ = combineLatest([
    this.answerGroupListFilteredByQuestionType$,
    this.answerGroupId$.asObservable()
  ]).pipe(
    map(([answerGroupList, answerGroupId]) =>
      answerGroupList.filter(answerGroup => answerGroup.id != answerGroupId))
  );

  isRemoveDisabled$ = new BehaviorSubject(true);

  isAnswersDirty$ = new BehaviorSubject<boolean>(false);

  isAnswersDirty2st$ = new BehaviorSubject<boolean>(false);

  roundTypesEnum = QuestionTypesEnum;

  questionForm: FormGroup;

  currentQuestionType$ = new BehaviorSubject(QuestionTypesEnum.SCORE);

  currentQuestionExternalId$ = new BehaviorSubject(null);

  canNotAddOrRemove$ = this.roundHelperService.roundStatus$.pipe(
    map(roundStatus => !this.canAddOrRemoveQuestionForRound(roundStatus))
  );

  tooltipTextIcon = 'We recommend using a 24px x 24px image resolution. Max image size is 4MB';

  constructor(
    public roundHelperService: RoundHelperService,
    public questionTypeService: QuestionTypeService,
    private answerGroupsService: AnswerGroupsService,
    private questionService: QuestionService,
    private rootFormGroup: FormGroupDirective,
    private answerGroupService: AnswerGroupsService,
    private questionSectionFormService: PredictionQuestionFormSectionService,
    private createAnswerGroupModalService: CreateAnswerGroupModalService,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private cdr: ChangeDetectorRef,
    private roundProcessingService: RoundProcessingService,
    private rounWizzardAnswerService: RounWizzardAnswerService
  ) {
    this.answerGroupsService.needUpdateAnswerGroup$.pipe(
      takeUntil(this.unsubscribe$),
      filter(response => response),
      take(1)
    )
      .subscribe(() => {
        this.questionForm.markAsDirty();
        this.addGroupControlListener();
      })
  }

  isAnswersDirtyEvent(value: boolean) {
    this.isAnswersDirty$.next(value);
  }

  isAnswersDirty2stEvent(value: boolean) {
    this.isAnswersDirty2st$.next(value);
  }

  canAddOrRemoveQuestionForRound(roundStatus: RoundStatusEnum) {
    return !roundStatus ||
    roundStatus === RoundStatusEnum.DRAFT ||
    roundStatus === RoundStatusEnum.PENDING
  }

  up() {
    if (this.canAddOrRemoveQuestionForRound(this.roundHelperService.roundStatus)) {
      this.onUp.emit();
    }
  }

  down() {
    if (this.canAddOrRemoveQuestionForRound(this.roundHelperService.roundStatus)) {
      this.onDown.emit();
    }
  }

  addQuestionForm() {
    if (this.canAddOrRemoveQuestionForRound(this.roundHelperService.roundStatus)) {
      this.onAddQuestionForm.emit();
    }
  }

  removeQuestionForm() {
    if (this.canAddOrRemoveQuestionForRound(this.roundHelperService.roundStatus)) {
      this.onRemoveQuestionForm.emit();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.questionForm = this.rootFormGroup.control;
    if (this.questionSectionFormService
        .isQuestionWithAnswerGroup(this.questionForm.get('type').value)) {
      this.addGroupControlListener();
    }

    this.questionForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.questionForm.touched) {
          this.questionForm.parent.setErrors({'not_save': true});
          this.disabled$.next(false);
        }
      });


    this.roundProcessingService.questionFormStateChanged$.pipe(
      takeUntil(this.unsubscribe$),
      tap(() => {
        this.cdr.detectChanges();
        if(this.questionForm.valid && !this.questionForm.touched) {
          this.questionForm.markAsPristine();
          this.cdr.markForCheck();
        }
      })
    ).subscribe();

    this.questionType$.next(this.questionForm.get('type').value);

    this.currentQuestionType$.next(this.questionForm.get('type').value);

    this.answerGroupId$.next(this.questionForm.get('answerGroupId').value);

    this.answerGroupId2$.next(this.questionForm.get('answerGroupId2').value);

    this.questionForm.get('answerGroupId').valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(answerGroupId => this.answerGroupId$.next(answerGroupId));

    this.questionForm.get('answerGroupId2').valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(answerGroupId2 => this.answerGroupId2$.next(answerGroupId2));

    this.questionForm.get('sportEvent')?.valueChanges
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap(sportEvent => {
          this.currentQuestionExternalId$.next(sportEvent?.externalData?.eventId)
          const externalEvent = sportEvent.externalData?.eventId ? sportEvent: null;
          return this.rounWizzardAnswerService.isExternalAnswerNeed(this.questionForm.get('type').value) ?
            this.rounWizzardAnswerService.getAnswersList(externalEvent, true) :
            of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.questionForm.get('type')?.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((type) => {
          this.currentQuestionType$.next(type);

          if (this.questionForm.dirty) {
            if (this.questionForm.get('additional')) {
              this.questionForm.removeControl('additional');
            }
            this.questionSectionFormService.addTextQWithConditionToQuestionForm(
              this.questionForm,
              this.roundHelperService.isMultiEventRound)
            ;
            this.questionForm.addControl('additional', this.questionSectionFormService.buildAdditionnalFormByType(type));

            if (this.questionSectionFormService.isQuestionWithAnswerGroup(type)) {
              this.questionForm.get('answerGroupId').patchValue(undefined);
              this.questionForm.get('answerGroupId2').patchValue(undefined);
              this.addGroupControlListener();
            }
          }
          this.questionType$.next(type);
          const sportEventValue = this.questionForm.get('sportEvent')?.value;
          const externalEvent = sportEventValue?.externalData?.eventId ? sportEventValue : null;
          if (this.rounWizzardAnswerService.isExternalAnswerNeed(type) && externalEvent) {
            return this.rounWizzardAnswerService.getAnswersList(externalEvent, true);
          }
          return of(null)
        })
      )
      .subscribe();

    if (this.answerGroupId$.value || this.answerGroupId2$.value) {
      this.addGroupControlListener(true);
    }

  }

  mapAnswer(answers: any, groupId: number, groupName: string) {
    if (answers && !groupId) {
      return answers.map((item: any) => ({ ...item, groupName }));
    }
    return [];
  }

  addGroupControlListener(onUpdate = false) {
    if (this.answerGroupSubscription) {
      this.answerGroupSubscription.unsubscribe()
    }

    this.answerGroupSubscription = (
      this.questionForm.get('type').value === QuestionTypesEnum.LISTS ?
        combineLatest([
          this.questionForm.get('answerGroupId').valueChanges.pipe(
            startWith(this.questionForm.get('answerGroupId').value)
          ),
          this.questionForm.get('answerGroupId2').valueChanges.pipe(
            startWith(this.questionForm.get('answerGroupId2').value)
          )
        ]).pipe(
          filter(() => !onUpdate ? this.questionForm.dirty : true),
          switchMap(([groupId0, groupId1]) => {
            const additionalControls = this.questionForm.get('additional')['controls'];
            const isNotSubmittedAnswersId0 = additionalControls.answers1st.value.length && !groupId0;
            const isNotSubmittedAnswersId1 = additionalControls.answers2st.value.length && !groupId1;

            const notSubmittedArrayId0 = isNotSubmittedAnswersId0 ?
              this.mapAnswer(additionalControls.answers1st.value, groupId0, 'group_0') : [];

            const notSubmittedArrayId1 = isNotSubmittedAnswersId1 ?
              this.mapAnswer(additionalControls.answers2st.value, groupId1, 'group_1') : [];


            return groupId0 || groupId1 ? this.answerGroupService.answerGroupList$.pipe(
              map(groupList => ([
                ... groupId0 ?
                  groupList.find(item => item.id === groupId0).answers.map(answer => ({
                    ...answer,
                    groupName: 'group_0'
                  })) :
                  notSubmittedArrayId0,
                ... groupId1 ?
                  groupList.find(item => item.id === groupId1).answers.map(answer => ({
                    ...answer,
                    groupName: 'group_1'
                  })) :
                  notSubmittedArrayId1,
              ]))
            ) : of([])
          })
        ) :
      this.questionForm.get('answerGroupId').valueChanges.pipe(
        startWith(this.questionForm.get('answerGroupId').value),
        filter(() => this.questionForm.dirty),
        switchMap(groupId => {
          return groupId ? this.answerGroupService.answerGroupList$.pipe(
            map(groupList => groupList.find(item => item.id === groupId)?.answers || [])
          ) : of([])
        })
      )
    ).subscribe(answers => {
      this.questionSectionFormService.addAnswersToQuestionFromGroup(
          answers,
          this.questionForm.get('type').value,
          this.questionForm
        )
      this.answerGroupService.needUpdateAnswerGroup$.next(false);
    });
  }

  addAnswersToSaveRequest(question, form: FormGroup) {
    const rawData = form.getRawValue();
    switch(rawData.type) {
      case QuestionTypesEnum.LISTS:
        if (
          rawData.answerGroupId &&
          !this.questionForm.get('additional').get('answers1st').dirty
        ) {
          question['answerGroupId'] = rawData.answerGroupId;
          question['answers'] = [];
        } else {
          question['answers'] = rawData.additional.answers1st
            .map(answer => ({answerId: answer.id,  groupName: 'group_0'}));
        }
        if (
          rawData.answerGroupId2 &&
          !this.questionForm.get('additional').get('answers2st').dirty
        ) {
          question['answerGroupId2'] = rawData.answerGroupId2;
        } else {
          question['answers'] = [
            ...question['answers'],
            ...rawData.additional.answers2st
              .map(answer => ({answerId: answer.id,  groupName: 'group_1'})),
          ]
        }
        break;

      case QuestionTypesEnum.LIST:
      case QuestionTypesEnum.GRID:
      case QuestionTypesEnum.OPTIONS:
        if (
          rawData.answerGroupId &&
          !this.questionForm.get('additional').get('answers').dirty
        ) {
          question['answerGroupId'] = rawData.answerGroupId;
          question['answers'] = [];
        } else {
          question['answers'] = rawData.additional.answers.map(answer => ({answerId: answer.id}));
        }
        break;
    }
    return question;
  }

  async save() {
    if (this.questionForm.valid) {
      const rawData = this.questionForm.getRawValue();
      const question = {
        text: rawData.text,
        type: rawData.type,
        roundClass: this.roundHelperService.isMultiEventRound ? RoundClassEnum.Multi : RoundClassEnum.Single,
        imageUrl: rawData.imageUrl ?? null,
        isMain: !!rawData.isMain
      };
      if (rawData.sportEvent) {
        question['sportEventId'] = rawData.sportEvent.id
      }

      this.addAnswersToSaveRequest(question, this.questionForm);

      if (rawData.type === QuestionTypesEnum.RANGE) {
        question['attributes'] = rawData.additional;
      }

      if (rawData.type === QuestionTypesEnum.SCORE_PLUS) {
        question['maxScoreValue'] = `${rawData.additional.maxScoreValue}+`;
      }

      const result =
        rawData.id ?
          await firstValueFrom(this.questionService.updateQuestion(rawData.id, question as CreateQuestionDetailModel)) :
          await firstValueFrom(this.questionService.createNewQuestion(question as CreateQuestionDetailModel));
      if (!this.questionForm.get('id').value) {
        this.questionForm.controls['id'].patchValue(result.id);
      }

      this.questionForm.markAsPristine();
      this.questionForm.markAsUntouched();
      this.cdr.markForCheck();

    } else {
      this.questionForm.markAllAsTouched();
      this.updateTreeValidity(this.questionForm.controls['text'] as FormGroup);
      if (this.questionForm.controls['additional']) {
        this.updateTreeValidity(this.questionForm.controls['additional'] as FormGroup);
      }
      if (this.questionForm.controls['sportEvent']) {
        this.updateTreeValidity(this.questionForm.controls['sportEvent'] as FormGroup);
      }
    }
  }

  async createNewFromAnswers(key, groupIdKey) {
    const answers = this.questionForm?.get('additional')?.get(key)?.value || [];
    const data = await this.createAnswerGroupModalService.open({
      questionType: this.questionForm.get('type').value,
      answers,
      event: this.currentQuestionExternalId$.value
    });
    if (data?.groupId) {
      this.questionForm.get(groupIdKey).patchValue(data.groupId);
      this.questionForm.markAsDirty();
      if (key === 'answers' || key === 'answers1st') {
        this.isAnswersDirty$.next(false);
      } else {
        this.isAnswersDirty2st$.next(false);
      }
    }
  }

  async editSelectedFromAnswers(key, groupIdKey) {
    const answers = this.questionForm?.get('additional')?.get(key)?.value || [];
    const groupId = this.questionForm.get(groupIdKey).value;
    const data = await this.createAnswerGroupModalService.open({
      questionType: this.questionForm.get('type').value,
      answers,
      group: this.answerGroupService.answerGroupList.find(group => group.id === groupId),
      event: this.currentQuestionExternalId$.value
    });
    if (data?.groupId) {
      this.questionForm.get(groupIdKey).patchValue(data.groupId);
      if (key === 'answers' || key === 'answers1st') {
        this.isAnswersDirty$.next(false);
      } else {
        this.isAnswersDirty2st$.next(false);
      }
    }
  }

  updateTreeValidity(group: FormGroup | FormArray | FormControl): void {
    if (group instanceof FormGroup) {
      Object.keys(group.controls).forEach((key: string) => {
        const abstractControl = group.controls[key];
        if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
          this.updateTreeValidity(abstractControl);
        } else {
          abstractControl.patchValue(abstractControl.value);
          abstractControl.markAsTouched();
        }
      });
    } else if (group instanceof FormArray) {
      group.controls.forEach((abstractControl) => {
        if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
          this.updateTreeValidity(abstractControl);
        } else {
          abstractControl.patchValue(abstractControl.value);
          abstractControl.markAsTouched();
        }
      });
    } else {
      group.patchValue(group.value);
    }
  }

  async editGroup(group) {
    //eslint-disable-next-line
    const data = await this.createAnswerGroupModalService.open({
      questionType: this.questionForm.get('type').value,
      group,
      event: this.currentQuestionExternalId$.value
    });
  }

  async deleteGroup(group, groupIdKey) {
    await lastValueFrom(
      this.dialogService.openDeleteConfirmationPopup(`${group.name} answers group`)
      .pipe(
        switchMap((response) => {
          if (response) {
            return this.answerGroupsService.deleteAnswerGroup(group.id)
              .pipe(
                switchMap(() => this.answerGroupsService.getAllAnswerGroups()),
                tap(() => {
                  if (this.questionForm.get(groupIdKey).value == group.id) {
                    this.questionForm.get(groupIdKey).patchValue(undefined, {onlySelf: true});
                    this.questionForm.markAsDirty();
                  }
                })
              )
          } else {
            return of(null);
          }
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error);
        })
      )
    );
  }

  async createGroup(groupIdKey) {
    const data = await this.createAnswerGroupModalService.open({
      questionType: this.questionForm.get('type').value,
      event: this.currentQuestionExternalId$.value
    });
    if (data?.groupId) {
      this.questionForm.get(groupIdKey).patchValue(data.groupId, {onlySelf: true});
      this.questionForm.markAsDirty();
    }
  }

  getExternalEvent () {
    const questionEvent = this.questionForm.get('sportEvent')?.value;

    if (questionEvent) {
      return questionEvent.externalData?.eventId ? questionEvent : null;
    }
    const singleRoundEvent = this.roundHelperService.singleRoundEvent;

    return singleRoundEvent?.externalData?.eventId ? singleRoundEvent : null;
  }

}
