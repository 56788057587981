import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformTwoFaError'
})
export class TransformTwoFaErrorPipe implements PipeTransform {
  transform(error: string): string {
    if (!error) return '';
    return error[0].toUpperCase() + error.slice(1);
  }
}
