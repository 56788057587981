<div class="two-fa-popup" *ngIf="showPopup">
  <h2>Enable 2FA</h2>
  <p>Download and install an authenticator app (e.g. Google Authenticator, Aegis, etc.).</p>
  <div class="qr-code-container">
    <img [src]="qrCodeSrc" *ngIf="qrCodeSrc" alt="QR">
    <div class="secret-container">
      <button mat-raised-button color="primary" (click)="revealSecret()">{{ showSecret ? 'Hide secret' : 'Reveal secret'}}</button>
      <p *ngIf="showSecret"> {{ secret }}</p>
    </div>
  </div>
  <div class="form-field-with-label">
    <mat-label>Generated 6-digit code:</mat-label>
    <mat-form-field>
      <input
        matInput
        type="text"
        name="code"
        placeholder="6-digit code"
        [(ngModel)]="code"/>
    </mat-form-field>
    <mat-error *ngIf="twoFaAuthService.error$ | async as error">
      <span> {{ error | transformTwoFaError }} </span>
    </mat-error>
  </div>
  <p>Your code changes every 30 seconds, make sure to enter the most recent one.</p>
  <div class="buttons-wrapper">
    <button mat-stroked-button color="primary" class="cancel-button" (click)="cancelTwoFa()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!isConfirmEnabled()" (click)="confirmEnableTwoFA()" >Confirm</button>
  </div>
</div>
