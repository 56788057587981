import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface Options {
  value: any,
  label: string
}

@Component({
  selector: 'select-filter',
  templateUrl: 'select-filter.component.html',
  styleUrls: ['select-filter.component.scss']
})
export class SelectFilterComponent {
  @Input() options: Array<Options>

  @Input() title: string;

  @Input()
  set selected(value) {
    // Checking  needed to clear the array of selected values if the selected input parameter is not defined
     if (value) {
      // In the template the component workw with array of selected values.
      // But in the interface selected can be single value or array,
      // there is need to support single or multiple selected mode
      this.selectedValues$.next(
        Array.isArray(value) ? value : [value]
      );
    } else {
      this.selectedValues$.next([]);
    }
  }

  @Input()
  cleanable = false;

  @Output()
  selectFilter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter();

  selectedValues$ = new BehaviorSubject([]);

  selectValue(value) {
    this.selectFilter.emit(value);
  }

  clear() {
    this.clearFilter.emit();
  }
}
