import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { combineLatest, lastValueFrom, map, of, tap } from 'rxjs';
import { RoundStatusEnum } from 'src/app/common/Enums/RoundStatusEnum';
import { TooltipPositionEnum } from "../../../common/Enums/TooltipPositionEnum";
import {
  CorrectAnswerPredictionServiceService
} from "../../wizzard-sections/correct-answers-prediction-section/correct-answer-prediction-service.service";
import { RoundHelperService } from "../../round-helper.service";
import { PredictionPrizeSectionService } from "../../wizzard-sections/prediction-prize-section/prize-section.service";
import { RoundProcessingService } from "../../../core/services/round-processing.service";
import { CorrectAnswerHelperService } from "../../../core/services/streak/correct-answer-helper.service";
import { filter } from "rxjs/operators";

@Component({
  selector: 'round-wizzard-header',
  templateUrl: './round-wizzard-header.component.html',
  styleUrls: ['./round-wizzard-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundWizzardHeaderComponent {

  @Output() saveDraft = new EventEmitter();

  @Output() previewAndPublish = new EventEmitter();

  @Output() submitCorrectAnswers = new EventEmitter();

  @Output() restartResultProcessing = new EventEmitter();

  @Output() editRoundImage = new EventEmitter();

  @Output() backButtonClicked = new EventEmitter();

  protected readonly RoundStatusEnum = RoundStatusEnum;


  TooltipPositionEnum = TooltipPositionEnum;

  displayPublishButton$ = this.roundHelperService.roundStatus$.pipe(
    map(roundStatus => !(roundStatus === RoundStatusEnum.CLOSED ||
      roundStatus === RoundStatusEnum.COMPLETED ||
      roundStatus === RoundStatusEnum.RESULT_PROCESSING))
  );

  displayDraftButton$ = this.roundHelperService.roundStatus$.pipe(
    map(roundStatus => !roundStatus || roundStatus === RoundStatusEnum.DRAFT),
    tap((isDraft) => this.roundProcessingService.isDraft$.next(isDraft))
  );

  displaySubmitButton$ = combineLatest([
    this.roundHelperService.roundStatus$,
    this.roundHelperService.roundType$
  ]).pipe(
    map(([roundStatus, roundType]) => !roundType?.toLowerCase()?.includes('streak') &&
      (roundStatus === RoundStatusEnum.CLOSED ||
      roundStatus === RoundStatusEnum.COMPLETED ||
      roundStatus === RoundStatusEnum.RESULT_PROCESSING))
  );

  isFinishedRound$ = combineLatest([
    this.roundHelperService.roundStatus$,
    this.roundHelperService.roundType$
  ]).pipe(
    map(([roundStatus, roundType]) => (
      !roundType?.toLowerCase()?.includes('streak') &&
      roundStatus === RoundStatusEnum.CLOSED
    ))
  )

  constructor(
    public correctAnswerPredictionServiceService: CorrectAnswerPredictionServiceService,
    public predictionPrizeSectionService: PredictionPrizeSectionService,
    public roundHelperService: RoundHelperService,
    private roundProcessingService: RoundProcessingService,
    private correctAnswerHelperService: CorrectAnswerHelperService
  ) {
  }

  isSubmitAnswersDisabled() {
    return this.roundHelperService.isMultiEventRound ?
      combineLatest([
        this.correctAnswerHelperService.isCorrectAnswerFormValid$,
        this.predictionPrizeSectionService.isPrizeValid$,
        of(this.correctAnswerHelperService.correctAnswersForm?.dirty),
        of(this.predictionPrizeSectionService.prizes?.dirty)
      ]).pipe(
        map(response => {
          const [correctAnswers, prize, ...dirtyFlags] = response;
          return this.checkValidity([correctAnswers, prize], dirtyFlags);
        })
      ) :
      combineLatest([
        this.correctAnswerHelperService.isCorrectAnswerFormValid$,
        this.correctAnswerPredictionServiceService.isEventFormValid$,
        this.predictionPrizeSectionService.isPrizeValid$,
        of(this.correctAnswerHelperService.correctAnswersForm?.dirty),
        of(this.correctAnswerPredictionServiceService.correctEventForm?.dirty),
        of(this.predictionPrizeSectionService.prizes?.dirty)
      ]).pipe(
        map(response => {
          const [correctAnswers, correctEvent, prize, ...dirtyFlags] = response;
          return this.checkValidity([correctAnswers, correctEvent, prize], dirtyFlags);
        })
      )
  }


  checkValidity(valueArray, dirtyFlagArray) {
    return dirtyFlagArray.every(item => !item) ? true : !valueArray.every(item => item);
  }

  displayUpdateImageButton() {
    return this.roundHelperService.isMultiEventRound;
  }

  onBackButton() {
    this.backButtonClicked.emit();
  }
}
