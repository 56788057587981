import { Injectable } from "@angular/core";
import { AbstractControl, ValidatorFn } from "@angular/forms";

@Injectable({providedIn: 'root'})

export class SportEventIdValidator {
  /**
   * Check that event was selected from the list, not just entered some text
   */
  public isSelectedEvent(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value !== null && typeof value !== 'object') {
        return { invalidSportEventId: true };
      }
      return null;
    };
  }
}
