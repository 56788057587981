import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, catchError, of, shareReplay, Subject, switchMap, take, tap, throwError } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogService } from "../../_base-component/dialog/dialog.service";
import { SnackBarService } from "@services/snack-bar.service";
import { InfoPopupCmsService } from "@services/info-popup-cms.service";
import { filter, takeUntil } from "rxjs/operators";
import { BreakdownDataItem, InfoPopupBreakdownData, InfoPopupTabsModel } from "@models/InfoPopupTabsModel";
import { APP_DATA } from "@app/general.app.config";
import { RoundService } from "@services/round.service";
import { isNull } from "lodash";
import { RoundPrizeAssigmentEnum } from "@enums/RoundPrizeAssigmentEnum";
import { InfoPopupTabEnum } from "@enums/InfoPopupTabEnum";

@Component({
  selector: 'info-popup-form-modal',
  templateUrl: './info-popup-form-modal.component.html',
  styleUrls: ['./info-popup-form-modal.component.scss']
})
export class InfoPopupFormModalComponent implements OnInit, OnDestroy {
  @ViewChild('preview') container!: ElementRef;

  locales = [];

  formGroup: FormGroup;

  selectedContentTypeValue$ = new BehaviorSubject(1);

  selectedTabIndex = 0;

  currentTab: InfoPopupTabsModel;

  jsonForm: FormGroup;

  slidesForm: FormGroup;

  mockSlide = {title: '', description: '', imageUrl: ''};

  tooltipTextIcon = 'We recommend using a 128px x 128px image resolution. Max image size is 4MB';

  maxLengthForSliderContent = 150;


  linkRegex = /<a\s+[^>]*href\s*=\s*['"]([^'"]*)['"][^>]*>(.*?)<\/a>/gi;

  linkRegexBroken = /<a\s+href=\\"([^\\]*)\\"\s+title=\\"([^\\]*)\\"\s+target=\\"([^\\]*)\\">([^<]*)<\/a>/gi;
  rounds= []

  previewDisplayed$ = new BehaviorSubject(null)

  protected readonly appData = APP_DATA;

  private unsubscribe$ = new Subject<void>();

  get autofillFormGroup(): FormGroup {
    return this.formGroup.get('Default').get('autofillGroup') as FormGroup
  }
  constructor(
    public dialogRef: MatDialogRef<InfoPopupFormModalComponent>,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private infoPopupCmsService: InfoPopupCmsService,
    private roundService: RoundService,
    private renderer: Renderer2
  ) {
    this.formGroup = this.fb.group({});
    this.jsonForm = this.fb.group({});
    this.slidesForm = this.fb.group({});
    const countyNames = this.data.locales.map(item => item.country);
    this.locales = countyNames.length ? ['Default', ...countyNames] : ['Default'];
    this.currentTab = this.data.tab;

    this.fetchRoundBreakdownList()
  }

  ngOnInit(): void {
    this.createForms(this.locales);
    if (this.currentTab) {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm() {
    this.formGroup.get('Default').patchValue({
      title: this.currentTab.title.title,
      name: this.currentTab.name.name,
      description: this.currentTab.description?.description ?? '',
    });

    if (this.currentTab.content.content.content) {
      this.formGroup.get('Default').patchValue({
        content: this.currentTab.content.content.content
      });
      this.addItem({title: '', content: null});
    } else if(this.currentTab.content.content.items) {
      this.selectedContentTypeValue$.next(2);
      this.currentTab.content.content.items.forEach(item => {
        this.addItemForLocale({...item}, 'Default')
      })
    } else {
      this.selectedContentTypeValue$.next(3);
      this.currentTab.content.content.slides.forEach(item => {
        this.addSlideForLocale({...this.mockSlide, ...item}, 'Default')
      })
    }
    this.data.locales.forEach((locale) => {
      const targetLocalizationName = this.currentTab.name.localizations.find(item => item.locale.i18n === locale.i18n);
      const targetLocalizationTitle = this.currentTab.title.localizations.find(item => item.locale.i18n === locale.i18n);

      this.formGroup.get(locale.country).get('name').patchValue(targetLocalizationName.text);
      this.formGroup.get(locale.country).get('title').patchValue(targetLocalizationTitle.text);
      if (this.currentTab.content.content.content) {
        const targetLocalizationContent = this.currentTab.content.localizations.find(item => item.locale.i18n === locale.i18n);
        const contentToParse = targetLocalizationContent.text;
        const parsedContent = JSON.parse(contentToParse);
        this.formGroup.get(locale.country).patchValue({
          content: parsedContent.content
        });
      } else if(this.currentTab.content.content.items) {
        const targetLocalizationContent = this.currentTab.content.localizations.find(item => item.locale.i18n === locale.i18n);
        const parsedData = JSON.parse(targetLocalizationContent.text);
        parsedData.forEach(item => {
          this.addItemForLocale({...item}, locale.country)
        })
      } else {
        const targetLocalizationContent = this.currentTab.content.localizations.find(item => item.locale.i18n === locale.i18n);
        const parsedData = JSON.parse(targetLocalizationContent.text);
        parsedData.slides.forEach(item => {
          this.addSlideForLocale({...this.mockSlide, ...item}, locale.country)
        })
      }
    })


    this.formGroup.get('Default').get('autofillGroup').get('allowAutofill')?.valueChanges.subscribe((value) => {
      const roundIdControl = (this.formGroup.get('Default').get('autofillGroup') as FormGroup).get('roundId');
      if (value) {
        roundIdControl?.enable();
      } else {
        roundIdControl?.disable();
      }
    });
  }


  removeItem(index: number, locale: string): void {
    this.jsonForm.get(locale).markAsDirty();

    (this.jsonForm.get(locale).get('items') as FormArray).removeAt(index)
  }

  removeSlide(index: number, locale: string): void {
    this.slidesForm.get(locale).markAsDirty();

    (this.slidesForm.get(locale).get('slides') as FormArray).removeAt(index)
  }


  createForms(locales: string[]): void {
    locales.forEach(locale => {
      this.jsonForm.addControl(locale, this.createJsonForm());
      this.formGroup.addControl(locale, this.createForm(locale));
      this.slidesForm.addControl(locale, this.createSlidesForm());
    });

    if (!this.currentTab) {
      this.addItem({title: '', content: null});
    }

    if (!this.currentTab) {
      this.addSlide(this.mockSlide);
    }
  }

  createSlidesForm(): FormGroup {
    return this.fb.group({
      slides: this.fb.array([])
    });
  }

  createJsonForm(): FormGroup {
    return this.fb.group({
      items: this.fb.array([])
    });
  }


  createForm(locale: string): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      title: ['', Validators.required],
      content: ['', Validators.required],
      ...(locale === 'Default' && {
        description: [''],
        autofillGroup: this.fb.group({
        allowAutofill: [false],
        roundId: [{ value: '', disabled: true }]
      })})
    });
  }

  onSubmit(): void {
    const formValue = {...this.formGroup.get('Default').value}
    if(formValue.description.trim().length === 0) {
      formValue.description = null
    }
    const defaultValue = {
      ...formValue,
      type: InfoPopupTabEnum.BASE,
      title: this.selectedContentTypeValue$.value === 3 ? this.formGroup.get('Default').get('name').value : this.formGroup.get('Default').get('title').value,
      content: this.createDataDefault('Default'),
      order: this.currentTab ? this.currentTab.order : 1
    };

    const targetRequest$ = this.currentTab ? this.infoPopupCmsService.updateInfoPopupTab(defaultValue, this.currentTab.id)
      : this.infoPopupCmsService.addInfoPopupTab(defaultValue);

    const request$ = targetRequest$.pipe(
        takeUntil(this.unsubscribe$),
        switchMap(data => {
          const localizationResult = this.getLocalizationBody(defaultValue, this.currentTab ? this.currentTab : data);
          return this.infoPopupCmsService.localizeTabs(localizationResult);
        }),
        tap(() => this.infoPopupCmsService.needUpdateContent$.next(true)),
        tap(data => {
          this.snackBarService.showSnackBar(`The tab was ${this.currentTab ? 'edited' : 'created'} successfully!`)
          this.currentTab = data;
          this.dialog.closeAll();
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error, true);
          return throwError(error);
        })
      );

    if (!this.checkIfFormsValid()) {
      this.dialogService.open(
        {
          dialogContent: 'Some data is not updated for other locales. Click “Save” to use the info from default locale for other locales where the data is missing',
          labelOk: 'Save',
          labelNo: 'Close'
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
        switchMap(data => {
          if (data) {
            return request$;
          } else {
            return of(true);
          }
        })
      ).subscribe()
    } else {
      request$.subscribe();
    }
  }

  cleanPastedContent(html) {
    html = html.replace(/\\&quot;/g, '');
    html = html.replace(/\\"/g, '"');
    return html;
  }

  cleanUnsafeLinks(htmlString: string) {
    if(htmlString) {
      const resultStepOne =  htmlString.replace(this.linkRegex, (match) => {
        return this.cleanPastedContent(match);
      });
      return resultStepOne.replace(this.linkRegexBroken, (match) => {
        return this.cleanPastedContent(match);
      });
    }
  }


  getLocalizationBody(defaultValue: any, data: any) {
    let result = [];
    this.data.locales.forEach((locale) => {
      const value = {
        ...this.formGroup.get(locale.country).value,
        content: this.createDataDefault(locale.country),
      };

      let contentDataAccordion = [];
      let contentDataHTML = '';
      let contentDataSlider = [];


      if (this.selectedContentTypeValue$.value === 2) {
        contentDataAccordion = (this.jsonForm.get(locale.country).get('items') as FormArray).value.map((item, index) =>{
          const targetContent = item.content ? this.cleanUnsafeLinks(item.content) : defaultValue.content.items[index].content;
          return {
            title: item.title ? item.title : defaultValue.content.items[index].title,
            content: targetContent,
          }
        });
      } else if (this.selectedContentTypeValue$.value === 1){
        contentDataHTML = value.content.content ? this.cleanUnsafeLinks(value.content.content) : defaultValue.content.content;
      } else {
        contentDataSlider = (this.slidesForm.get(locale.country).get('slides') as FormArray).value.map((slide, index) =>{
          return {
            title: slide.title ? slide.title : defaultValue.content.slides[index].title,
            description: slide.description ? this.cleanUnsafeLinks(slide.description) : defaultValue.content.slides[index].description,
            imageUrl: slide.imageUrl ? slide.imageUrl : defaultValue.content.slides[index].imageUrl,
          }
        });
      }

      const targetContentDataAccordion = this.cleanUnsafeLinks(JSON.stringify(contentDataAccordion));

      const targetContentDataSlider = JSON.stringify({slides: contentDataSlider});

      const htmlString = JSON.stringify({content: contentDataHTML});

      const targetContentDataHTML = this.cleanUnsafeLinks('{' + htmlString + '}').slice(1, -1);


      const targetLocalizationContent = data.content.localizations.find(item => item.locale.i18n === locale.i18n);



      const content = {
        text: this.selectedContentTypeValue$.value === 1 ? targetContentDataHTML : (this.selectedContentTypeValue$.value === 2 ? targetContentDataAccordion : targetContentDataSlider),
        id: targetLocalizationContent.id
      }

      const targetLocalizationName = data.name.localizations.find(item => item.locale.i18n === locale.i18n);

      const name = {
        text: value.name ? value.name : defaultValue.name,
        id: targetLocalizationName.id
      }

      const targetLocalizationTitle = data.title.localizations.find(item => item.locale.i18n === locale.i18n);


      const title = {
        text: value.title ? value.title : defaultValue.title,
        id: targetLocalizationTitle.id
      }

      result = [...result, content, name, title]
    });

    return result;
  }

  checkIfFormsValid() {
    let flag = false;
    if (!this.data.locales.length) {
      flag = this.formGroup.get('Default').get('title').valid && this.formGroup.get('Default').get('name').valid;
    } else {
      this.data.locales.forEach(locale => {
        if (!flag) {
          flag = this.formGroup.get(locale.country).get('title').valid && this.formGroup.get(locale.country).get('name').valid;
        }
      });
    }
    return this.selectedContentTypeValue$.value === 1 ? this.formGroup.valid : (this.selectedContentTypeValue$.value === 2 ? (flag && this.jsonForm.valid) : (flag && this.slidesForm.valid));
  }

  createDataDefault(locale: string) {
    if (this.selectedContentTypeValue$.value === 1) {
      return {
        content: this.formGroup.get(locale).value.content ? this.cleanUnsafeLinks(this.formGroup.get(locale).value.content) : null
      };
    } else if (this.selectedContentTypeValue$.value === 2){
      const contentDataAccordion = (this.jsonForm.get(locale).get('items') as FormArray).value.map((item) =>{
        return {
          title: item.title,
          content: item.content ? this.cleanUnsafeLinks(item.content) : null,
        }
      });
      return {items: contentDataAccordion};
    } else {
      const contentDataSlides = (this.slidesForm.get(locale).get('slides') as FormArray).value.map((slide) =>{
        return {...slide, description: slide.description ? this.cleanUnsafeLinks(slide.description) : ''};
      });
      return {slides: contentDataSlides};
    }
  }


  close() {
    const isDirty = (!this.formGroup.dirty && !this.jsonForm.dirty && !this.slidesForm.dirty)
    if (isDirty) {
      this.dialog.closeAll();
    } else {
      this.dialogService.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter(response => !!response),
        tap((response) => {
          if (response) {
            this.dialog.closeAll();
          }
        })
      ).subscribe();
    }
  }

  addSlide(value: { title: string, description: string, imageUrl: string }): void {
    this.locales.forEach(locale => {
      const group = this.fb.group({
        title: [value.title, [Validators.required, Validators.maxLength(50)]],
        description: [value.description, Validators.required],
        imageUrl: [value.imageUrl, Validators.required],
      });
      (this.slidesForm.get(locale).get('slides') as FormArray).push(group);
    });
  }

  addItem(value: { title: string, content: string }): void {
    this.locales.forEach(locale => {
      const group = this.fb.group({
        title: [value.title, Validators.required],
        content: [value.content, Validators.required]
      });
      (this.jsonForm.get(locale).get('items') as FormArray).push(group);
    });
  }
  addSlideForLocale(value: { title: string, description: string, imageUrl: string }, locale: string): void {
    const group = this.fb.group({
      title: [value.title, [Validators.required, Validators.maxLength(50)]],
      description: [value.description, Validators.required],
      imageUrl: [value.imageUrl, Validators.required],
    });
    (this.slidesForm.get(locale).get('slides') as FormArray).push(group);
  }

  addItemForLocale(value: { title: string, content: string }, locale: string): void {
    const group = this.fb.group({
      title: [value.title, Validators.required],
      content: [value.content, Validators.required]
    });
    (this.jsonForm.get(locale).get('items') as FormArray).push(group);
  }

  changeTabIndex(event: any) {
    this.selectedTabIndex = event.index;
  }

  onSelectContentType(event: any) {
    this.selectedContentTypeValue$.next(event.value);
    if (event.value === 3) {
      this.locales.forEach(locale => {
        this.formGroup.get(locale).get('title').removeValidators(Validators.required);
        this.formGroup.get(locale).get('title').patchValue(this.formGroup.get(locale).get('name').value);
        this.formGroup.get(locale).get('title').updateValueAndValidity();
      });
    } else {
      this.locales.forEach(locale => {
        this.formGroup.get(locale).get('title').addValidators(Validators.required);
      });
    }
  }

  getContentControl(locale: string) {
    return this.formGroup.get(locale).get('content') as FormControl;
  }

  get items(): FormArray {
    return this.jsonForm.get(this.locales[this.selectedTabIndex]).get('items') as FormArray;
  }

  get slides(): FormArray {
    return this.slidesForm.get(this.locales[this.selectedTabIndex]).get('slides') as FormArray;
  }

  isFormDisabled() {
    return this.selectedContentTypeValue$.value === 1
      ? this.formGroup.get('Default').valid
      : (this.selectedContentTypeValue$.value === 2 ? this.jsonForm.get('Default').valid : this.slidesForm.get('Default').valid) && this.formGroup.get('Default').get('name').valid && this.formGroup.get('Default').get('title').valid;
  }

  isAccordionValid() {
    return this.locales.find(locale => !(this.jsonForm.get(locale).get('items') as FormArray).controls.length)
  }

  isSlideValid() {
    return this.locales.find(locale => !(this.slidesForm.get(locale).get('slides') as FormArray).controls.length)
  }

  isFormDisabledForEdit() {
    return this.selectedContentTypeValue$.value === 1
      ? this.formGroup.get('Default').valid && this.formGroup.dirty
      : (this.formGroup.get('Default').get('name').valid && this.formGroup.get('Default').get('title').valid)
        && this.formGroup.dirty
      || ( this.selectedContentTypeValue$.value === 2 ? (this.jsonForm.get('Default').valid && this.jsonForm.dirty && !this.isAccordionValid())
        : (this.slidesForm.get('Default').valid && this.slidesForm.dirty && !this.isSlideValid()));
  }

  fetchRoundBreakdownList() {
    this.infoPopupCmsService.getRoundsBreakdownList()
      .pipe(
        takeUntil(this.unsubscribe$),
        shareReplay(1),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error, true);
          return throwError(error);
        }))
      .subscribe(
        (roundListResponse: any) => {
          this.rounds = roundListResponse
        })
  }

  displayPrizeModalOnLanding(id: number) {
    this.infoPopupCmsService.displayPrizeModalOnLanding(id)
      .pipe(
        take(1),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error, true);
          return throwError(error);
        }))
      .subscribe(()=> {
        const round = this.rounds.find(res => res.roundId === id);
        this.snackBarService.showSnackBar(`The results information popup for '${round.roundName}' is set to display on the landing page!`)
      });
  }

  previewClick(){
    const round = this.rounds.find(res =>  res.roundId === this.autofillFormGroup.get('roundId').value)

    const content = this.formGroup.get('Default').get('content').value
    const template =  this.previewContent(content, round)

    this.renderPreview(template)
  }

  renderPreview(template: string) {
    this.previewDisplayed$.next(!!template)

    if(template) {
      const cleanHtml = this.cleanUnsafeLinks(template)
      const newElement = this.renderer.createElement('div');
      this.renderer.setProperty(this.container.nativeElement, 'innerHTML',  '');
      this.renderer.setProperty(newElement, 'className', 'autofill-preview')
      this.renderer.setProperty(newElement, 'innerHTML', cleanHtml);
      this.renderer.appendChild(this.container.nativeElement, newElement);
    }

  }

  previewContent(template: string, data: InfoPopupBreakdownData) {
    if (template.match(/{([^{}]*)}/g)) {
      let finalContent = template;

      // Replace basic placeholders in the template
      if (template.includes("{roundName}") && data.roundName) {
        finalContent = finalContent.replace(/{roundName}/g, data.roundName);
      }
      if (template.includes("{roundId}") && data.roundId) {
        finalContent = finalContent.replace(/{roundId}/g, data.roundId.toString());
      }
      if (template.includes("{totalWinningCash}") && !isNull(data.totalWinningCash)) {
        finalContent = finalContent.replace(/{totalWinningCash}/g, data.totalWinningCash.toString());
      }
      if (template.includes("{totalQuestionAmount}") && !isNull(data.totalQuestionAmount)) {
        finalContent = finalContent.replace(/{totalQuestionAmount}/g, data.totalQuestionAmount.toString());
      }
      if (template.includes("{totalWinningUsers}") && !isNull(data.totalWinningUsers)) {
        finalContent = finalContent.replace(/{totalWinningUsers}/g, data.totalWinningUsers.toString());
      }

    const hasDefaultPrizeText = template.includes("{defaultPrizeText}");
    const hasSplitPrizeText = template.includes("{splitPrizeText}");

    let defaultTemplate = '';
    let splitTemplate = '';

      if (hasDefaultPrizeText) {
        // Extract the DEFAULT prize template section
        const defaultStart = template.indexOf("{defaultPrizeText}");
        const defaultEnd = template.indexOf("{defaultPrizeTextEnd}") + "{defaultPrizeTextEnd}".length;
        defaultTemplate = template.slice(defaultStart, defaultEnd)
          .replace("{defaultPrizeText}", "")
          .replace("{defaultPrizeTextEnd}", "");
        if(defaultTemplate.match(/<li>(.*?)<\/li>/g)) {
          defaultTemplate = defaultTemplate.match(/<li>(.*?)<\/li>/g)[0]
        }
        finalContent = finalContent.replace(/{defaultPrizeText}[\s\S]*?{defaultPrizeTextEnd}/, '') ;
      }

      if (hasSplitPrizeText) {
        // Extract the SPLIT prize template section
        const splitStart = template.indexOf("{splitPrizeText}");
        const splitEnd = template.indexOf("{splitPrizeTextEnd}") + "{splitPrizeTextEnd}".length;
        splitTemplate = template.slice(splitStart, splitEnd)
          .replace("{splitPrizeText}", "")
          .replace("{splitPrizeTextEnd}", "");
        if(splitTemplate.match(/<li>(.*?)<\/li>/g)) {
          splitTemplate = splitTemplate.match(/<li>(.*?)<\/li>/g)[0]
        }
        finalContent = finalContent.replace(/{splitPrizeText}[\s\S]*?{splitPrizeTextEnd}/, '')
      } else if(defaultTemplate) {
        splitTemplate = defaultTemplate
      }

      // If both identifiers are missing, use the generic <ul>...</ul> as the default and split template
      if (!hasDefaultPrizeText || !hasSplitPrizeText) {
        if(template.match(/<ul>(.*?)<\/ul>/g).length ){
          const listStart = template.indexOf('<ul>');
          const listEnd = template.indexOf('</ul>') + 5; // Include </ul> closing tag
          const listTemplate = template.slice(listStart, listEnd);
          const listItemTemplate = listTemplate.match(/<li>(.*?)<\/li>/g).length ? listTemplate.match(/<li>(.*?)<\/li>/g)[0] : listTemplate;
         if (!hasDefaultPrizeText) defaultTemplate = listItemTemplate;
          if(!hasSplitPrizeText) splitTemplate = listItemTemplate;
          finalContent = finalContent.replace(/<ul>(.*?)<\/ul>/g, '');
        } else if(template.match(/<p>([^<]*?\{answeredCorrectly\}[^<]*?)<\/p>/).length){
          const listTemplate = template.match(/<p>([^<]*?\{answeredCorrectly\}[^<]*?)<\/p>/)[0];
          if (!hasDefaultPrizeText) defaultTemplate = listTemplate;
          if(!hasSplitPrizeText) splitTemplate = listTemplate;
        }
        finalContent = finalContent.replace(/<p>([^<]*?\{answeredCorrectly\}[^<]*?)<\/p>/, '');
      }


      // Generate the breakdown list dynamically based on breakdownData
        let breakdownList = '';
        data.breakdownData.forEach((item: BreakdownDataItem) => {

          // Choose the correct list item template based on prizeType
          let listItem = item.assignment === RoundPrizeAssigmentEnum.SPLIT ? splitTemplate : defaultTemplate;

          // Replace placeholders in the selected list item template with item data
          if (listItem.includes('{answeredCorrectly}') && !isNull(item.answeredCorrectly)) {
            listItem = listItem.replace(/{answeredCorrectly}/g, item.answeredCorrectly.toString());
          } else {
            listItem = ''
          }
          if (listItem.includes('{totalQuestionAmount}') && !isNull(data.totalQuestionAmount)) {
            listItem = listItem.replace(/{totalQuestionAmount}/g, data.totalQuestionAmount.toString());
          } else {
            listItem = ''
          }
          if (listItem.includes('{usersAmount}') && !isNull(item.usersAmount)) {
            listItem = listItem.replace(/{usersAmount}/g, item.usersAmount.toString());
          } else {
            listItem = ''
          }
          if (listItem.includes('{reward}') && !isNull(item.reward)) {
            listItem = listItem.replace(/{reward}/g, item.reward.toString());
          } else {
            listItem = ''
          }
          breakdownList += listItem;
        });

        finalContent = finalContent +  `<ul>${breakdownList}</ul>`;

        return finalContent;
    }
    return ''
  }

  fillAllLocalesWithNonLocalizedText() {
    this.dialogService.open(
      {
        dialogContent: 'Are you sure you want to fill all locales content with non-localized content?',
        labelOk: 'Yes',
        labelNo: 'No'
      }
    ).pipe(
      takeUntil(this.unsubscribe$),
      filter(response => !!response),
      tap((response) => {
        if (response) {
          const defaultContentTemplate = this.formGroup.get('Default').get('content').value
          this.locales.forEach(locale => {
            if (locale !== 'Default') {
              this.formGroup.get(locale).get('content').patchValue(defaultContentTemplate)
            }
          })
          this.snackBarService.showSnackBar('All locales have been updated with the default (non-localized) content!');
        }
      })
    ).subscribe();
  }

  protected readonly isNull = isNull;
}

