import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { UriConfig } from "../../app.config";
import { BehaviorSubject, Observable } from "rxjs";
import { UpsellLocalized, UpsellModel } from "../../common/models/UpsellModel";

@Injectable({
  providedIn: 'root'
})
export class UpsellService {

  needUpdateUpsellContent$ = new BehaviorSubject(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

	getUpsellBanners(requestBody): Observable<{ records: UpsellModel[], total: number }> {
		let params = new HttpParams()
		Object.keys(requestBody).forEach(param => {
			params = params.set(param, requestBody[param])
		})

		return this.http.get<{ records: UpsellModel[], total: number }>(this.uriConfig.upsellBanners, {params});
	}

  createUpsellBanner(body: Partial<UpsellModel>) {
    return this.http.post(this.uriConfig.upsellBanners , body);
  }

  updateUpsellBanner(body: Partial<UpsellModel>, id: number) {
    return this.http.patch(this.uriConfig.upsellBanners + '/' + id, body);
  }

  localizeUpsell(banners: UpsellLocalized[]): Observable<any> {
    const body = {
      items: [...banners]
    }
    return this.http.patch(this.uriConfig.localizations, body);
  }

  deleteUpsellBanner(id: number) {
    return this.http.delete(this.uriConfig.upsellBanners + '/' + id);
  }
}
