import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy } from "@angular/core";
import { APP_DATA } from "../../../../general.app.config";
import { catchError, Subject, tap, throwError } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { DiffReportService } from "./diff-report.service";
import { FilesService } from "../../../../core/services/files.service";

@Component({
  selector: 'hun-diff-report',
  templateUrl: './diff-report.component.html',
  styleUrls: ['./diff-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiffReportComponent implements OnDestroy {


  selectedFiles: File[] = [];

  fileNamePrev: string;
  fileNameNew: string;

  errorMessages: string[];

  appData = APP_DATA;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<DiffReportComponent>,
    private diffReportService: DiffReportService,
    private fileService: FilesService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  triggerFileInput(input: HTMLInputElement) {
    input.click();
  }

  onCloseModal(reportDownloaded = false) {
    this.dialogRef.close(reportDownloaded);
  }

  onFileNewChange(event: any) {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      this.selectedFiles[1] = files[0];
      this.fileNameNew = this.selectedFiles[1].name;
    }

    if (this.errorMessages) {
      this.errorMessages = [];
    }
  }

  onFileOldChange(event: any) {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      this.selectedFiles[0] = files[0];
      this.fileNamePrev = this.selectedFiles[0].name;
    }
    if (this.errorMessages) {
      this.errorMessages = [];
    }
  }

  onConfirmClick() {
    this.diffReportService.compareCSV(this.selectedFiles).pipe(
      takeUntil(this.unsubscribe$),
      tap(({link}) => {
        this.fileService.downLoadFileByLink(link)
        this.onCloseModal(true)
      }),
      catchError((error) => {
        if (Array.isArray(error.error.message)) {
          this.errorMessages = error.error.message.map((item: string) => item[0].toUpperCase() + item.slice(1));
          this.cdr.markForCheck()
        } else {
          const transformedError = error.error.message[0].toUpperCase() + error.error.message.slice(1)
          this.errorMessages = [transformedError];
          this.cdr.markForCheck()
        }
        return throwError(error);
      })
    ).subscribe();
  }



  protected readonly ElementRef = ElementRef;
}

