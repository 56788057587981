import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { UriConfig } from "../../app.config";
import { BehaviorSubject, Observable}  from "rxjs";
import { RoundDetailsModel } from "../../common/models/RoundDetailsModel";
import { RoundItemDetailsModel } from 'src/app/common/models/RoundItemDetailsModel';
import { CreateRoundDetailsModel, Prize } from "../../common/models/CreateRoundDetailsModel";
import { RoundStatusEnum } from "../../common/Enums/RoundStatusEnum";


@Injectable({
  providedIn: 'root'
})
export class RoundService {

  isNeedUpdate$ = new BehaviorSubject<boolean>(true);

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  getRoundList(body):Observable<{ total: number,  records: RoundItemDetailsModel[]}> {
    let params = new HttpParams()
    Object.keys(body).filter(v => body[v]).forEach(param => {
      params = params.set(param, body[param])
    })
    return this.http.get<{ total: number,  records: RoundItemDetailsModel[]}>(this.uriConfig.rounds, { params });
  }

  getRoundById(id):Observable<RoundDetailsModel> {
    return this.http.get<RoundDetailsModel>(this.uriConfig.rounds+'/'+id);
  }

  getSportTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.uriConfig.rounds+'/sports-types');
  }

  deleteRoundById(roundId: string | number) {
    return this.http.delete<any>(this.uriConfig.rounds + '/' + roundId);
  }

  undoDeleteRoundById(roundId: string) {
    return this.http.patch<any>(this.uriConfig.rounds + '/' + roundId + '/undo-delete', {});
  }

  needUpdateRoundList() {
    this.isNeedUpdate$.next(true);
  }

  roundListUpdated() {
    this.isNeedUpdate$.next(false);
  }

  getRoundListStatus() {
    return this.isNeedUpdate$;
  }

  updateRoundById(roundId: number, round: {round: {prizeType: string, prize: Prize}}) {
    return this.http.patch<any>(this.uriConfig.rounds + '/' + roundId, round);
  }

  restartResultProcessing(roundId: number) {
    const body = {
      hasBeenProcessed: true
    }
    return this.http.post<any>(this.uriConfig.rounds + '/' + roundId + '/restart-processing', body);
  }


  updateRoundsImageById(roundId: number, round: {round: any}) {
    return this.http.patch<any>(this.uriConfig.rounds + '/' + roundId, round);
  }

  createDraft(round:CreateRoundDetailsModel): Observable<any> {
    return this.http.post<any>(this.uriConfig.rounds, round);
  }

  editeDraft(round:CreateRoundDetailsModel, id: number): Observable<any> {
    return this.http.patch<any>(this.uriConfig.rounds + '/' + id, round);
  }

  reorderRound(roundId: number, order: number): Observable<any> {
    return this.http.patch<any>(this.uriConfig.rounds + '/' + roundId + '/reorder', {order: order});
  }

  createRoundPreview(round:CreateRoundDetailsModel) {
      return this.http.post<any>(this.uriConfig.rounds + '/preview', round);
  }

  createRoundFromPreview(round:CreateRoundDetailsModel, key : string) {
    return this.http.post<any>(this.uriConfig.rounds + '/preview/' + key, null);
  }

  downloadRoundSubmissionReport(roundId) {
    return this.http.get(this.uriConfig.analytics + '/submission-report/' + roundId);
  }

  saveSubmissionReportToS3(roundId) {
    return this.http.post(this.uriConfig.analytics + '/submission-report/' + roundId + '/send', null);
  }

  downloadRoundAnalyticalReport(roundId) {
    return this.http.get<{ link: string }>(this.uriConfig.analytics + '/detailed/' + roundId);
  }

  setUpDefaultLandingRound(id, body) {
    return this.http.patch<any>(this.uriConfig.rounds + '/' + id, body);
  }

  processRoundPayment(id) {
    return this.http.patch<any>(this.uriConfig.rounds + '/' + id + '/payment-status', {});
  }

  changeRoundsStatus(id: number, status: RoundStatusEnum) {
    return this.http.patch(this.uriConfig.rounds + '/' + id + '/status/' + status, {});
  }
}
