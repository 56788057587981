import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { catchError, Subject, throwError } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogService } from "../../_base-component/dialog/dialog.service";
import { SnackBarService } from "@services/snack-bar.service";
import { filter, takeUntil, tap } from "rxjs/operators";
import { CmsService } from "@services/cms.service";
import { APP_DATA } from "@app/general.app.config";

@Component({
  selector: 'edit-cms-label',
  templateUrl: './edit-cms-label.component.html',
  styleUrls: ['./edit-cms-label.component.scss']
})
export class EditCmsLabelComponent implements OnInit, OnDestroy {

  form = new FormGroup({
    editorContent: new FormControl()
  });

  jsonForm: FormGroup;

  variableInLabel: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<EditCmsLabelComponent>,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    public cmsService: CmsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.initTextEditing();
  }

  initTextEditing() {
    const value = this.data.locale ?
      this.cmsService.getLocalizedLable(this.data.label, this.data.locale) :
      this.data.label['Text value'];

    if (this.data.label.isJson && !this.isValidJson(value)) {
      this.snackBarService.showSnackBar('Variable is broken, please contact administrator', true)
      this.dialog.closeAll();
      return;
    }

    if (this.data.label.isJson) {
      this.jsonForm = this.fb.group({
        items: this.fb.array([])
      });
      const variableArray = JSON.parse(value);
      this.patchValues(variableArray);
    }

    if (!this.data.label.isHTMLMarkup) {
      this.variableInLabel = value?.includes('{') ? APP_DATA.TEXT_VARIABLE : '';
    }
    this.form.patchValue({editorContent: value});
  }

  isValidJson(jsonString) {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  }

  get items(): FormArray {
    return this.jsonForm.get('items') as FormArray;
  }

  addItem(value: { title: string, content: string }): void {
    const group = this.fb.group({
      title: [value.title],
      content: [value.content]
    });
    this.items.push(group);
  }

  removeItem(index: number): void {
    this.jsonForm.markAsDirty();
    this.items.removeAt(index);
  }

  patchValues(values: { title: string, content: string }[]): void {
    values.forEach(value => this.addItem(value));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    if (!this.form.dirty) {
      this.dialog.closeAll();
    } else {
      this.dialogService.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter(response => !!response),
        tap((response) => {
          if (response) {
            this.dialog.closeAll();
          }
        })
      ).subscribe();
    }
  }

  saveDoc() {
    const variable = this.data.locale ? `Text value_${this.data.locale.i18n}` : 'Text value';
    const body = {
      ...this.data.label,
    };
    Object.keys(body).forEach(key => {
      if (body[key] === null && key.includes('Text value')) {
        delete body[key];
      }
    })
    if (this.data.label.isJson) {
      const formValue =  this.getJsonValue();
      body[variable] = formValue;
    } else {
      const formValue = this.form.getRawValue();
      body[variable] = formValue.editorContent;
    }

    delete body.isHTMLMarkup;
    this.cmsService.editTextLabel(body)
      .pipe(
        tap(() => {
          this.cmsService.needUpdateContent$.next(true);
          this.snackBarService.showSnackBar('Variable changes were updated successfully!');
          this.dialog.closeAll();
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  getJsonValue() {
    const value = this.jsonForm.getRawValue();
    return JSON.stringify(value.items);
  }

  isButtonDisabled() {
    return this.data.label.isJson ?
      !this.jsonForm.dirty || !this.isAllFieldFill() :
      !this.form.dirty || !this.form.get('editorContent').getRawValue();
  }

  isAllFieldFill() {
    return this.items.controls.every(control => control.get('title').value && control.get('content').value)
  }
}

