<div class="builder-tabs-container">
  <div class="builder-wrapper">
    <div class="header-pannel">
      <div class="header-container">
        <div class="general-header">
          <mat-icon class="material-icons close" (click)="closeDialog()">close</mat-icon>
          <h2 class="popup-header">{{ this.data ? 'Edit' : 'Create'}} Tier</h2>
        </div>
        <div class="buttons-container">
          <button
            mat-raised-button
            color="primary"
            [disabled]="tierForm.invalid"
            (click)="save()">
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="tier-color-form">
      <mat-tab-group>
        <mat-tab>
          <form [formGroup]="tierForm">
            <div class="form-field-with-label">
              <mat-label>
                Choose color*
              </mat-label>
              <ngx-colors
                ngx-colors-trigger
                formControlName="color"
              ></ngx-colors>
            </div>

            <div class="form-field-with-label">
              <custom-input
                [formControl]="tierForm.get('name')"
                placeholder="Enter tier name">
                Tier Name*
              </custom-input>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
