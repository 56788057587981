import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'create-tier-color',
  templateUrl: './create-tier-color.component.html',
  styleUrls: ['./create-tier-color.component.scss'],
})
export class CreateTierColorComponent implements OnInit {

  tierForm: FormGroup;


  constructor(
    public dialogRef: MatDialogRef<CreateTierColorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.buildForm();

    if (this.data) {
      this.tierForm.patchValue(this.data);
    }
  }

  buildForm() {
    this.tierForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      color: new FormControl(null, [Validators.required]),
    });

  }

  save() {
    this.dialogRef.close(this.tierForm.value)
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
