import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable } from 'rxjs';
import {UriConfig} from "../../app.config";
import { HealthCheckResultsModel } from "../../common/models/HealthCheckResultsModel";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  getPlayersRound() {
    return this.http.get<{ link: string }>(this.uriConfig.analytics + '/detailed');
  }

  getHealthCheckResult(roundId): Observable<HealthCheckResultsModel[]> {
    return this.http.get<HealthCheckResultsModel[]>(this.uriConfig.issuesCheck + '/' + roundId);
  }
}
