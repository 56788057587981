<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="close()">close</mat-icon>
        <h2 class="popup-header">{{ data ? 'Edit banner' : 'Create a new banner'}}</h2>
      </div>
    </div>
  </div>
  <div class="builder-body">
    <div class="builder-content">
      <div class="class-details">
        <form [formGroup]="form">
          <custom-input
            [placeholder]="'Enter Upsell Title'"
            formControlName="title">
            Upsell Title*
          </custom-input>

          <custom-input
            [placeholder]="'Enter Upsell Description'"
            formControlName="description">
            Upsell Description*
          </custom-input>

          <div class="dates-controllers">
            <custom-date-input
              [formControl]="form.get('displayFrom')">
              Start Date*
            </custom-date-input>
            <custom-date-input
              [formControl]="form.get('displayTo')">
              End Date*
            </custom-date-input>
          </div>

          <custom-input
            [placeholder]="'Enter Button Name'"
            formControlName="buttonName">
            Upsell Button Name*
          </custom-input>

          <custom-input
            [placeholder]="'Enter Web Link'"
            formControlName="webLink">
            Upsell Web Link*
          </custom-input>

          <custom-input
            [placeholder]="'Enter App Link'"
            formControlName="appLink">
            Upsell Mobile App Link*
          </custom-input>

          <div class="image-upload-form-field">
            <div *ngIf="form.get('imageUrl')">
              <drag-and-drop-file-form
                formControlName="imageUrl"
                [tooltipText]="tooltipTextIcon"
                [url]="form?.get('imageUrl')?.value">
                Upsell Image*
              </drag-and-drop-file-form>
            </div>
          </div>
        </form>
        <div class="buttons-container">
          <button mat-raised-button color="primary" [disabled]="checkFormValidity()" (click)="saveBanner()">Save</button>
          <button mat-raised-button (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
